import BasicResponse from '../BasicResponse';
import { IBackendPlannedSteps } from './__ResponseStructures';

// TODO: Einschränken ? Enums ?
export interface __Platform {
  name: string;
};
export interface __Userstate {
  name: string;
};
export interface __Role {
  name: string;
};
export interface __WorkBlockState {
  name: string;
};
export interface __Location {
  name: string;
};
export interface __MaterialType {
  name: string;
};
export interface __MaterialState {
  name: string;
};
export interface __MaterialOrigin {
  name: string;
};

export interface __FileResponse {
  id: string;
  platformID: string;
  name: string;
  path: string;
  filetypeId: string;
  // + connections
  summaryID: string |null;
  workpackageID: string |null;
  workblockID: string |null;
  anodeConstructionID: string |null;
  anodeConstrucR2RID: string |null;
  batteryConstructionID: string |null;
  batteryTestID: string |null;
  charceristicID: string |null;
  microscopyID: string |null;
  remID: string |null;
  xrdID: string |null;
  ramanID: string |null;
  postMortemID: string |null;
};
export interface FileAddData {
    platform: string,
    filetype: string,
    name: string
};
//request types
export interface __EvaluationUnconverted {
  id: string | null;
  done: string;
  comment: string;
  points: string | null;
};
export interface __EvaluationConverted {
  id?: string;
  done: boolean;
  comment: string;
  points?: number;
};
//anodes-unconverted
export interface __AnodeSheetToSheet {
  id?: string;
  name: string;
  description: string;
};
export interface __LayerSheetToSheetUnconverted {
  id?: string;
  number: number;
  material: string;
  pendulumStrokes: number;
  height: string;
  lightnings: Array<number>; //numbers in lightnings
  annotation: string;
};
export interface __LightningSheetToSheetUnconverted {
  id?: string;
  number: number;
  numberOfLightnings: number;
  energyKV: number;
  position: string;
  mm_S: number;
  impulseLength: number;
  intervalLength: number;
  annotation: string;
};
export interface __AnodeR2R {
  id?: string;
  name: string;
  runningPlan: __RunningPlanR2R;
  vorreinigung: __Vorreinigung[];
  description: string;
  afterSputteringGlassSclices: boolean;
  afterSputteringRolling: boolean;
};
export interface __LayerR2RUnconverted {
  number: string;
  material: string;
  layerDepth: string;
  gasFlow: string;
  pressure: string;
  source_P: string;
  source_U: string;
  source_I: string;
  speed: string;
  positionStart: string;
  positionEnd: string;
  lightnings: Array<string>;
  comment: string;
};
export interface __LightningR2RUnconverted {
  id?: string;
  number: string;
  tension: string;
  energy: string;
  lamp: string;
  numberOfLightnings: string;
  positionStart: string;
  positionEnd: string;
  speed: string;
  impulseLength: string;
  intervall: string;
  preLightningVolt: string;
  preLightningPulseLen: string;
  comment: string;
};
//anodes-converted
export interface __LayerSheetToSheetConverted {
  id?: string;
  number: number;
  material: string;
  pendulumStrokes: number;
  height: string;
  lightnings: Array<number>;
  annotation: string;
};
export interface __LightningSheetToSheetConverted {
  id?: string;
  number: number;
  numberOfLightnings: number;
  energyKV: number;
  position: string;
  mm_S: number;
  impulseLength: number;
  intervalLength: number;
  annotation: string;
};
export interface __LayerR2RConverted {
  number: number;
  material: string;
  layerDepth: number;
  gasFlow: number;
  pressure: number;
  source_P: number;
  source_U: number;
  source_I: number;
  speed: number;
  positionStart: string;
  positionEnd: string;
  lightnings: Array<number>;
  comment: string;
};
export interface __LightningR2RConverted {
  id?: string;
  number: number;
  tension: number;
  energy: number;
  lamp: string;
  numberOfLightnings: number;
  positionStart: string;
  positionEnd: string;
  speed: number;
  impulseLength: number;
  intervall: number;
  preLightningVolt: number;
  preLightningPulseLen: number;
  comment: string;
};
export interface __RunningPlanR2RResponse {
  id: string;
  platformID: string;
  anodeID: string;
  length: number;
  segment: string;
  band: string;
  startPositions: string;
  endPositions: string;
  pos: string;
};
export interface __RunningPlanR2R {
  id?: string;
  length: number;
  segment: string;
  band: string;
  startPosition: string;
  endPosition: string;
  pos: string;
};
export interface __VorreinigungResponse{
    id?:string;
    platformID: string;
    anodeID: string;
    speed: string;
    leistung: string;
    gasFlow: string;
    pressure: string;
    positionStartStop: string;
    lightnings: string;//number[]; // number of lightnings to connect
    comment: string;
};
export interface __Vorreinigung{
    id?:string;
    speed: string;
    leistung: string;
    gasFlow: string;
    pressure: string;
    positionStartStop: string;
    lightnings: string;//number[]; // number of lightnings to connect
    comment: string;
};
//batteries-unconverted
export interface __BatteryDataUnconverted {
  id: string | null;
  celltype: string;
  anode: __DataAnodeUnconverted | null;
  cathode: __DataCathodeUnconverted | null;
  electrolyt: __DataElectrolytUnconverted | null;
  separator: __DataSeperatorUnconverted | null;
  feather: __DataFeatherUnconverted | null;
  spacer: __DataSpacerUnconverted | null;
  case: __DataCaseUnconverted | null;
};
export interface __BatteryWishUnconverted {
  numberOfBatteries: string;
  location: string;
  battery: __BatteryUnconverted[];
  variation: __BatteryVariationUnconverted
};
export interface __BatteryUnconverted {
  id: string | null;
  name: string;
  locationOfMeasuring: string;
  constructor: string[];
  trys: string;
  u1: string;
  u2: string;
  annotation: string;
  variation: __BatteryVariationUnconverted|null;//////////////
};
export interface __BatteryVariationUnconverted {
  id?: string;
  anode: __DataAnodeUnconverted | null;
  cathode: __DataCathodeUnconverted | null;
  electrolyt: __DataElectrolytUnconverted | null;
  separator: __DataSeperatorUnconverted | null;
  spacer: __DataSpacerUnconverted| null;
  caseData: __DataCaseUnconverted | null;
  feather: __DataFeatherUnconverted| null;
};
export interface __DataAnodeUnconverted {
  id?: string;
  material: string;
  diameter: string;
};
export interface __DataCathodeUnconverted {
  id?: string;
  material: string;
  diameter: string;
};
export interface __DataElectrolytUnconverted {
  id?: string;
  material: string;
  volume: string;
  volumeText: string;
  volumeUnit: string;
};
export interface __DataSeperatorUnconverted {
  id?: string;
  material: string;
  diameter: string;
  numberOfSeperators: string;
};
export interface __DataSpacerUnconverted {
  id?: string;
  material: string;
  diameter: string;
  thickness: string;
};
export interface __DataCaseUnconverted {
  id?: string;
  material: string;
};
export interface __DataFeatherUnconverted {
  id?: string;
  material: string;
};
export interface __BatteryTestWishUnconverted {
  numberOfBatteries: string;
  ch: __BatteryTestValuesUnconverted;
  dch: __BatteryTestValuesUnconverted;
  type: string;
};
export interface __BatteryTestProcessingUnconverted {
  id?: string;
  ch: __BatteryTestValuesUnconverted;
  dch: __BatteryTestValuesUnconverted;
  isMeasuring: string;
  isFinished: string;
  hasDefects: string;
  measuringPoint: string;
  annotation: string;
  batteries: string;
};
export interface __BatteryTestValuesUnconverted {
  forming_I_mA: string;
  forming_U_max_min: string;
  forming_QLimit_mAh: string;
  cycling_I_mA: string;
  cycling_U_max_min_V: string;
  cycling_QLimit_mAh: string;
  cycling_CV_I_cut_mAh: string;
  rateTest_I_mA: string;
  rateTest_Cyc_per_I: string;
  rateTest_Repeat: string;
};
//batteries-converted
export interface __BatteryDataConverted {
  id: string | null;
  celltype: string;
  anode: __DataAnodeConverted | null;
  cathode: __DataCathodeConverted | null;
  electrolyt: __DataElectrolytConverted | null;
  separator: __DataSeperatorConverted | null;
  feather: __DataFeatherConverted | null;
  spacer: __DataSpacerConverted | null;
  case: __DataCaseConverted | null;
};
export interface __BatteryWishConverted {
  numberOfBatteries: number;
  location: string;
  battery: __BatteryConverted[];
  variation: __BatteryVariationConverted
};
export interface __BatteryConverted {
  id: string | null;
  name: string;
  locationOfMeasuring: string;
  constructor: string[];
  trys: number;
  u1: string;
  u2: string;
  annotation: string;
  variation: __BatteryVariationConverted;
};
export interface __BatteryVariationConverted {
  id?: string;
  anode?: __DataAnodeConverted | null;
  cathode?: __DataCathodeConverted | null;
  electrolyt?: __DataElectrolytConverted| null;
  separator?: __DataSeperatorConverted | null;
  spacer?: __DataSpacerConverted | null;
  caseData?: __DataCaseConverted | null;
  feather?: __DataFeatherConverted | null;
};
export interface __DataAnodeConverted {
  id?: string;
  material: string;
  materialName?: string;
  diameter: number;
};
export interface __DataCathodeConverted {
  id?: string;
  material: string;
  materialName?: string;
  diameter: number;
};
export interface __DataElectrolytConverted {
  id?: string;
  material: string;
  materialName?: string;
  volume: number;
  volumeText: string;
  volumeUnit: string;
};
export interface __DataSeperatorConverted {
  id?: string;
  material: string;
  materialName?: string;
  diameter: number;
  numberOfSeperators: number;
};
export interface __DataSpacerConverted {
  id?: string;
  material: string;
  materialName?: string;
  diameter: number;
  thickness: number;
};
export interface __DataCaseConverted {
  id?: string;
  material: string;
  materialName?: string;
};
export interface __DataFeatherConverted {
  id?: string;
  material: string;
  materialName?: string;
};
export interface __BatteryTestWishConverted {
  numberOfBatteries: number;
  ch: __BatteryTestValuesConverted;
  dch: __BatteryTestValuesConverted;
  type: string;
  annotation: string;
};
export interface __BatteryTestProcessingConverted {
  id?: string;
  ch: __BatteryTestValuesConverted;
  dch: __BatteryTestValuesConverted;
  isMeasuring: boolean;
  isFinished: boolean;
  hasDefects: boolean;
  measuringPoint: string;
  annotation: string;
  batteries: string;
};
export interface __BatteryTestValuesConverted {
  forming_I_mA: string;
  forming_U_max_min: string;
  forming_QLimit_mAh: string;
  cycling_I_mA: string;
  cycling_U_max_min_V: string;
  cycling_QLimit_mAh: string;
  cycling_CV_I_cut_mAh: string;
  rateTest_I_mA: string;
  rateTest_Cyc_per_I: string;
  rateTest_Repeat: number;
};

//characteristics
export interface __AdhesionTestDataUnconverted {
  id: string, //empty string if null
  evaluation: __EvaluationUnconverted | null;
  responsibleUser: string,
  state: string,
  data:{
      series: string,
      name: string,
      postMortem: string,
      comment: string
  }[],
  comment: string
};
export interface __MicroscopyDataUnconverted {
  id: string,
  responsibleUser: string,
  state: string,
  evaluation?: __EvaluationUnconverted,
  data:{
      series: string,
      name: string,
      postMortem: string,
      comment: string
  }[],
  comment: string
};
export interface __RemDataUnconverted {
  id: string,
  responsibleUser: string,
  state: string,
  evaluation?: __EvaluationUnconverted,
  data:{
      name: string,
      ebsd: string,
      edx: string,
      postMortem: string,
      comment: string
  }[],
  comment: string

};
export interface __XrdDataUnconverted {
  id: string,
  responsibleUser: string,
  state: string,
  evaluation?: __EvaluationUnconverted,
  data:{
      name: string,
      postMortem: string,
      protectiveGlassSampleCarrier: string,
      template: string,
      comment: string,
  }[],
  comment: string
};
export interface __RamanDataUnconverted {
  id: string,
  responsibleUser: string,
  state: string,
  evaluation?: __EvaluationUnconverted,
  data:{
      name: string,
      postMortem: string,
      comment:string
  }[],
  comment: string
};
export interface __PostMortemAnalysisDataConverted {
  responsibleUser: string;
  state: string;
  evaluation: __EvaluationUnconverted|null;
};

export interface __PostMortemAnalysisBatteryDataUnconverted {
  id?: string;
  battery: string;
  meanderExpression: string;
  colorImgAnode: string;
  stateAnode: string;
  stateCathode: string;
  stateSeparator: string;
  annotation: string;
};
export interface __PostMortemAnalysisBatteryDataConverted {
  id: string;
  responsibleUser: string,
  date: Date,
  battery: string;
  meanderExpression: string;
  colorImgAnode: boolean;
  stateAnode: string;
  stateCathode: string;
  stateSeparator: string;
  annotation: string;
};
//converted
export interface __AdhesionTestDataConverted {
  id: string, //empty string if null
  evaluation: __EvaluationConverted | null;
  responsibleUser: string,
  state: string,
  data:{
      id: string,
      responsibleUser: string,
      date: Date,
      series: string,
      name: string,
      postMortem: boolean,
      comment: string
  }[],
  comment: string
};
export interface __MicroscopyDataConverted {
  id: string,
  responsibleUser: string,
  state: string,
  evaluation: __EvaluationConverted|null,
  data:{
      id: string,
      responsibleUser: string,
      date: Date,
      series: string,
      name: string,
      postMortem: boolean,
      comment: string
  }[],
  comment: string
};
export interface __RemDataConverted {
  id: string,
  responsibleUser: string,
  state: string,
  evaluation: __EvaluationConverted |null,
  data:{
      id: string,
      responsibleUser: string,
      date: Date,
      name: string,
      ebsd: boolean,
      edx: boolean,
      postMortem: boolean,
      comment: string
  }[],
  comment: string

};
export interface __XrdDataConverted {
  id: string,
  responsibleUser: string,
  state: string,
  evaluation: __EvaluationConverted|null,
  data:{
      id: string,
      responsibleUser: string,
      date: Date,
      name: string,
      postMortem: boolean,
      protectiveGlassSampleCarrier: string,
      template: string,
      comment: string,
  }[],
  comment: string
};
export interface __RamanDataConverted {
  id: string,
  responsibleUser: string,
  state: string,
  evaluation: __EvaluationConverted |null,
  data:{
      id: string,
      responsibleUser: string,
      date: Date,
      name: string,
      postMortem: boolean,
      comment:string
  }[],
  comment: string
};

//workblock
export interface __PlannedStepsUnconverted {
  anodeConstructionSheetToSheet: { number: string } | null;
  anodeConstructionR2R: { length: string } | null;
  batteryConstruction: {
    VZ: { type: string; number: string; numberPZ: string } | null;
    HZ: { type: string; number: string; numberPZ: string } | null;
  } | null;
  batteryTest: {
    VZ: { type: string } | null;
    HZ: { type: string } | null;
  } | null;
  analysis: {
    adhesionTest: string;
    microscopy: string;
    rem: string;
    xrd: string;
    raman: string;
    postMortemAnalysis: string;
  } | null;
};
export interface __PlannedStepsConverted {
  anodeConstructionSheetToSheet: { number: number } | null;
  anodeConstructionR2R: { length: number } | null;
  batteryConstruction: {
    VZ: { type: string; number: number; numberPZ: number } | null;
    HZ: { type: string; number: number; numberPZ: number } | null;
  } | null;
  batteryTest: {
    VZ: { type: string } | null;
    HZ: { type: string } | null;
  } | null;
  analysis: {
    adhesionTest: boolean;
    microscopy: boolean;
    rem: boolean;
    xrd: boolean;
    raman: boolean;
    postMortemAnalysis: boolean;
  } | null;
};
export interface __Addition {
  platform: any | null;
  anodeConstructionComment?: string;
  batteryConstructionComment?: string;
  batteryTestComment?: string;
  adhesionTestComment?: string;
  microscopyComment?: string;
  remComment?: string;
  xrdComment?: string;
  ramanComment?: string;
  postMortemComment?: string;
  workblock: any | null;
  comment: string;
};

//response types
export interface __UserResponse {
  id: string;
  platformID: string;
  loginName: string;
  displayName: string;
  position: string;
  stateId: string;
  lastLogin: Date;
  deleted: boolean;
  validityId: string;
};
export interface __WorkBlockResponse {
  id: string;
  platformID: string;
  workpackageID: string;
  plannedSteps: string;
  date: Date;
  name: string;
  description: string;
  comment: string;
  locationOfSynthesisID: string;
  stateID: string;
  anodeConstruction:{
    evaluation : __EvaluationResponse |null;
  } ; 
  anodeConstructionR2R: {
    evaluation : __EvaluationResponse |null;
  } ;
  batteryConstruction:{
    evaluation : __EvaluationResponse |null;
  } [];
  batteryTest:{
    evaluation : __EvaluationResponse |null;
  } [] ;
  characteristics:{
    evaluation : __EvaluationResponse |null;
  } ;
  addition: any;
  responsibleUser: __UserResponse[];
};
export interface __WorkBlockResponseList {
  id: string;
  platformID: string;
  workpackageID: string;
  plannedSteps: string;
  date: Date;
  name: string;
  description: string;
  comment: string;
  locationOfSynthesisID: string;
  stateID: string;
  anodeConstruction:{
    id: string;
    date: Date;
    state: {name:string};
    evaluation : __EvaluationResponse |null;
    anode: {name: string} | null;
  } ; 
  anodeConstructionR2R: {
    id: string;
    date: Date;
    state: {name:string};
    evaluation : __EvaluationResponse |null;
    anode: {name: string} | null;
  } ;
  batteryConstruction:{
    id: string;
    date: Date;
    state: {name:string};
    fixedBatteryData: {celltype: {name: "Halbzelle" | "Vollzelle" | "Pouchzelle" | "SymmetrischeZelle" | "DreiElektrodenZelle"}} | null;
    evaluation : __EvaluationResponse |null;
  } [];
  batteryTest:{
    id: string;
    date: Date;
    state: {name:string};
    batteryTestWishes: {type: {name: "Halbzelle" | "Vollzelle" | "Pouchzelle" | "SymmetrischeZelle" | "DreiElektrodenZelle";}}[];
    evaluation : __EvaluationResponse |null;
  } [] ;
  characteristics: {
    id: string;
    date: Date;
    state: {name:string};
    evaluation: __EvaluationResponse | null;
    adhesionTest: {
        id: string;
        date: Date;
        state: {name:string}| null;
        evaluation: __EvaluationResponse | null
    }| null,
    xrd:{
        id: string;
        date: Date;
        state: {name:string}| null;
        evaluation: __EvaluationResponse | null
    }| null,
    rem:{
        id: string;
        date: Date;
        state: {name:string}| null;
        evaluation: __EvaluationResponse | null
    }| null,
    raman: {
        id: string;
        date: Date;
        state: {name:string}| null;
        evaluation: __EvaluationResponse | null
    }| null,
    postMortemAnalysis:{
        id: string;
        date: Date;
        state: {name:string}| null;
        evaluation: __EvaluationResponse | null
    }| null,
    microscopy: {
        id: string;
        date: Date;
        state: {name:string}| null;
        evaluation: __EvaluationResponse | null
    }| null
  }| null;
  addition: any;
  responsibleUser: __UserResponse[];
};
export interface __WorkPackageResponse {
  id: string;
  platformID: string;
  date: string;
  name: string;
  shortDescription: string;
  projectDescription: string;
  locationOfSynthesisID: string;
  stateID: string;
};
export interface __EvaluationResponse {
  id: string;
  platformID: string;
  done: boolean;
  comment: string;
  points: number;
};
export interface __AnodeResponse {
  id: string;
  platformID: string;
  constructionID: string;
  name: string;
  description: string;
};

export interface __LayerResponse {
  id: string;
  platform:{
    name:string;
  }
  anodeID: string;
  number: number;
  pendulumStrokes: number;
  height: string;
  annotation: string;
  material: string;
  lightnings: __LightningResponse[];
};
export interface __LightningResponse {
  id: string;
  platformID: string;
  number: number;
  numberOfLightnings: number;
  energyKV: number;
  position: string;
  mm_S: number;
  impulseLength: number;
  intervalLength: number;
  annotation: string;
  anodeID: string;
};
export interface __LayerResponseR2R {
  id: string;
  platformID: string;
  anodeID: string;
  number: number;
  material: string;
  layerDepth: number;
  gasFlow: number;
  pressure: number;
  source_P: number;
  source_U: number;
  source_I: number;
  speed: number;
  positionStart: string;
  positionEnd: string;
  lightnings: Array<__LightningResponseR2R>;
  comment: string;
};
export interface __LightningResponseR2R {
  id: string;
  platformID: string;
  number: number;
  tension: number;
  energy: number;
  lamp: string;
  numberOfLightnings: number;
  positionOfStart: number;
  positionOfEnd: number;
  speed: number;
  impulseLength: number;
  intervall: number;
  preLightningVolt: number;
  preLightningPulseLen: number;
  comment: string;
  anodeID: string;
};
export interface __AnodeR2RResponse {
  id: string;
  platformID: string;
  constructionID: string;
  layers: __LayerResponseR2R[];
  lightnings: __LightningResponseR2R[];
  runningPlan: __RunningPlanR2RResponse,
  vorreinigung: __VorreinigungResponse[],
  name: string;
  description: string;
  afterSputteringGlassSclices: boolean;
  afterSputteringRolling: boolean;
};
export interface __BatteryConstructionResponse {
  id: string;
  platformID: string;
  responsibleUserID: string[];
  date: Date;
  stateID: string;
  workblockID: string;
};
export interface __BatteryWishesResponse {
  id: string;
  platformID: string;
  numberOfBatteryWishes: number;
  locationOfConstructionID: string;
  batteryConstructionID: string;
};
export interface __FixedBatteryDataResponse {
  id: string;
  platformID: string;
  typeID: string;
  batteryConstructionID: string;
  batteryID: string;
};
export interface __FixBatterydataObject{
  id: string;
  platformID: string;
  typeID: string;
  batteryConstructionID: string|null;
  battery: string|null;
  anode:{
      id: string,
      materialName: string,
      materialID: string,
      diameter: number
  }|null,
  cathode:{
      id: string,
      materialName: string,
      materialID: string,
      diameter: number
  }|null,
  electrolyt:{
      id: string,
      materialName: string,
      materialID: string,
      volume: number,
      volumeText: string,
      volumeUnit: string
  }|null,
  separator:{
      id: string,
      materialName: string,
      materialID: string,
      diameter: number,
      numberOfSeparators: number
  }|null,
  spacer:{
      id: string,
      materialName: string,
      materialID: string,
      diameter: number,
      thickness: number
  }|null,
  case:{
      id: string,
      materialName: string,
      materialID: string,
  }|null,
  feather:{
      id: string,
      materialName: string,
      materialID: string,
  }|null
};
export interface __BatteryResponse {
  id: string;
  platformID: string;
  name: string;
  locOfMeasuringID: string;
  constructorOfCell: __UserResponse[];
  trys: number;
  u1: string;
  u2: string;
  annotation: string;
  batteryConstructionID: string;
  batteryWishesID: string;
  batteryTestProcessID: string;
  postmortembatteryID: string;
  batteryDataID: string | null;
  batteryData: __BatteryDataWithMaterialNames;
  variation: __BatteryVariationWithMaterialNames;
};
/**
 * Battery Types for Response
   Model BatteryData Response with material name
 */
export type MaterialNameForBatteryData ={
  material: {name:string};
};
export type __BatteryDataWithMaterialNames = {
  anode: MaterialNameForBatteryData|null;
  cathode: MaterialNameForBatteryData|null;
  electrolyt: MaterialNameForBatteryData|null;
  spacer: MaterialNameForBatteryData|null;
  separator: MaterialNameForBatteryData|null;
  feather: MaterialNameForBatteryData|null;
  case: MaterialNameForBatteryData|null;
};
export type __BatteryVariationWithMaterialNames = {
  id: string;
  platformID: string;
  batteryID: string;
  batteryWishesID: string;
  dataAnode:{
    id: string,material: {name:string, id:string},diameter: number}|null,
  dataCathode:{
    id: string,material: {name:string, id:string},diameter: number}|null,
  dataElectrolyt:{
    id: string,material: {name:string, id:string},volume: number,volumeText: string,volumeUnit: string}|null,
  dataSeparator:{
    id: string,material: {name:string, id:string},diameter: number,numberOfSeparators: number}|null,
  dataSpacer:{
    id: string,material: {name:string, id:string},diameter: number,thickness: number}|null,
  dataCase:{
    id: string,material: {name:string, id:string}}|null,
  dataFeather:{
    id: string,material: {name:string, id:string}}|null

};
/**
 * end
 */
export interface __AnodeResponseForBatteryConstruction {
  id: string;
  platformID: string;
  materialID: string;
  diameter: number;
  batteryVariationID: string;
  batteryDataID: string;
};
export interface __CathodeResponseForBatteryConstruction {
  id: string;
  platformID: string;
  materialID: string;
  diameter: number;
  batteryVariationID: string;
  batteryDataID: string;
};
export interface __ElectrolytResponseForBatteryConstruction {
  id: string;
  platformID: string;
  materialID: string;
  volume: number;
  volumeText: string;
  volumeUnit: string;
  batteryVariationID: string;
  batteryDataID: string;
};
export interface __SpacerResponseForBatteryConstruction {
  id: string;
  platformID: string;
  materialID: string;
  diameter: number;
  thickness: number;
  batteryVariationID: string;
  batteryDataID: string;
};
export interface __SeparatorResponseForBatteryConstruction {
  id: string;
  platformID: string;
  materialID: string;
  diameter: number;
  numberOfSeparators: number;
  batteryVariationID: string;
  batteryDataID: string;
};
export interface __FeatherResponseForBatteryConstruction {
  id: string;
  platformID: string;
  materialID: string;
  batteryVariationID: string;
  batteryDataID: string;
};
export interface __CaseResponseForBatteryConstruction {
  id: string;
  platformID: string;
  materialID: string;
  batteryVariationID: string;
  batteryDataID: string;
};
export interface __BatteryvariationResponse {
  id: string;
  platformID: string;
  batteryID: string;
  batteryWishesID: string;
};
export interface __BatteryTestWishesGetResponse {
  id: string;
  platform: string;
  type: {name:string};
  numberOfBatteries: number;
  ch: __BatteryTestValuesGetResponse;
  dch: __BatteryTestValuesGetResponse;
  annotation: string;
};
export interface __BatteryTestProcessingGetResponse {
  id: string;
  platform: string;
  isMeasuring: boolean;
  isFinished: boolean;
  hasDefects: boolean;
  measuringPoint: string;
  annotation: string;
  ch: __BatteryTestValuesGetResponse;
  dch: __BatteryTestValuesGetResponse;
  battery: __BatteryGetResponseForBatteryTest;
};
export interface __BatteryTestValuesGetResponse {
  id: string;
  platform: string;
  forming_I_mA: string;
  forming_U_max_min: string;
  forming_QLimit_mAh: string;
  cycling_I_mA: string;
  cycling_U_max_min_V: string;
  cycling_QLimit_mAh: string;
  cycling_CV_I_cut_mA: string;
  rateTest_I_mA: string;
  rateTest_Cyc_per_i: string;
  rateTest_Repeat: number;
};
export interface __BatteryTestProcessingGetResponseForBatteryList {
  id: string;
  platformID: string;
  isMeasuring: boolean;
  isFinished: boolean;
  hasDefects: boolean;
  measuringPointID: string;
  annotation: string;
  batteryTestID: __BatteryGetResponseForBatteryTest;
};
export interface __BatteryGetResponseForBatteryTest {
  id: string;
  platform: string;
  batteryConstructionID: string;
  name: string;
  variation: string;
  locationOfMeasuring: string;
  constructorOfCell: string;
  trys: number;
  u1: string;
  u2: string;
  batteryData: __FixedBatteryDataResponse;
};
export interface __BatteryTestWishesListResponse {
  id: string;
  type: {name:string};
  numberOfBatteries: number;
};
export interface __BatteryTestProcessingListResponse {
  id: string;
  battery: {
    batteryData: __FixedBatteryDataResponse,
    locOfMeasuring: __Location,
    id: string
  }
};
export interface __AdhesionTestResponse {
  id: string;
  platformID: string;
  date: string;
  stateID: string;
  data:{
    id: string;
    date: Date;
    userID: string;
    platformID: string;
    series: string;
    name: string;
    postMortem: boolean;
    comment: string;
    adhesionTestID: string;
  }[];
  responsibleUser: __UserResponse[];
  comment: string;
  characteristicID: string;
  files: __FileResponse[];
};
export interface __MicroscopyResponse {
  id: string;
  platformID: string;
  date: string;
  stateID: string;
  responsibleUser: __UserResponse[];
  data:{
    id: string;
    platformID: string;
    date: Date;
    userID: string;
    comment: string;
    series: string;
    name: string;
    postMortem: boolean;
    microscopyID: string;
  }[];
  comment: string;
  characteristicID: string;
  files: __FileResponse[];
};
export interface __RemResponse {
  id: string;
  platformID: string;
  date: string;
  stateID: string;
  responsibleUser: __UserResponse[];
  comment: string;
  characteristicID: string;
  data:{
    id: string;
    platformID: string;
    date: Date;
    userID: string;
    comment: string;
    name: string;
    ebsd: boolean;
    edx: boolean;
    postMortem: boolean;
    remID: string;
  }[];
  files: __FileResponse[];
};
export interface __XrdResponse {
  id: string;
  platformID: string;
  date: string;
  stateID: string;
  responsibleUser: __UserResponse[];
  comment: string;
  characteristicID: string;
  data:{
    id: string;
    platformID: string;
    date: Date;
    userID: string;
    comment: string;
    name: string;
    postMortem: boolean;
    protectiveGlassSampleCarrier: string;
    template: string;
    xrdID: string;
  }[];
  files: __FileResponse[];
};
export interface __RamanResponse {
  id: string;
  platformID: string;
  date: string;
  stateID: string;
  responsibleUser: __UserResponse[];
  comment: string;
  characteristicID: string;
  data:{
    id: string;
    platformID: string;
    date: Date;
    userID: string;
    comment: string;
    name: string;
    postMortem: boolean;
    ramanID: string;
  }[];
  files: __FileResponse[];
};
export interface __PostMortemAnalysisResponse {
  id: string;
  platformID: string;
  responsibleUser: __UserResponse[];
  date: string;
  stateID: string;
  characteristicID: string;
  analysis: __AnalysisBatteries[];
  files: __FileResponse[];
};
export interface __AnalysisBatteries {
  id: string;
  date: Date;
  responsibleUser: __UserResponse;
  meanderExpresssion: {name: string};
  colorImgAnode: boolean;
  platformID: string;
  stateAnodeID: string;
  stateSeparatorID: string;
  stateCathodeID: string;
  annotation: string;
  postmortemID: string;
  batteryTake: __BatteryResponse
};

//extended Responses
export interface CreateBasicResponse<T> extends BasicResponse<T> {
  id: string;
};
//create response for batteryConstruction
export interface __BatteryIDObject {
  success: boolean;
  id:{
    batteryConstruction: string;
    batteryWish: string;
    batteryWishbatteries: string[];
    fixBatteryData: string;
    batteries: string[];
  };
};

export interface __CreateBatteryIDObject<T> extends BasicResponse<T> {
  id:string;
};
export interface PlannesStepsResponse<T> extends BasicResponse<T> {
  steps: IBackendPlannedSteps;
};

//Enums
export class TypeofCharacteristic {
  static adhesionTest: string = 'adhesionTest';
  static microscopy: string = 'microscopy';
  static xrd: string = 'xrd';
  static rem: string = 'rem';
  static raman: string = 'raman';
  static postmortem: string = 'postmortem';
};
