import { useEffect, useState } from 'react';
import Card from '../../UI/Card';
import { ApiWorkPackage } from '../../backend/ApiWorkPackage';
import { ApiAnodeConstruction } from '../../backend/ApiAnodeConstruction';
import { ApiAnodeConstructionR2R } from '../../backend/ApiAnodeConstructionR2R';
import { ApiWorkBlock } from '../../backend/ApiWorkBlock';
import { ApiBatteryConstruction } from '../../backend/ApiBatteryConstruction';
import { ApiBatteryTest } from '../../backend/ApiBatteryTest';
import { ApiCharacteristics } from '../../backend/ApiCharacteristics';
import { BarChart } from '../../UI/barChart';
import { __PlannedStepsConverted } from '../../backend/types/__GeneralStructures';




/**
   * Charakterisierung
   * Umbenennungen:
      Hafttest -> Sonstiges
      Post Mortem -> PM Foto
      Raman -> PM REM
      Mikroskopie -> LiMi
  */
export default function Dashboard() {
  const[initialized, setInitialization] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [countLoadings, setCountLoadings] = useState(0);
  const [numPlannedPackages, setNumPlannedPackages] = useState<number>(0);
  const [numOpenPackages, setNumOpenPackages] = useState<number>(0);
  const [numOpenBlocks, setNumOpenBlocks] = useState<number>(0);

  //chart Data
  const [numOpenAnodeConstruction, setNumOpenAnodeConstruction] = useState<number>(0);
  // const [numOpenAnodeConstructionS2S, setNumOpenAnodeConstructionS2S] = useState<number>(0);
  // const [numOpenAnodeConstructionR2R, setNumOpenAnodeConstructionR2R] = useState<number>(0);
  const [numOpenBatteryConstruction, setNumOpenBatteryConstruction] = useState<number>(0);
  const [numOpenBatteryTest, setNumOpenBatteryTest] = useState<number>(0);
  const [numOpenCharacteristics, setNumOpenCharacteristics] = useState<number>(0);
  const [numOpenHafttest, setNumOpenHafttest] = useState<number>(0);
  const [numOpenPostMortem, setNumOpenPostMortem] = useState<number>(0);
  const [numOpenRaman, setNumOpenRaman] = useState<number>(0);
  const [numOpenMikroskopie, setNumOpenMikroskopie] = useState<number>(0);
  const [numOpenXRD, setNumOpenXRD] = useState<number>(0);
  const [numOpenREM, setNumOpenREM] = useState<number>(0);
  const [chartData, setChartData] = useState<number[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const [finishedLoop,setFinishedLoop] = useState(false);


  /**
   * collect data
   */
  const loadData = () => {
    ApiWorkPackage.ListAll({
      state: { name: 'inPlanung' },
    }).then((data) => {
      setNumPlannedPackages(data.length);
      setCountLoadings(countLoadings+1);
    });
  };
  const loadChartData = () => {
    let listOfLabels: string[] = [];
    let dataList: number[] = [];

    listOfLabels.push('Anodenbau ' + String(numOpenAnodeConstruction));
    dataList.push(numOpenAnodeConstruction);

    listOfLabels.push('Batteriebau ' + String(numOpenBatteryConstruction));
    dataList.push(numOpenBatteryConstruction);

    listOfLabels.push('BatterieTest ' + String(numOpenBatteryTest));
    dataList.push(numOpenBatteryTest);

    // listOfLabels.push('Charakterisierungen ' + String(numOpenCharacteristics));
    // dataList.push(numOpenCharacteristics);

    listOfLabels.push('LiMi ' + String(numOpenMikroskopie));
    dataList.push(numOpenMikroskopie);

    listOfLabels.push('XRD ' + String(numOpenXRD));
    dataList.push(numOpenXRD);

    listOfLabels.push('REM ' + String(numOpenREM));
    dataList.push(numOpenREM);

    listOfLabels.push('PM REM ' + String(numOpenRaman));
    dataList.push(numOpenRaman);

    listOfLabels.push('PM Foto ' + String(numOpenPostMortem));
    dataList.push(numOpenPostMortem);

    listOfLabels.push('Sonstige ' + String(numOpenHafttest));
    dataList.push(numOpenHafttest);

    setChartData(dataList);
    setLabels(listOfLabels);
  };
  
 


  useEffect(() => {
    document.title = 'Dashboard';
    loadData();
  },[]);
  useEffect(()=>{
    if(countLoadings === 1){
      ApiWorkPackage.ListAll({
        state: { name: 'aktiv' },
      }).then((data) => {
        setNumOpenPackages(data.length);
        setCountLoadings(countLoadings+1);
      });
    }
    else if(countLoadings === 2){
      ApiWorkBlock.ListAll({
        state: { name: 'aktiv' },
      }).then((data) => {
        data = data.filter(x => x.workpackage.stateID =='aktiv')//und abgeschlossen?
        setNumOpenBlocks(data.length);
        let anodeCounts = 0;
        let batterycounts = 0;
        let batteryTestCounts = 0;
        let hafttestCounts = 0;
        let microscopyCounts = 0;
        let remCounts = 0;
        let ramanCounts = 0;
        let xrdCounts = 0;
        let pmVisuellCounts = 0;
        let i = 0;
        //add planned steps to counts
        for(let ab of data){
          i+= 1;
          let plannedOpbject: __PlannedStepsConverted = JSON.parse(ab.plannedSteps);
          //Anoden
          if(plannedOpbject.anodeConstructionR2R){
            if(!ab.anodeConstructionR2R){
              anodeCounts += 1;
            }
            else if(ab.anodeConstructionR2R.stateID === 'aktiv'){
              anodeCounts +=1;
            }
          }
          if(plannedOpbject.anodeConstructionSheetToSheet){
            if(!ab.anodeConstruction){
              anodeCounts += 1;
            }
            else if(ab.anodeConstruction.stateID === 'aktiv'){
              anodeCounts +=1;
            }
          }
          //Batteriebau
          if(plannedOpbject.batteryConstruction){
            //add planned objects
            if(ab.batteryConstruction.length < 2 && plannedOpbject.batteryConstruction.HZ && plannedOpbject.batteryConstruction.VZ){
              batterycounts += 2-ab.batteryConstruction.length
            }
            else if((plannedOpbject.batteryConstruction.HZ || plannedOpbject.batteryConstruction.VZ) && ab.batteryConstruction.length < 1){
              batterycounts += 1;
            }
            //in case every construction is acitve, add these ones too
            else if(ab.batteryConstruction.length > 0){
              for(let item of ab.batteryConstruction){
                if(item.stateID === 'aktiv'){
                  batterycounts += 1;
                }
              }
            }
          }
          //Batterietest
          if(plannedOpbject.batteryTest){
            //add planned objects
            if(ab.batteryTest.length < 2 && plannedOpbject.batteryTest.HZ && plannedOpbject.batteryTest.VZ){
              batteryTestCounts += 2-ab.batteryTest.length
            }
            else if((plannedOpbject.batteryTest.HZ || plannedOpbject.batteryTest.VZ) && ab.batteryTest.length < 1){
              batteryTestCounts += 1;
            }
            //in case every construction is acitve, add these ones too
            else if(ab.batteryTest.length > 0){
              for(let item of ab.batteryTest){
                if(item.stateID === 'aktiv'){
                  batteryTestCounts += 1;
                }
              }
            }
          }
          //Analysen
          if(plannedOpbject.analysis){
            if(plannedOpbject.analysis.adhesionTest){
              //planned
              if(!ab.characteristics?.adhesionTest){
                hafttestCounts += 1;
              }
              //aktiv
              else if(ab.characteristics.adhesionTest.stateID === 'aktiv'){
                hafttestCounts +=1;
              }
            }
            if(plannedOpbject.analysis.microscopy){
              //planned
              if(!ab.characteristics?.microscopy){
                microscopyCounts += 1;
              }
              //aktiv
              else if(ab.characteristics.microscopy.stateID === 'aktiv'){
                microscopyCounts +=1;
              }
            }
            if(plannedOpbject.analysis.xrd){
              //planned
              if(!ab.characteristics?.xrd){
                xrdCounts += 1;
              }
              //aktiv
              else if(ab.characteristics.xrd.stateID === 'aktiv'){
                xrdCounts +=1;
              }
            }
            if(plannedOpbject.analysis.raman){
              //planned
              if(!ab.characteristics?.raman){
                ramanCounts += 1;
              }
              //aktiv
              else if(ab.characteristics.raman.stateID === 'aktiv'){
                ramanCounts +=1;
              }
            }
            if(plannedOpbject.analysis.rem){
              //planned
              if(!ab.characteristics?.rem){
                remCounts += 1;
              }
              //aktiv
              else if(ab.characteristics.rem.stateID === 'aktiv'){
                remCounts +=1;
              }
            }
            if(plannedOpbject.analysis.postMortemAnalysis){
              //planned
              if(!ab.characteristics?.postMortemAnalysis){
                pmVisuellCounts += 1;
              }
              //aktiv
              else if(ab.characteristics.postMortemAnalysis.stateID === 'aktiv'){
                pmVisuellCounts +=1;
              }
            }
          }
          //last block --> set all data
          if(i===data.length){
            setNumOpenAnodeConstruction(anodeCounts);
            setNumOpenBatteryConstruction(batterycounts);
            setNumOpenBatteryTest(batteryTestCounts);
            setNumOpenHafttest(hafttestCounts);
            setNumOpenMikroskopie(microscopyCounts);
            setNumOpenPostMortem(pmVisuellCounts);
            setNumOpenREM(remCounts);
            setNumOpenRaman(ramanCounts);
            setNumOpenXRD(xrdCounts);
            setFinishedLoop(true)
          }
        }
      });
    }
    // else if(countLoadings === 3){
    //   ApiAnodeConstruction.ListAll().then((data1) => {
    //     ApiAnodeConstructionR2R.ListAll().then((data2) => {
    //       data1 = data1.filter(x => x.state.name === 'aktiv' && (x.workblock.stateID === 'aktiv' || x.workblock.stateID==='abgeschlossen'));
    //       data2 = data2.filter(x => x.state.name === 'aktiv');
    //       setNumOpenAnodeConstruction(data1.length + data2.length+numOpenAnodeConstruction);
    //       // setNumOpenAnodeConstructionR2R(data2.length);
    //       // setNumOpenAnodeConstructionS2S(data1.length);
    //       setCountLoadings(countLoadings+1);
    //     });
    //   });
    // }
    // else if(countLoadings === 4){
    //   ApiBatteryConstruction.ListAll().then((data) => {
    //     data = data.filter(x => x.state.name === 'aktiv')
    //     setNumOpenBatteryConstruction(data.length+numOpenBatteryConstruction);
    //     setCountLoadings(countLoadings+1);
    //   });
    // }
    // else if(countLoadings === 5){
    //   ApiBatteryTest.ListAll().then((data) => {
    //     data = data.filter(x => x.state.name === 'aktiv')
    //     setNumOpenBatteryTest(data.length+numOpenBatteryTest);
    //     setCountLoadings(countLoadings+1);
    //   });
    // }
    // else if(countLoadings === 6){
    //   ApiCharacteristics.ListAll().then((data) => {
    //     data = data.filter(x => x.state.name === 'aktiv')
    //     setNumOpenCharacteristics(data.length);
    //     let adhesionTest = data.map(x => x.adhesionTest).filter(x => x && x.stateID === 'aktiv');
    //     let xrd = data.map(x => x.xrd).filter(x => x && x.stateID === 'aktiv');
    //     let rem = data.map(x => x.rem).filter(x => x && x.stateID === 'aktiv');
    //     let raman = data.map(x => x.raman).filter(x => x && x.stateID === 'aktiv');
    //     let postMortemAnalysis = data.map(x => x.postMortemAnalysis).filter(x => x && x.stateID === 'aktiv');
    //     let microscopy = data.map(x => x.microscopy).filter(x => x && x.stateID === 'aktiv');
    //     setNumOpenHafttest(adhesionTest.length+numOpenHafttest);
    //     setNumOpenXRD(xrd.length+numOpenXRD);
    //     setNumOpenREM(rem.length+numOpenREM);
    //     setNumOpenRaman(raman.length+numOpenRaman);
    //     setNumOpenPostMortem(postMortemAnalysis.length+numOpenPostMortem);
    //     setNumOpenMikroskopie(microscopy.length+numOpenMikroskopie);
    //     setCountLoadings(countLoadings+1);
    //   });
    // }
    else if(countLoadings >= 2){
      setDataLoaded(true);
    }
  },[countLoadings])
  useEffect(()=>{
    if(dataLoaded){
      loadChartData();
    }
  },[dataLoaded]);
  useEffect(()=>{
    if(chartData.length > 0){
      setInitialization(true);
    }
  },[chartData]);
  useEffect(()=>{
    if(finishedLoop){
      setCountLoadings(countLoadings+1);
    }
  },[finishedLoop]);

  return (
    <>
    {
      initialized
      ? <div className='content-main-container'>
          <h2 className=''>Arbeitspakete</h2>
            <div className='row mt-2'>
              <div className='col'>
                <Card title='Arbeitspakete in Planung' linkText="Zur Planung" mainContent={numPlannedPackages.toString()} path='/planung' />
              </div>
              <div className='col'>
                <Card title='Offene Arbeitspakete' linkText="Zu den Arbeitspaketen" mainContent={numOpenPackages.toString()} path='/arbeitspakete' />
              </div>
              <div className='col'>
                <Card title='Offene Arbeitsblöcke' linkText="Zur Gesamtübersicht" mainContent={numOpenBlocks.toString()} path='/gesamtuebersicht' />
              </div>
            </div>
            <div className='row mt-5' style={{height:'40em'}}>
              <h2 className='mb-0'>Offene Prozesse</h2>
              <BarChart
                labels={labels}
                datasets={[{
                    data: chartData,
                    label: 'Offene Prozesse',
                    backgroundColor: 'rgba(75, 95, 128, 0.85)',
                    borderColor: 'rgba(75, 95, 128,1)',
                    borderWidth: 1,
                    barThickness: 'flex'
                }
                ]}
                optionData={{
                  legendposotion: 'right',
                  horizontal: true
                }}
              />
            </div>

        </div>
      : <></>
    }
    </>
  );
}
