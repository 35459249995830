import Config from "../util/Config";
import { __EvaluationConverted,CreateBasicResponse,__PostMortemAnalysisBatteryDataConverted, __PlannedStepsConverted } from "./types/__GeneralStructures";
import { ApiClient } from "./ApiClient";
import BasicResponse from "./BasicResponse";
import * as requestTypes from "./types/__RequestStructures"
import * as responseTypes from "./types/__ResponseStructures"
import { ApiPrepareRequest } from './ApiPrepareRequest';


export class ApiCharacteristics {

    static basePath: string = "/characteristics";
    static baseUrl: string = Config.BackendUrl + ApiCharacteristics.basePath;
     //Get
    static Get(id: string = ''): Promise<responseTypes.IBackendCharacteristics> {
        let path = '/get';

        // prepare query
        let query = ApiPrepareRequest.PrepareGetQuery(id);
        let url = ApiCharacteristics.baseUrl + path + query;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.get(url);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject = (await response.json()) as BasicResponse<responseTypes.IBackendCharacteristics>;

        resolve(responseObject.data);
        });
    };
    // TODO: add filter and search
    /**
     * /auth/list
     */
    static ListAll(): Promise<responseTypes.IBackendCharacteristics[]> {

        let path = "/list";
        let url = ApiCharacteristics.baseUrl + path;

        return new Promise(async (resolve, reject) => {

            let response = await ApiClient.get(url);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as BasicResponse<responseTypes.IBackendCharacteristics[]>;

            resolve(responseObject.data);

        });

    };
    static Create(requestData: requestTypes.__ChracteristicsCreate): Promise<string> {
        let path = "/create";
        let url = ApiCharacteristics.baseUrl + path;
    
    
        return new Promise(async (resolve, reject) => {
            let convertedRequestData = {
                platform: requestData.platform,
                workBlock: requestData.workBlock,
                state: requestData.state,
                evaluation: JSON.parse(JSON.stringify(requestData.evaluation)) as __EvaluationConverted,
                responsibleUser: requestData.responsibleUser
                
            };
    
            let response = await ApiClient.post(url, convertedRequestData);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }
    
            let responseObject = (await response.json()) as CreateBasicResponse<string>;
    
            resolve(responseObject.id);
        });
    };
    static CreateAdhesionTest(requestData: requestTypes.__AdhesionTestCreate): Promise<string> {
        let path = "/createadhesiontest";
        let url = ApiCharacteristics.baseUrl + path;


        return new Promise(async (resolve, reject) => {
            let convertedRequestData: requestTypes.__AdhesionTestCreate = {
                platform: requestData.platform,
                characteristicID: requestData.characteristicID,
                adhesionTest: {
                    id: '',
                    responsibleUser: requestData.adhesionTest.responsibleUser,
                    state: requestData.adhesionTest.state,
                    evaluation: JSON.parse(JSON.stringify(requestData.adhesionTest.evaluation)) as __EvaluationConverted,
                    data: requestData.adhesionTest.data,
                    comment: requestData.adhesionTest.comment
                }
            };

            let response = await ApiClient.post(url, convertedRequestData);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as CreateBasicResponse<string>;

            resolve(responseObject.id);
        });
    };
    static CreateMicroscopy(requestData: requestTypes.__MicroscopyCreate): Promise<string> {
        let path = "/createmicroscopy";
        let url = ApiCharacteristics.baseUrl + path;


        return new Promise(async (resolve, reject) => {
            let convertedRequestData:requestTypes.__MicroscopyCreate = {
                platform: requestData.platform,
                characteristicID: requestData.characteristicID,
                microscopy: {
                    id:'',
                    responsibleUser: requestData.microscopy.responsibleUser,
                    state: requestData.microscopy.state,
                    evaluation: JSON.parse(JSON.stringify(requestData.microscopy.evaluation)) as __EvaluationConverted,
                    data: requestData.microscopy.data,
                    comment: requestData.microscopy.comment
                }
            };

            let response = await ApiClient.post(url, convertedRequestData);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as CreateBasicResponse<string>;

            resolve(responseObject.id);
        });
    };
    static CreateRem(requestData: requestTypes.__RemCreate): Promise<string> {
        let path = "/createrem";
        let url = ApiCharacteristics.baseUrl + path;


        return new Promise(async (resolve, reject) => {
            let convertedRequestData:requestTypes.__RemCreate = {
                platform: requestData.platform,
                characteristicID: requestData.characteristicID,
                rem: {
                    id: '',
                    responsibleUser: requestData.rem.responsibleUser,
                    state: requestData.rem.state,
                    evaluation: JSON.parse(JSON.stringify(requestData.rem.evaluation)) as __EvaluationConverted,
                    data: requestData.rem.data,
                    comment: requestData.rem.comment
                }
            };

            let response = await ApiClient.post(url, convertedRequestData);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as CreateBasicResponse<string>;

            resolve(responseObject.id);
        });
    };
    static CreateXrd(requestData: requestTypes.__XrdCreate): Promise<string> {
        let path = "/createxrd";
        let url = ApiCharacteristics.baseUrl + path;


        return new Promise(async (resolve, reject) => {
            let convertedRequestData: requestTypes.__XrdCreate = {
                platform: requestData.platform,
                characteristicID: requestData.characteristicID,
                xrd: {
                    id:'',
                    responsibleUser: requestData.xrd.responsibleUser,
                    state: requestData.xrd.state,
                    evaluation: JSON.parse(JSON.stringify(requestData.xrd.evaluation)) as __EvaluationConverted,
                    data: requestData.xrd.data,
                    comment: requestData.xrd.comment
                }
            };
            let response = await ApiClient.post(url, convertedRequestData);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as CreateBasicResponse<string>;

            resolve(responseObject.id);
        });
    };
    static CreateRaman(requestData: requestTypes.__RamanCreate): Promise<string> {
        let path = "/createraman";
        let url = ApiCharacteristics.baseUrl + path;


        return new Promise(async (resolve, reject) => {
            let convertedRequestData: requestTypes.__RamanCreate = {
                platform: requestData.platform,
                characteristicID: requestData.characteristicID,
                raman: {
                    id: '',
                    responsibleUser: requestData.raman.responsibleUser,
                    state: requestData.raman.state,
                    evaluation: JSON.parse(JSON.stringify(requestData.raman.evaluation)) as __EvaluationConverted,
                    data: requestData.raman.data,
                    comment: requestData.raman.comment
                }
            };
            let response = await ApiClient.post(url, convertedRequestData);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as CreateBasicResponse<string>;

            resolve(responseObject.id);
        });
    };
    static CreatePostMortemAnalysis(requestData: requestTypes.__PostMortemAnalysisCreate): Promise<string> {
        let path = "/createpostMortemAnalysis";
        let url = ApiCharacteristics.baseUrl + path;


        return new Promise(async (resolve, reject) => {
            //convert all analysis 
            let analysis = [];
            for (let item of requestData.analysis){
                let convertedBattery =JSON.parse(JSON.stringify(item)) as __PostMortemAnalysisBatteryDataConverted
                analysis.push(convertedBattery);
            }
            let convertedRequestData = {
                platform: requestData.platform,
                characteristicID: requestData.characteristicID,
                postMortem: {
                    responsibleUser: requestData.postMortem.responsibleUser,
                    state: requestData.postMortem.state,
                    evaluation: JSON.parse(JSON.stringify(requestData.postMortem.evaluation)) as __EvaluationConverted,
                },
                analysis:analysis
            };
            let response = await ApiClient.post(url, convertedRequestData);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as CreateBasicResponse<string>;

            resolve(responseObject.id);
        });
    };
    static Edit(requestData: requestTypes.__ChracteristicsEdit):Promise<boolean>{
        let path = "/edit";
        let url = ApiCharacteristics.baseUrl + path;
        return new Promise(async (resolve, reject) => {
            let convertedRequestData = {
                id: requestData.id,
                state: requestData.state,
                date: requestData.date,
                evaluation: JSON.parse(JSON.stringify(requestData.evaluation)) as __EvaluationConverted,
                responsibleUser: requestData.responsibleUser
                
            };
          let response = await ApiClient.put(url, convertedRequestData);
          if (!response.ok) {
              if (response.status === 401) {
                  reject("Unauthorized!");
              }
              else {
                  reject(`Error! status: ${response.status}`);
              }
          }
    
          let responseObject = (await response.json()) as BasicResponse<string>;
    
          resolve(responseObject.success);
      });
    };
    static EditAdhesionTest(requestData: requestTypes.__AdhesionTestEdit):Promise<boolean>{
        let path = "/editadhesiontest";
        let url = ApiCharacteristics.baseUrl + path;
        let convertedRequestData:requestTypes.__AdhesionTestEdit = {
            id: requestData.id,
            date:requestData.date,
            changes: {
                id:requestData.changes.id,
                responsibleUser: requestData.changes.responsibleUser,
                state: requestData.changes.state,
                evaluation: JSON.parse(JSON.stringify(requestData.changes.evaluation)) as __EvaluationConverted,
                data: requestData.changes.data,
                comment: requestData.changes.comment,
            },
            deletedData: requestData.deletedData
        };
        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.put(url, convertedRequestData);
        if (!response.ok) {
            if (response.status === 401) {
                reject("Unauthorized!");
            }
            else {
                reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject = (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
    });
    };
    static EditMicroscopy(requestData: requestTypes.__MicroscopyEdit):Promise<boolean>{
        let path = "/editmicroscopy";
        let url = ApiCharacteristics.baseUrl + path;
        return new Promise(async (resolve, reject) => {
            let convertedRequestData:requestTypes.__MicroscopyEdit = {
                id: requestData.id,
                date: requestData.date,
                changes: {
                    id:requestData.changes.id,
                    responsibleUser: requestData.changes.responsibleUser,
                    state: requestData.changes.state,
                    evaluation: JSON.parse(JSON.stringify(requestData.changes.evaluation)) as __EvaluationConverted,
                    data: requestData.changes.data,
                    comment: requestData.changes.comment
                },
                deletedData: requestData.deletedData
            };
            let response = await ApiClient.put(url, convertedRequestData);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as BasicResponse<string>;

            resolve(responseObject.success);
        });
    };
    static EditRem(requestData: requestTypes.__RemEdit):Promise<boolean>{
        let path = "/editrem";
        let url = ApiCharacteristics.baseUrl + path;
        return new Promise(async (resolve, reject) => {
            let convertedRequestData:requestTypes.__RemEdit = {
                id: requestData.id,
                date: requestData.date,
                changes: {
                    id: requestData.changes.id,
                    responsibleUser: requestData.changes.responsibleUser,
                    state: requestData.changes.state,
                    evaluation: JSON.parse(JSON.stringify(requestData.changes.evaluation)) as __EvaluationConverted,
                    data: requestData.changes.data,
                    comment: requestData.changes.comment
                },
                deletedData: requestData.deletedData

            };
            let response = await ApiClient.put(url, convertedRequestData);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as BasicResponse<string>;

            resolve(responseObject.success);
        });
    };
    static EditXrd(requestData: requestTypes.__XrdEdit):Promise<boolean>{
        let path = "/editxrd";
        let url = ApiCharacteristics.baseUrl + path;
        return new Promise(async (resolve, reject) => {
            let convertedRequestData:requestTypes.__XrdEdit = {
                id: requestData.id,
                date: requestData.date,
                changes: {
                    id: requestData.changes.id,
                    responsibleUser: requestData.changes.responsibleUser,
                    state: requestData.changes.state,
                    evaluation: JSON.parse(JSON.stringify(requestData.changes.evaluation)) as __EvaluationConverted,
                    data: requestData.changes.data,
                    comment: requestData.changes.comment
                },
                deletedData: requestData.deletedData
            };
            let response = await ApiClient.put(url, convertedRequestData);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as BasicResponse<string>;

            resolve(responseObject.success);
        });
    };
    static EditRaman(requestData: requestTypes.__RamanEdit):Promise<boolean>{
        let path = "/editraman";
        let url = ApiCharacteristics.baseUrl + path;
        return new Promise(async (resolve, reject) => {
            let convertedRequestData:requestTypes.__RamanEdit = {
                id: requestData.id,
                date: requestData.date,
                changes: {
                    id: requestData.changes.id,
                    responsibleUser: requestData.changes.responsibleUser,
                    state: requestData.changes.state,
                    evaluation: JSON.parse(JSON.stringify(requestData.changes.evaluation)) as __EvaluationConverted,
                    data: requestData.changes.data,
                    comment: requestData.changes.comment
                },
                deletedData: requestData.deletedData
            };
            let response = await ApiClient.put(url, convertedRequestData);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as BasicResponse<string>;

            resolve(responseObject.success);
        });
    };
    static EditPostMortemAnalysis(requestData: requestTypes.__PostMortemAnalysisEdit):Promise<boolean>{
        let path = "/editpostmortemanalysis";
        let url = ApiCharacteristics.baseUrl + path;
        return new Promise(async (resolve, reject) => {
            //convert all analysis 
            let analysis = [];
            for (let item of requestData.analysis){
                let convertedBattery =JSON.parse(JSON.stringify(item)) as __PostMortemAnalysisBatteryDataConverted
                analysis.push(convertedBattery);
            }
            let convertedRequestData = {
                id: requestData.id,
                date: requestData.date,
                changes: {
                    responsibleUser: requestData.changes.responsibleUser,
                    state: requestData.changes.state,
                    evaluation: JSON.parse(JSON.stringify(requestData.changes.evaluation)) as __EvaluationConverted,
                },
                analysis:analysis,
                deletedData: requestData.deletedData
            };
            
            let response = await ApiClient.put(url, convertedRequestData);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as BasicResponse<string>;

            resolve(responseObject.success);
        });
    };
    //delete
    static Delete(requestData: requestTypes.__IDRequest): Promise<boolean> {
        let path = '/delete';
        let url = ApiCharacteristics.baseUrl + path;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject =
            (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
        });
    };
    //lock
    static Lock(requestData: requestTypes.__IDRequest): Promise<boolean> {
        let path = '/lock';
        let url = ApiCharacteristics.baseUrl + path;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject =
            (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
        });
    };
    //activate
    static Activate(requestData: requestTypes.__IDRequest): Promise<boolean> {
        let path = '/activate';
        let url = ApiCharacteristics.baseUrl + path;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject =
            (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
        });
    };
    //close
    static Close(requestData: requestTypes.__IDRequest): Promise<boolean> {
        let path = '/close';
        let url = ApiCharacteristics.baseUrl + path;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject =
            (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
        });
    };
    //upload
    static Upload(requestData: requestTypes.__UploadFiles): Promise<boolean> {
        let path = '/upload';
        let url = ApiCharacteristics.baseUrl + path;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject =
            (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
        });
    };
    //generatepdf
    static GeneratePdf(requestData:requestTypes.__GeneratePDFForCharacteristics): Promise<string> {
        let id = requestData.id;
        let typeofAnalysis = requestData.type;
        let path = '/generatepdf';
        let query = '?id=' + id+ '&typeofAnalysis=' + typeofAnalysis;
        let url = ApiCharacteristics.baseUrl + path + query;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.get(url);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject = (await response.json()) as BasicResponse<string>;

        resolve(responseObject.data);
        });
    };
}

export class CharacteristicsConverter {
    // Temporärer Hinweis: Gedacht für die Tabellen Planung und Arbeitspakete
    static ConvertFromApiToTableList(data: responseTypes.IBackendCharacteristics[]) {
        // [1, 'Haft 1', "Hafttest", 'Max', '15.03.2023', 'abgeschlossen', 'Halle'],
        let simpleList: string[][] = [];
        data.forEach(item => {
            let myPlannedSteps = JSON.parse(item.workblock.plannedSteps) as __PlannedStepsConverted;
            let types = "";
            /**
             * Charakterisierung
             * Umbenennungen:
                 Hafttest -> Sonstiges
                Post Mortem -> PM Foto
                Raman -> PM REM
                Mikroskopie -> LiMi
            */
            if (item.microscopy != null || myPlannedSteps.analysis?.microscopy) types += "LiMi, ";
            if (item.rem != null || myPlannedSteps.analysis?.rem) types += "REM, ";
            if (item.xrd != null || myPlannedSteps.analysis?.xrd) types += "XRD, ";
            if (item.raman != null || myPlannedSteps.analysis?.raman) types += "PM REM, ";
            if (item.postMortemAnalysis != null || myPlannedSteps.analysis?.postMortemAnalysis) types += 'PM Foto, ';
            if (item.adhesionTest != null || myPlannedSteps.analysis?.adhesionTest) types += "Sonstiges";

            simpleList.push([
                item.id,
                item.workblock.name ?? '',
                types,
                item.responsibleUser[0].displayName,
                new Date(item.date).toLocaleDateString(),
                item.state.name,
            ]);
        });

        return simpleList;
    }
}


