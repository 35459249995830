import { rejects } from "assert";
import Config from "../util/Config";
import BasicReponse from "./BasicResponse";
import { __Platform, __Role, __Userstate } from "./types/__GeneralStructures";
import { ApiClient } from "./ApiClient";
import BasicResponse from "./BasicResponse";

export interface IBackendGetEnumerations {
  success: boolean;
  data: Record<string, string[]>;
}


export class ApiBackendData {

  static basePath: string = "/data";
  static baseUrl: string = Config.BackendUrl + ApiBackendData.basePath;


  static enumerations: Record<string, string[]> | null = null;

  static GetEnumerations(): Promise<Record<string, string[]>> {

    let path = "/getEnumerations";
    let url = ApiBackendData.baseUrl + path;

    return new Promise(async (resolve, reject) => {

      if (ApiBackendData.enumerations != null) {
        resolve(ApiBackendData.enumerations);
      }

      let response = await ApiClient.get(url);
      if (!response.ok) {
        if (response.status == 401) {
          reject("Unauthorized!");
        }
        else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<Record<string, string[]>>;

      ApiBackendData.enumerations = responseObject.data;
      resolve(ApiBackendData.enumerations);

    });

  };

  static GetEnumerationsSync(): Record<string, string[]> {

    let path = "/getEnumerations";
    let url = ApiBackendData.baseUrl + path;

    if (ApiBackendData.enumerations != null) {
      return ApiBackendData.enumerations;
    }

    let responseObject = ApiClient.getSync(url) as BasicResponse<Record<string, string[]>>;

    ApiBackendData.enumerations = responseObject.data;
    return ApiBackendData.enumerations;

  }

}
