import { useNavigate, useLocation } from 'react-router-dom';
import Ablaufdiagramm from '../../components/Ablaufdiagramm';

export default function ArbeitsblockDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const arbeitsblock = {
    id: '1',
    name: 'Arbeitsblock XY',
    title: 'Ein wenig Arbeit',
    verantwortlicher: 'Tony',
    datum: '01.01.2021',
    status: 'abgeschlossen',
    syntheseort: 'Halle',
    beschreibung: 'Eine sehr lange Beschreibung',
    arbeitspakete: [
      {
        title: 'Anodenbau',
        notes: ['Folie', 'Sheet to Sheet', 'Sheets: 3', 'Länge: 10mm'],
        status: 'completed',
        lineState: 'upcoming',
        link: '/anodenbau/sheetToSheet',
        linkProps: {},
      },
      {
        title: 'Batteriebau',
        notes: ['Halbzelle', 'Anzahl: 3'],
        status: 'in-progress',
        lineState: 'upcoming',
        link: '/anodenbau/sheetToSheet',
        linkProps: {},
      },
      {
        title: 'Batterietest',
        notes: ['Halbzellen'],
        status: 'upcoming',
        lineState: 'upcoming',
        link: '/anodenbau/sheetToSheet',
        linkProps: {},
      },
      {
        title: 'Charakterisierung',
        notes: ['Mikroskopie', 'REM', 'Post-Mortem Visuell'],
        status: 'upcoming',
        lineState: 'upcoming',
        link: '/anodenbau/sheetToSheet',
        linkProps: {},
      },
      {
        title: 'Zusammenfassung',
        notes: [],
        status: 'upcoming',
        lineState: 'upcoming',
        link: '/anodenbau/sheetToSheet',
        linkProps: {},
      },
    ],
  };

  const handleEdit = () => {
    navigate(`/arbeitsbloecke/ergaenzung`);
  };
  return (
    <div className='content-main-container'>
      <h1>{arbeitsblock.name}</h1>
      <div className='margin-top-20 paket-information-container row'>
        <div className='column'>
          <div className='row'>
            <p>Name:</p>
            <p>{arbeitsblock.title}</p>
          </div>
          <div className='row'>
            <p>Verantwortlicher:</p>
            <p>{arbeitsblock.verantwortlicher}</p>
          </div>
        </div>
        <div style={{ maxWidth: '600px' }} className='column margin-left-20'>
          <div className='row'>
            <p>Syntheseort:</p>
            <p>{arbeitsblock.syntheseort}</p>
          </div>
          <div className='row'>
            <p>Beschreibung:</p>
            <p>{arbeitsblock.beschreibung}</p>
          </div>
        </div>
      </div>
      <input
        className='btn-main'
        type='button'
        value='Bearbeiten'
        onClick={handleEdit}
      />
      <h2 className='margin-top-10'>Arbeitspakete</h2>
      <Ablaufdiagramm ablauf={arbeitsblock.arbeitspakete} disableLinks={false}/>
    </div>
  );
}
