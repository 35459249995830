import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import {
  tableHeadersOne,
  tableHeadersTwo,
  emptyTableOne,
  emptyTableTwo,
  InputElementTableOne,
  InputElementTableTwo,
} from './AnodenbauSheetToSheetData';
import InputField from '../../UI/InputField';
//import { enums, userNamesList } from '../../data';
import { useNavigate, useLocation } from 'react-router-dom';
import { ApiBackendData } from '../../backend/ApiBackendData';
import { ApiAnodeConstruction } from '../../backend/ApiAnodeConstruction';
import {
  __AnodeConstructionSheetToSheetCreateConverted,
  __AnodeConstructionSheetToSheetEditConverted,
  __MaterialUnconverted,
} from '../../backend/types/__RequestStructures';
import {
  __FileResponse,
  __LayerSheetToSheetConverted,
  __LayerSheetToSheetUnconverted,
  __LightningSheetToSheetConverted,
  __LightningSheetToSheetUnconverted,
} from '../../backend/types/__GeneralStructures';
import Config from '../../util/Config';
import { ConfirmationModal, InfoModal } from '../../components/Modal';
import MultiSelectDropdown, { MultiSelectDropdownWithCheckboxes } from '../../components/MultiSelectDropdown';
import { ApiWorkBlock } from '../../backend/ApiWorkBlock';
import Loading from '../../components/Ladebalken';
import FileUploadAccordeon from '../../components/FileUpload';
import { ApiFile } from '../../backend/ApiFiles';
import { ApiMaterial } from '../../backend/ApiMaterial';
import { IBackendAnodeConstructionSheetToSheetGet, IBackendAnodeConstructionSheetToSheetList, IBackendAnodes } from '../../backend/types/__ResponseStructures';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faXmark } from '@fortawesome/free-solid-svg-icons';
import { ApiAuth } from '../../backend/ApiAuth';

interface IBlitzProps {
  annotation: string;
  anodeID: string;
  energyKV: number;
  id: string;
  impulseLength: number;
  intervalLength: number;
  mm_S: number;
  number: number;
  numberOfLightnings: number;
  platformID: string;
  position: string;
};
interface ILayerProps {
  annotation: string;
  anodeID: string;
  height: string;
  material: string;
  lightnings: {
    annotation: string;
    anodeID: string;
    energyKV: number;
    id: string;
    impulseLength: number;
    intervalLength: number;
    mm_S: number;
    number: number;
    numberOfLightnings: number;
    platformID: string;
    position: string;
  }[];
  id: string;
  number: number;
  pendulumStrokes: number;
  platform:{
    name: string;
  }
};

const createChangeHandler =
  <T extends { [key: string]: any }>(
    table: T[],
    setInputs: React.Dispatch<React.SetStateAction<T[]>>
  ) =>
  (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    let val: string| number = value;
    if(name === 'anzahl'){
      val = Number(val);
    }
    else{
      val = String(val);
    }
    const list = [...table];
    list[index] = { ...list[index], [name]: val };
    setInputs(list);
  };


export default function AnodenbauSheetToSheet() {
  // call Apis for basic data
  let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();

  const navigate = useNavigate();
  const location = useLocation();

  const [userList, setUserList] = useState(location.state.userList);
  const [userLoginList, setUserLoginList] = useState(location.state.userLoginList);
  const [anodeConstructionData, setAnodeConstructionData] = useState<IBackendAnodeConstructionSheetToSheetGet>(location.state.data);
  /**
 * Table data
 */
  emptyTableOne.schicht = '1';
  emptyTableTwo.blitz = '1';
  let currentFullDate = new Date();
  let month: number|string = currentFullDate.getMonth()+1;
  if (month < 10){
    month = '0' + month.toString();
  }
  else{
    month = month.toString();
  }
  let date =  [currentFullDate.getFullYear().toString(), month,currentFullDate.getDate().toString()].join('-');

  const tHeadersOne = (
    <thead>
      <tr className=" align-middle">
        {tableHeadersOne.map((header) => {
          if(header=='') {
            return <th className="width-76" key={header}>{header}</th>
          } 
          else {
            return <th key={header}>{header}</th>
          }
        })}
      </tr>
    </thead>
  );
  const tHeadersTwo = (
    <thead>
      <tr className=" align-middle">
        {tableHeadersTwo.map((header) => (
          <th key={header}>{header}</th>
        ))}
      </tr>
    </thead>
  );
  
  const createTableOneData = (): InputElementTableOne[] => {
    let blitzArray = anodeConstructionData.lightnings ? anodeConstructionData.lightnings.map(
      (blitz: { number: any }) => {
        return blitz.number;
      }
    ) : [];
    setBlitzList(blitzArray);
    let blitzOptions: string[] =[];
    for(let blitz of blitzArray){
      blitzOptions.push(blitz.toString());
    }
    setBlitzListOptions(blitzOptions);
    let temp: InputElementTableOne[] = anodeConstructionData.layer ? anodeConstructionData.layer.map((layer: ILayerProps) => {
      let tempLightnings: {id:string,label:string}[] = layer.lightnings.map(x=>{ return {id: String(x.number-1), label:x.number.toString()}});
      return {
        schicht: layer.number.toString(),
        material: layer.material,
        pendelhuebe: layer.pendulumStrokes.toString(),
        hoehe: layer.height,
        blitz: layer.lightnings.map(x => x.number.toString()),
        kommentar: layer.annotation,
        blitzList: tempLightnings
      };
    }) : [];

    // selection list
    let newSelectionList = [];
    for(let t of temp) {
      newSelectionList.push(false);
    }
    setSelectionList(newSelectionList);

    return temp;
  };
  const createTableTwoData = (): InputElementTableTwo[] => {
    let temp = anodeConstructionData.lightnings ? anodeConstructionData.lightnings.map((lightning: IBlitzProps) => {
      return {
        blitz: lightning.number.toString(),
        anzahl: lightning.numberOfLightnings,
        energie: String(lightning.energyKV),
        position: lightning.position,
        mms: String(lightning.mm_S),
        impulslaenge: String(lightning.impulseLength),
        intervallaenge: String(lightning.intervalLength),
        kommentar: lightning.annotation,
      };
    }) : [];
    return temp;
  };

  /**
   * UseStates
   */
  const [packagename, setPackagename] = useState('');
  const [blockname, setBlockname] = useState('');
  const [nameAlreadyExists, setNameAlreadyExists] = useState<boolean>(false);

  const [files, setFiles] = useState<__FileResponse[]>([]);
  const [loadFiles, setLoadFiles] = useState(false);
  const [infotext, setInfotext] = useState('');

  const [selectionList, setSelectionList] = useState<boolean[]>([]);
  const [deleteRefresh, setDeleteRefresh] = useState<boolean>(true);
  const [initialized, setInitialization] = useState(false);
  
  const [blitzList, setBlitzList] = useState<string[]>([]);
  const [blitzListOptions, setBlitzListOptions] = useState<string[]>([])
  const [inputsHeader, setInputsHeader] = useState({
    anodeID: anodeConstructionData?.id ?? '',
    date: anodeConstructionData?.date.split('T')[0] ?? date,
    verantwortlicher: anodeConstructionData?.responsibleUser[0].displayName ?? userList[0],
    syntheseort: anodeConstructionData?.location.name ?? 'Halle',
    name: anodeConstructionData?.anode?.name ?? '',
    vorgehensbeschreibung: anodeConstructionData?.anode?.description ?? '',
    status: anodeConstructionData?.state.name ?? 'aktiv',
    substrat: anodeConstructionData?.substrat ?? ''
  });

  const [anodeNames, setAnodeNames] = useState<string[]>([]);
  const [anodesList, setAnodesList] = useState<IBackendAnodes[]>([]);
  const [inputCheckbox, setInputCheckbox] = useState({
    vakuumCheckbox: anodeConstructionData?.postVakuumPacking ?? false,
    gloveCheckbox: anodeConstructionData?.postGloveBox ?? false,
    ausstanzenCheckbox: anodeConstructionData?.postPunchOut ?? false,
    kupferstreifenCheckbox: anodeConstructionData?.postCopperStripes ?? false
  });

  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [checkLight, setCheckLights] = useState<any[]>([]);

  const [inputsTableOne, setInputsTableOne] = useState<InputElementTableOne[]>(
    anodeConstructionData ? () => createTableOneData() : [] 
  );
  const [inputsTableTwo, setInputsTableTwo] = useState<InputElementTableTwo[]>(
    anodeConstructionData ? () => createTableTwoData() : [] // [emptyTableTwo]
  );
  const [validatedInputs, setValidatedInputs] = useState(false);
  const [reloadTableOne, setReloadTableOne] = useState(false);

  /**
   * Loading data
   */
  const loadUserList = async() => {
    await ApiAuth.ListAll()
      .then((data)=>{
        let users = data.filter( x=> !x.deleted && x.state.name !== 'gesperrt' && x.state.name !== 'gelöscht');
        setUserList(users.map(x => x.displayName));
        setUserLoginList(users.map(x => x.loginName));
      })
      .catch((e)=>{
        console.log(e);
      });
  };
  const getPackageName = async() =>{
    const blockId: string = anodeConstructionData?.workblock.id ?? location.state.workblockID;
    await ApiWorkBlock.Get(blockId).then((data)=>{
      setPackagename(data.workpackage.name);
      setBlockname(data.name);
    });
    return;
  };
  const loadFilesData = async() =>{
    if(anodeConstructionData){
        await ApiAnodeConstruction.Get(anodeConstructionData.id).then((data)=>{
          const fileList = data.files ?? [];
          setFiles(fileList);
        });
      }
    setLoadFiles(true);
    setOpenLoadingModal(false);
  };
  const loadAnodes = () =>{
    ApiAnodeConstruction.ListAnodes({
      construction:{
        state:{
          name: 'aktiv'
        }
      }
    }).then((data1) =>{
      ApiAnodeConstruction.ListAnodes({
        construction:{
          state:{
            name: 'abgeschlossen'
          }
        }
      }).then((data2)=>{
          let data = [...data1,...data2];
          //sort anodes by name
          data = data.sort((a,b)=>{
            if(a.name.trim().toLowerCase() < b.name.trim().toLowerCase()){
              return -1
            }
            if(a.name.trim().toLowerCase() > b.name.trim().toLowerCase()){
              return 1;
            }
            return 0;
          })
          //set data
          let namesList = data.map(x => x.name.trim());
          setAnodeNames(namesList);
          setAnodesList(data);
          setInitialization(true);
      }).catch((e)=>{
        console.log(e);
      });
    }).catch((e)=>{
      console.log(e);
    });
  };
  const reloadAnodeConstruction = async(id:string) => {
    setOpenLoadingModal(true);
    await ApiAnodeConstruction.Get(id)
      .then((data)=>{
        setAnodeConstructionData(data);
      })
      .catch((e)=> {
        console.log(e);
      });
  };


  /**
   * Handle Functions
   */

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputsHeader((values) => ({ ...values, [name]: value }));
  };
  const handleInputCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setInputCheckbox((prevState) => ({ ...prevState, [name]: checked }));
  };
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setInputsHeader((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    if(name === 'status' && value === 'abgeschlossen'){
      let newInputs = {...inputsHeader};
      let currentFullDate = new Date();
      let month: number|string = currentFullDate.getMonth()+1;
      if (month < 10){
        month = '0' + month.toString();
      }
      else{
        month = month.toString();
      }
      let date =  [currentFullDate.getFullYear().toString(), month,currentFullDate.getDate().toString()].join('-');
      newInputs.date = date
      newInputs.status = value;
      setInputsHeader(newInputs);
    }
    else{
      setInputsHeader((prevState) => ({ ...prevState, [name]: value }));
    }
  };
  const handleChangeTableOne = createChangeHandler<InputElementTableOne>(
    inputsTableOne,
    setInputsTableOne
  );
  const handleChangeTableTwo = createChangeHandler<InputElementTableTwo>(
    inputsTableTwo,
    setInputsTableTwo
  );
  const handleSelectionChange = (selectedValues: string[], index:number) => {
    let newTableOne = [...inputsTableOne];
    newTableOne[index].blitz = selectedValues;
    newTableOne[index].blitzList = selectedValues.map(x => {return {id: String(parseInt(x)-1), label: x}});
    checkLinkedLightnings(newTableOne);
    setInputsTableOne(newTableOne);
  };

  const handleAddRowTableOne = () => {
    setReloadTableOne(true);
    let newTableOne = JSON.parse(JSON.stringify(emptyTableOne)); // copy
    newTableOne.schicht = (inputsTableOne.length + 1).toString();

    setInputsTableOne([...inputsTableOne, newTableOne]);

    let newSelectionList = [...selectionList];
    newSelectionList.push(false);
    setSelectionList(newSelectionList);
  };
  const handleAddRowAtSpecifiedIndexTableOne = (index: number) => {
    setReloadTableOne(true);
    let newInputsTableOne = [...inputsTableOne];
    //left side of array until index and oush empty row there
    let newInputsTableOneLeft = newInputsTableOne.slice(0,index);

    let newTableOne = JSON.parse(JSON.stringify(emptyTableOne)); // copy
    newTableOne.schicht = (inputsTableOne.length + 1).toString();
    newInputsTableOneLeft.push(newTableOne);

    //handle right side
    let newInputsTableOneRight = newInputsTableOne.slice(index,newInputsTableOne.length);
    
    //concat arrays
    newInputsTableOne = newInputsTableOneLeft.concat(newInputsTableOneRight);

    //reset layer numbers && selectiolist
    let newSelectionList: boolean[] = [];
    for(let idx in newInputsTableOne){
      newInputsTableOne[idx].schicht = String(Number(idx)+1);
      newSelectionList.push(false);
    }
    setInputsTableOne(newInputsTableOne);
    setSelectionList(newSelectionList);
  };
  const handleDuplicateSelectedRows = () => {
    let newRows : InputElementTableOne[] = [];
    let newSelectionList: boolean[] = [...selectionList];
    let offsetCount = 0
    for(let selectionIdx in selectionList) {
      if(selectionList[selectionIdx]){
        let newTableOne = JSON.parse(JSON.stringify(inputsTableOne[selectionIdx])); // copy
        newTableOne.schicht = (inputsTableOne.length + 1 + offsetCount).toString();
  
        newRows.push(newTableOne);
        newSelectionList.push(false);
        offsetCount += 1;
      }
    }

    setInputsTableOne([...inputsTableOne, ...newRows]);
    setSelectionList(newSelectionList);
  };
  const handleLoadAnode = (selectedAnode: IBackendAnodes |null) => {
    if(selectedAnode === null){
      return;
    }
    //add lightnings
    let lastNumberOfLightning = inputsTableTwo.length > 0 ? Number(inputsTableTwo[inputsTableTwo.length-1].blitz) : 0;
    if(selectedAnode.lightnings && selectedAnode.lightnings.length > 0){
      let newInputsTableTwo = [...inputsTableTwo];
      for(let blitz of selectedAnode.lightnings){
        newInputsTableTwo.push({
          blitz: String(lastNumberOfLightning + blitz.number),
          anzahl: blitz.numberOfLightnings,
          energie: String(blitz.energyKV),
          position: String(blitz.position),
          mms: String(blitz.mm_S),
          impulslaenge: String(blitz.impulseLength),
          intervallaenge: String(blitz.intervalLength),
          kommentar: blitz.annotation
        });
      }
      setInputsTableTwo(newInputsTableTwo);
    }
    //add layer
    if(selectedAnode.layers && selectedAnode.layers.length > 0){
      let lastNumberOfLayer = inputsTableOne.length > 0 ? Number(inputsTableOne[inputsTableOne.length-1].schicht) : 0;
      let newInputsTableOne = [...inputsTableOne];
      for(let layer of selectedAnode.layers){
        let blitzNumbers = layer.lightnings.map( x=> String(lastNumberOfLightning + x.number));
        newInputsTableOne.push({
          schicht: String(lastNumberOfLayer +layer.number),
          material: layer.material,
          pendelhuebe: String(layer.pendulumStrokes),
          hoehe: layer.height,
          blitz: blitzNumbers,
          blitzList: blitzNumbers.map((x) => {
            return {id:x.toString(),label:x.toString()};
          }),
          kommentar: layer.annotation
        })
      }
      //reset the number of layers && selectiolist
      let newSelectionList: boolean[] = [];
      for(let idx in newInputsTableOne){
        newInputsTableOne[idx].schicht = String(Number(idx)+1);
        newSelectionList.push(false);
      }
      setInputsTableOne(newInputsTableOne);
      setSelectionList(newSelectionList);
    }

  };
  const handleAddRowTableTwo = async () => {
    setReloadTableOne(true);
    let newTableTwo = JSON.parse(JSON.stringify(emptyTableTwo)); // copy
    if(inputsTableTwo.length > 1){
      newTableTwo.blitz = (Number(inputsTableTwo[inputsTableTwo.length-1].blitz)+1).toString();
    }
    else{
      newTableTwo.blitz = (inputsTableTwo.length+1).toString();
    }

    let newInputsTableTwo = [...inputsTableTwo, newTableTwo];
    await setInputsTableTwo(newInputsTableTwo);
    await setBlitzList([...blitzList, (blitzList.length+1).toString()]);

    let newBlitzList = newInputsTableTwo.map(element => element.blitz);
    await setBlitzListOptions(newBlitzList);
  };
  const handleSelectionRowTableOne = (index: number) => {
    let newSelectionList = selectionList;
    newSelectionList[index] = !newSelectionList[index]
    setSelectionList(newSelectionList);
    setDeleteRefresh(true);
  };


  /**
   * Validations
   */
  const validateInputFields = () => {
    setNameAlreadyExists(false);
    setValidatedInputs(true);
    // setInitialization(false); ## need to fix

    let numList:string[] = [];
    for(let num of inputsTableTwo.map(x=>x.blitz)){
      if(numList.includes(num)){
        setValidatedInputs(false);
        return;
      }
      numList.push(num);
    } 
    let checkTemp = anodeConstructionData && anodeConstructionData.anode
    ? (
      anodeConstructionData.anode.name.trim() !== inputsHeader.name.trim() && inputsHeader.anodeID !== '' && anodeNames.includes(inputsHeader.name.trim())
    ) 
    : false
    if(anodeNames.includes(inputsHeader.name.trim()) && inputsHeader.anodeID === '' ||  checkTemp){
      setNameAlreadyExists(true);
      setValidatedInputs(false);
      return;
    }
    else if(inputsHeader.name.trim() == ''){
      setValidatedInputs(false);
      return;
    }
    setValidatedInputs(true);
  };
  const checkLinkedLightnings = (table:InputElementTableOne[]) =>{
    let blitzNumbers: any[] = [];
    for(let blitz of table.map(entry => entry.blitz)){
      if(blitz.length > 0){
        for(let one of blitz){
          blitzNumbers.push(Number(one));
        }
      }
    }
    setCheckLights(blitzNumbers);
  };

  /**
   * Navigation
   */
  const handleBack = () => {
    navigate('/gesamtuebersicht');
  };
  const handleReloadPage = async () => {
    setLoadFiles(false);
  };

  /**
   * Submiting and Api Calls
   */
  const handleGeneratePdf = async () => {
    let id = anodeConstructionData?.id ?? '';
    if(id === '') return;
    setOpenLoadingModal(true);
    ApiAnodeConstruction.GeneratePdf(id).then((url) => {
      window.open(url, '_blank');
      setOpenLoadingModal(false);
    })
    .catch((e:unknown)=>{
      setOpenLoadingModal(false);
      //open error modal to show fail
      let errorModal = document.getElementById("ErrorDownloadFailed");
      if(!errorModal) return;
      $('#ErrorDownloadFailed').modal('show');
    });
  };
  const handleCreateMaterial = async() =>{
    //create anode material
    let requestDataAnode: __MaterialUnconverted = {
      name: inputsHeader.name,
      platform: Config.Platform,
      materialType: 'Anode',
      total: '1',
      available: '1',
      orderNumber: '',
      state: 'vorhanden',
      origin: 'hergestellt',
      annotation: '',
      deleted: 'false',
      delieverer: '',
      placeOfStorage: '',
      producer: '',
      orderInfo: ''  
    }
    await ApiMaterial.Create(requestDataAnode)
    .then(async()=>{
      setInfotext('Material wurde erstellt.');
      //create cathode material
      let requestDataKathode: __MaterialUnconverted = requestDataAnode;
      requestDataKathode.materialType = 'Kathode'
      await ApiMaterial.Create(requestDataKathode).then(()=>{
        setInfotext('Material wurde erstellt.');
      })
      .catch(()=>{
        setInfotext('Material konnte nicht erstellt werden');
      })
    })
    .catch(() =>{
      setInfotext('Material konnte nicht erstellt werden');
    });
  };
  const submitModalCloseAccordion = (response:FormData)  =>{
    sendFileData(response);
    // send formdata to backend
    return response;
  };
  const sendFileData = async(data:FormData) =>{
    if(anodeConstructionData){
      const id = anodeConstructionData?.id;
      if(id === '') return;
      await ApiFile.Upload(data,'/anode',id).then(async ()=>{
        await ApiAnodeConstruction.Get(id).then((data) => {
          const fileList = data.files?? []
          setFiles(fileList);
        });
      });
    }
    setLoadFiles(false);
  };
  const handleSave = () => {
    setOpenLoadingModal(true);
    if (anodeConstructionData) {
      const responsibleUsers = anodeConstructionData.responsibleUser.map(
        (user: { loginName: any }) => {
          return user.loginName;
        }
      );
      
      let layer = inputsTableOne.map((input, index) => {
        if (anodeConstructionData.layer && anodeConstructionData.layer[index]) {
          return {
            id: anodeConstructionData.layer[index].id,
            number: Number(input.schicht),
            material: input.material,
            pendulumStrokes: Number(input.pendelhuebe.replace(',','.')),
            height: input.hoehe,
            lightnings: input.blitzList
              ? input.blitzList.map(x=>Number(x.label))
              : [],
            annotation: input.kommentar,
          };
        } else {
          return {
            number: Number(input.schicht),
            material: input.material,
            pendulumStrokes: Number(input.pendelhuebe.replace(',','.')),
            height:input.hoehe,
            lightnings: input.blitzList
              ? input.blitzList.map(x=>Number(x.label))
              : [],
            annotation: input.kommentar,
          };
        }
      });
      let lightnings = inputsTableTwo.map((input, index) => {
        if (anodeConstructionData.lightnings && anodeConstructionData.lightnings[index]) {
          return {
            id: anodeConstructionData.lightnings[index].id,
            number: Number(input.blitz),
            numberOfLightnings: Number(input.anzahl),
            energyKV: Number(input.energie.replace(',','.')),
            position: input.position,
            mm_S: Number(input.mms.replace(',','.')),
            impulseLength: Number(input.impulslaenge.replace(',','.')),
            intervalLength: Number(input.intervallaenge.replace(',','.')),
            annotation: input.kommentar,
          };
        } else {
          return {
            number: Number(input.blitz),
            numberOfLightnings: Number(input.anzahl),
            energyKV: Number(input.energie.replace(',','.')),
            position: input.position,
            mm_S: Number(input.mms.replace(',','.')),
            impulseLength: Number(input.impulslaenge.replace(',','.')),
            intervalLength: Number(input.intervallaenge.replace(',','.')),
            annotation: input.kommentar,
          };
        }
      });
      const requestData: __AnodeConstructionSheetToSheetEditConverted = {
        anodeConstructionID: anodeConstructionData.id,
        platform: Config.Platform,
        responsibleUser: responsibleUsers,
        state: inputsHeader.status,
        substrat: inputsHeader.substrat,
        postVakuumPacking: inputCheckbox.vakuumCheckbox,
        postGloveBox: inputCheckbox.gloveCheckbox,
        postPunchOut: inputCheckbox.ausstanzenCheckbox,
        postCopperStripes: inputCheckbox.kupferstreifenCheckbox,
        anode: {
          id: anodeConstructionData.anode.id,
          name: inputsHeader.name ? inputsHeader.name.trim() : anodeConstructionData.anode.name.trim(),
          description: inputsHeader.vorgehensbeschreibung ?? anodeConstructionData.anode.description,
        },
        location: inputsHeader.syntheseort ?? enums.Location[0],
        lightnings: lightnings,
        layer: layer,
        date: new Date(inputsHeader.date),
        evaluation: null /*{
          id: anodeConstructionData.evaluation.id,
          done: anodeConstructionData.evaluation.done,
          comment: anodeConstructionData.evaluation.comment,
          points: anodeConstructionData.evaluation.points,
        },*/
      };
      ApiAnodeConstruction.Edit(requestData).then(() => {
        setInitialization(false);
        reloadAnodeConstruction(anodeConstructionData.id);
      })
      .catch(e => {
        setOpenLoadingModal(false);
        console.log(e);
      });

    } 
    else {
      const responsibleUsers = [userLoginList[0]];

      let layer = inputsTableOne.map((input, index) => {
          return {
            number: Number(input.schicht),
            material: input.material,
            pendulumStrokes: Number(input.pendelhuebe.replace(',','.')),
            height: input.hoehe,
            lightnings: input.blitzList
              ? input.blitzList.map(x=>Number(x.label))
              : [],
            annotation: input.kommentar,
          };
      });
      let lightnings = inputsTableTwo.map((input, index) => {
          return {
            number: Number(input.blitz),
            numberOfLightnings: Number(input.anzahl),
            energyKV: Number(input.energie.replace(',','.')),
            position: input.position,
            mm_S: Number(input.mms.replace(',','.')),
            impulseLength: Number(input.impulslaenge.replace(',','.')),
            intervalLength: Number(input.intervallaenge.replace(',','.')),
            annotation: input.kommentar,
          };
      });
      const requestData: __AnodeConstructionSheetToSheetCreateConverted = {
        platform: Config.Platform,
        responsibleUser: responsibleUsers,
        state: "aktiv",
        substrat: inputsHeader.substrat,
        postVakuumPacking: inputCheckbox.vakuumCheckbox,
        postGloveBox: inputCheckbox.gloveCheckbox,
        postPunchOut: inputCheckbox.ausstanzenCheckbox,
        postCopperStripes: inputCheckbox.kupferstreifenCheckbox,
        location: inputsHeader.syntheseort,
        anode: {
          name: inputsHeader.name.trim(),
          description: inputsHeader.vorgehensbeschreibung,
        },
        lightnings: lightnings,
        layer: layer,
        evaluation: null,
        workBlockID: location.state.workblockID
      };
      ApiAnodeConstruction.Create(requestData).then((id) => {
        setInitialization(false);
        reloadAnodeConstruction(id);
      })
      .catch(e => {
        setOpenLoadingModal(false);
        console.log(e);
      });

    }
  };


  useEffect(() => {
    document.title = 'Anodenbau';
    if(!userList || !userLoginList){
      loadUserList();
    }
    validateInputFields();
    setOpenLoadingModal(true);
    getPackageName();
    loadAnodes();
    loadFilesData();
  },[]);
  useEffect(()=>{
    loadFilesData();
  },[loadFiles]);
  useEffect(() => {
    validateInputFields();
    setInitialization(true);
    checkLinkedLightnings(inputsTableOne);
    setDeleteRefresh(true);
    setOpenLoadingModal(false);
  }, [inputsTableOne]);
  useEffect(()=>{
    validateInputFields();
  },[inputsHeader]);
  useEffect(() => {
    setDeleteRefresh(false);
    setBlitzList(inputsTableTwo.map(x => x.blitz));
    setBlitzListOptions(inputsTableTwo.map(x => x.blitz));
    validateInputFields();
    checkLinkedLightnings(inputsTableOne);
    setOpenLoadingModal(false);
  }, [inputsTableTwo]);
  useEffect(() => {
    setDeleteRefresh(true);
  },[blitzListOptions])
  useEffect(() => {
    validateInputFields();
    setInitialization(true);
  }, [validatedInputs]);
  useEffect(()=>{
  if(reloadTableOne){
    setReloadTableOne(false);
    }
  },[reloadTableOne]);
  useEffect(()=> {
    if(anodeConstructionData){
      setInputsTableOne(createTableOneData());
      setInputsTableTwo(createTableTwoData());
    }
  },[anodeConstructionData]);


  return (
    <div className='content-main-container'>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}
      {initialized ? 
      <>
        <h1>Syntheseprotokoll Sheet-to-Sheet</h1>
        <form action='' className='margin-top-15'>
          <div className='col-6'>
            <div className='row'>
                <p className='h6 fw-bold'>Arbeitspaket: {packagename}</p>
              </div>
              <div className='row mt-0'>
              <p className='h6 fw-bold'>Arbeitsblock: {blockname}</p>
            </div>
          </div>
          <div className='row mt-3'>
            <div className='col'>
              <label htmlFor='verantwortlicher'>Verantwortlicher</label>
              <div style={{ visibility: 'hidden', height: 0 }}>
                <InputField
                  inputType='text'
                  name='verantwortlicher'
                  placeholder=''
                  value={inputsHeader.verantwortlicher}
                  changeHandler={handleChange}
                  disabled={false}
                  inputClass='text-center    border-bottom-primary cut-text-overflow margin-top-5'
                />
              </div>
              <select
                name='verantwortlicher'
                value={inputsHeader.verantwortlicher}
                onChange={(event) => handleOptionChange(event)}
                className='input-lg  border-bottom-primary'
              >
                {userList.map((option: string) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className='col'>
              <label htmlFor='syntheseort'>Syntheseort</label>
              <div style={{ visibility: 'hidden', height: 0 }}>
                <InputField
                  inputType='text'
                  name='syntheseort'
                  placeholder=''
                  value={inputsHeader.syntheseort}
                  changeHandler={handleChange}
                  disabled={false}
                  inputClass='text-center    border-bottom-primary cut-text-overflow margin-top-5'
                />
              </div>
              <select
                name='syntheseort'
                value={inputsHeader.syntheseort}
                onChange={(event) => handleOptionChange(event)}
                className='input-lg border-bottom-primary'
              >
                {enums.Location.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className='col'>
              <label htmlFor='status'>Status</label>
              <div style={{ visibility: 'hidden', height: 0 }}>
                <InputField
                  inputType='text'
                  name='status'
                  placeholder=''
                  value={inputsHeader.status}
                  changeHandler={handleChange}
                  disabled={false}
                  inputClass='text-center    border-bottom-primary cut-text-overflow margin-top-5'
                />
              </div>
              <select
                name='status'
                value={inputsHeader.status}
                onChange={(event) => handleOptionChange(event)}
                className='input-lg    border-bottom-primary'
              >
                {enums.WorkBlockState.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='row margin-top-5'>
            <div className='col'>
              <label htmlFor='name'>Name</label>
              <div className='input-wrapper'>
                <input
                  className={`input-lg text-left ${nameAlreadyExists || inputsHeader.name =='' ? "border-bottom-error" : "border-bottom-primary"} cut-text-overflow`}
                  type='text'
                  placeholder='Name'
                  name='name'
                  onChange={handleChange}
                  value={inputsHeader.name}
                />
              </div>
                {nameAlreadyExists ? 
                  <p className = 'text-err margin-bottom-10'>Name existiert bereits.</p> : 
                  <></>}
                {inputsHeader.name == '' ?
                  <p className = 'text-err margin-bottom-10'>Name darf nicht leer sein.</p> : 
                  <></>
                }
            </div>
            <div className='col'>
               <label htmlFor=''>Substrat</label>
              <InputField
                inputType='text'
                name='substrat'
                placeholder=''
                value={inputsHeader.substrat}
                changeHandler={handleChange}
                disabled={false}
                inputClass='text-center text-md border-bottom-primary cut-text-overflow'
              />
            </div>
            <div className='col'>
              <label htmlFor=""> Datum</label>
              <InputField
                  inputType='date'
                  name='date'
                  placeholder= {inputsHeader.date}
                  value={inputsHeader.date}
                  changeHandler={handleChange}
                  disabled={false}
                  inputClass='text-center border-bottom-primary cut-text-overflow'
                />
              </div>
          </div>
          <div className='margin-top-5 col-8'>
            <label htmlFor='vorgehensbeschreibung'>Vorgehensbeschreibung</label>
            <textarea
              className='input-lg-textarea border-bottom-primary cut-text-overflow'
              name='vorgehensbeschreibung'
              value={inputsHeader.vorgehensbeschreibung}
              onChange={handleInputChange}
            />
          </div>
        </form>
          <h2 className='margin-top-15'>Schritte</h2>

          <div className="table-responsive margin-top-10">
            <table className='table main-table table-hover align-middle' style={{width:'100%'}}>
          {tHeadersOne}
          <tbody>
          {deleteRefresh && !reloadTableOne? <>
            {inputsTableOne?.map((inputElement, index) => (
              <tr key={index}>
                  <td key='' style={{minWidth:'100px'}}>
                    <button
                      className='btn btn-add text-success pe-0 me-1 ps-0'
                      type='button'
                      title='Schritt oben einfügen'
                      onClick={event => handleAddRowAtSpecifiedIndexTableOne(index)}
                    > 
                      <FontAwesomeIcon
                      icon={faPlus}
                      size='sm'
                      />
                    </button>
                    <button
                      className='btn-delete p-0 me-2 ms-1'
                      type='button'
                      title='Löschen'
                      onClick={() => {
                        let newSelectionList = selectionList.map(x => false);
                        setDeleteRefresh(false);
                        newSelectionList.pop();
                        setSelectionList(newSelectionList);

                        setInputsTableOne((prevTable) => {
                          let newTable = [...prevTable];
                          newTable.splice(index, 1);
                          for(let i in newTable){
                            newTable[i].schicht = String(Number(i)+1);
                          }
                          return newTable;
                        });
                      }}
                      // data-bs-toggle='modal'
                      // data-bs-target={'#anodeS2STableOne_'+String(index)}
                    ><FontAwesomeIcon
                      icon={faXmark}
                      size='sm'
                      />
                    </button>
                    {/* <ConfirmationModal
                      modalID={'anodeS2STableOne_'+String(index)}
                      modalText='Soll der Eintrag wirklich gelöscht werden?'
                      confirmationText='löschen'
                      handleConfirm={() => {
                        let newSelectionList = selectionList.map(x => false);
                        setDeleteRefresh(false);
                        newSelectionList.pop();
                        setSelectionList(newSelectionList);

                        setInputsTableOne((prevTable) => {
                          let newTable = [...prevTable];
                          newTable.splice(index, 1);
                          for(let i in newTable){
                            newTable[i].schicht = String(Number(i)+1);
                          }
                          return newTable;
                        });
                      }}
                    /> */}
                    {deleteRefresh ? 
                    <input
                      id={'marking-selection-anodeS2S'+String(index)}
                      className='btn-selection'
                      name ='selection'
                      type='checkbox'
                      title="Markieren"
                      onChange={() =>handleSelectionRowTableOne(index)}
                    /> : <></>}
                    
                  </td>
                  <td key='schicht'  style={{width:'100px'}}>
                    <InputField
                      inputType='text'
                      name = 'schicht'
                      placeholder=''
                      value={inputElement.schicht}
                      changeHandler={(event) =>handleChangeTableOne(event,index)}
                      inputClass ='text-center  cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='material'  style={{minWidth:'250px'}}>
                    <InputField
                      inputType='text'
                      name = 'material'
                      placeholder=''
                      value={inputElement.material}
                      changeHandler={(event) =>handleChangeTableOne(event,index)}
                      inputClass ='text-center    cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='pendelhuebe'  style={{width:'100px'}}>
                    <InputField
                      inputType="number" 
                      step="0.01"
                      name = 'pendelhuebe'
                      placeholder=''
                      value={inputElement.pendelhuebe}
                      changeHandler={(event) =>handleChangeTableOne(event,index)}
                      inputClass ='text-center    cut-text-overflow margin-top-5'
                    />
                  </td>
                  <td key='hoehe'  style={{width:'100px'}}>
                    <InputField
                      inputType='text'
                      name = 'hoehe'
                      placeholder=''
                      value={inputElement.hoehe}
                      changeHandler={(event) =>handleChangeTableOne(event,index)}
                      inputClass ='text-center    cut-text-overflow margin-top-5'
                    />
                  </td>
                  
                  <td key='lightningSelect'  style={{maxWidth:'100px'}}>
                    <MultiSelectDropdownWithCheckboxes
                      valueList={blitzListOptions} 
                      selectedValues={inputElement.blitzList}
                      formClass="border-dark"
                      inputTextClass='margin-top-5 input-lg'
                      handleChange={(result) => handleSelectionChange(result, index)}
                    />
                  </td>
                  <td key='kommentar'  style={{minWidth:'455px'}}>
                    <InputField
                      inputType='text'
                      name = 'kommentar'
                      placeholder=''
                      value={inputElement.kommentar}
                      changeHandler={(event) =>handleChangeTableOne(event,index)}
                      inputClass ='text-center    cut-text-overflow margin-top-5'
                    />
                  </td>
              </tr>
            ))}</>:<></>}
          </tbody>
            </table>
        </div>
        <input
          className='btn-main margin-top-15'
          type='button'
          value='Schritt hinzufügen'
          onClick={handleAddRowTableOne}
        />
        <input
          className='btn-main margin-top-15 margin-left-10'
          type='button'
          value='Markierte Schritte duplizieren'
          onClick={handleDuplicateSelectedRows}
        />
        {
          anodesList.length > 0
          ? <>
              <button
              className='btn-main margin-top-15 margin-left-10'
              type='button'
              data-bs-toggle='modal'
              data-bs-target={'#LoadAnode'}
              >Anodenbau Laden
            </button>
            <SelectAnodeModalProps
              modalID='LoadAnode'
              listOfAnodes={anodesList}
              handleSelect={handleLoadAnode}
            />
          </>
          : <></>
        }
        <button
          className='btn-main margin-top-15 margin-left-10'
          type='button'
          title='Alle Tabelleneinträge löschen'
          data-bs-toggle='tooltip'
          onClick={() => {
            setInputsTableOne([]);
            setInputsTableTwo([]);
          }}
        >
          Tabellen leeren
        </button>
        
        <h2 className='margin-top-15'>Blitze</h2>
        <div className="table-responsive margin-top-10">
          <table className='table main-table table-hover align-middle' style={{width:'100%'}}>
            {tHeadersTwo}
            <tbody>
              {inputsTableTwo.map((inputElement, index) => (
                <tr key={index}>
                    <td key=''style={{minWidth:'50px'}}>
                        <button
                          className='btn-delete'
                          type='button'
                          disabled = {checkLight.includes(Number(inputElement.blitz))}
                          data-bs-toggle='modal'
                          data-bs-target={'#anodeS2STableTwo_'+String(index)}
                        ><FontAwesomeIcon
                          icon={faXmark}
                          size='sm'
                        /></button>
                        <ConfirmationModal
                          modalID={'anodeS2STableTwo_'+String(index)}
                          modalText='Soll der Eintrag wirklich gelöscht werden?'
                          confirmationText='löschen'
                          handleConfirm={() => 
                            setInputsTableTwo((prevTable) => {
                              const newTable = [...prevTable];
                              newTable.splice(index, 1);
                              return newTable;
                            })
                          }
                        />
                    </td>
                    
                    <td key='blitz'style={{minWidth:'100px'}}>
                      <InputField
                        inputType='number'
                        name = 'blitz'
                        min='1'
                        placeholder='1'
                        value={inputElement.blitz}
                        changeHandler={(event) =>handleChangeTableTwo(event,index)}
                        inputClass ='text-center    cut-text-overflow margin-top-5'
                      />
                    </td>
                    <td key='anzahl'style={{minWidth:'100px'}}>
                      <input
                        type='number'
                        name = 'anzahl'
                        placeholder=''
                        min = '0'
                        value={inputElement.anzahl}
                        className='input-lg    border-bottom-primary margin-top-5'
                        onChange={(event) =>handleChangeTableTwo(event,index)}
                      />
                    </td>
                    <td key='energie'style={{minWidth:'100px'}}>
                      <InputField
                        inputType='text'
                        name = 'energie'
                        placeholder=''
                        value={inputElement.energie}
                        changeHandler={(event) =>handleChangeTableTwo(event,index)}
                        inputClass ='text-center    cut-text-overflow margin-top-5'
                      />
                    </td>
                    <td key='position'style={{minWidth:'100px'}}>
                      <InputField
                        inputType='text'
                        name = 'position'
                        placeholder=''
                        value={inputElement.position}
                        changeHandler={(event) =>handleChangeTableTwo(event,index)}
                        inputClass ='text-center    cut-text-overflow margin-top-5'
                      />
                    </td>
                    <td key='mms'style={{minWidth:'100px'}}>
                      <InputField
                        inputType='text'
                        name = 'mms'
                        placeholder=''
                        value={inputElement.mms}
                        changeHandler={(event) =>handleChangeTableTwo(event,index)}
                        inputClass ='text-center    cut-text-overflow margin-top-5'
                      />
                    </td>
                    <td key='impulslaenge'style={{minWidth:'100px'}}>
                      <InputField
                        inputType='text'
                        name = 'impulslaenge'
                        placeholder=''
                        value={inputElement.impulslaenge}
                        changeHandler={(event) =>handleChangeTableTwo(event,index)}
                        inputClass ='text-center    cut-text-overflow margin-top-5'
                      />
                    </td>
                    <td key='intervallaenge'style={{minWidth:'100px'}}>
                      <InputField
                        inputType='text'
                        name = 'intervallaenge'
                        placeholder=''
                        value={inputElement.intervallaenge}
                        changeHandler={(event) =>handleChangeTableTwo(event,index)}
                        inputClass ='text-center    cut-text-overflow margin-top-5'
                      />
                    </td>
                    <td key='kommentar' style={{minWidth:'400px'}}>
                      <InputField
                        inputType='text'
                        name = 'kommentar'
                        placeholder=''
                        value={inputElement.kommentar}
                        changeHandler={(event) =>handleChangeTableTwo(event,index)}
                        inputClass ='text-center    cut-text-overflow margin-top-5'
                      />
                    </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <input
          className='btn-main margin-top-15'
          type='button'
          value='Blitz hinzufügen'
          onClick={handleAddRowTableTwo}
        />
        <p className='margin-top-10'>Handhabung nach dem Sputtern</p>
        <div className='margin-top-auto margin-bottom-auto margin-left-15 margin-top-10'>
          <input
            type='checkbox'
            name='vakuumCheckbox'
            id='vakuumCheckbox'
            checked={inputCheckbox.vakuumCheckbox}
            onChange={handleInputCheckboxChange}
          />
          <label htmlFor='vakuumCheckbox' className='margin-left-5'>
            Vakuumverpacken
          </label>
        </div>
        <div className='margin-top-auto margin-bottom-auto margin-left-15 margin-top-5'>
          <input
            type='checkbox'
            name='gloveCheckbox'
            id='gloveCheckbox'
            checked={inputCheckbox.gloveCheckbox}
            onChange={handleInputCheckboxChange}
          />
          <label htmlFor='gloveCheckbox' className='margin-left-5'>
            Glovebox
          </label>
        </div>
        <div className='margin-top-auto margin-bottom-auto margin-left-15 margin-top-5'>
          <input
            type='checkbox'
            name='ausstanzenCheckbox'
            id='ausstanzenCheckbox'
            checked={inputCheckbox.ausstanzenCheckbox}
            onChange={handleInputCheckboxChange}
          />
          <label htmlFor='ausstanzenCheckbox' className='margin-left-5'>
            Ausstanzen
          </label>
        </div>
        <div className='margin-top-auto margin-bottom-auto margin-left-15 margin-top-5'>
          <input
            type='checkbox'
            name='kupferstreifenCheckbox'
            id='kupferstreifenCheckbox'
            checked={inputCheckbox.kupferstreifenCheckbox}
            onChange={handleInputCheckboxChange}
          />
          <label htmlFor='kupferstreifenCheckbox' className='margin-left-5'>
            Kupferstreifen
          </label>
        </div>

        <input
          className='btn-main margin-top-15 margin-right-15'
          type='button'
          value='Zurück'
          onClick={handleBack}
        />
        <input
          className='btn-main margin-top-15 margin-right-15'
          type='button'
          value='Speichern'
          onClick={handleSave}
          disabled={!validatedInputs}
        />
        {anodeConstructionData ?
        <>
          <input
            className='btn-main margin-top-15 margin-right-15'
            type='button'
            value='Dokumente generieren'
            onClick={handleGeneratePdf}
          />
          <button
            className='btn-main margin-top-15'
            type='button'
            onClick={handleCreateMaterial}
            data-bs-toggle='modal'
            data-bs-target='#InfoModalAnodenbauCreate'
          >Material erstellen </button>
          <InfoModal
              modalID='InfoModalAnodenbauCreate'
              infoText= {infotext}
              confirmationText='ok'
          />
        </>
           
          :(<></>)
        }
          
        {loadFiles && anodeConstructionData ?
          <div className='margin-top-20 paket-information-container row'>
            <div className='column'>
              <FileUploadAccordeon
                handleConfirm={submitModalCloseAccordion}
                handleReloadPage={handleReloadPage}
                files={files}
              />
            </div>
          </div>
          : <></>
        }
        <InfoModal
          modalID='ErrorDownloadFailed'
          infoText='Download fehlgeschlagen'
          confirmationText='Ok'
        />
      </>
      :<></>}
    </div>
  );
};

interface selectAnodeModalProps{
  listOfAnodes: IBackendAnodes[];
  modalID: string;
  handleSelect: (anode:IBackendAnodes | null) => void;
};

function SelectAnodeModalProps ({
  listOfAnodes,
  modalID,
  handleSelect
}: selectAnodeModalProps) {
  const [inputs, setInputs] = useState(listOfAnodes[0].name);
  const [selectedAnode, setSelectedAnode] = useState<IBackendAnodes | null>(null);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    setInputs(value);
    let anode = listOfAnodes.find(x => x.name === value);
    if(anode){
      setSelectedAnode(anode);
    }
    else{
      setSelectedAnode(null);
    }
  };

  useEffect(() => {
    if(listOfAnodes.length> 0){
      setSelectedAnode(listOfAnodes[0]);
    }
  },[])

  return(
    <div className='modal fade mt-4' id={modalID} tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-middle">
        <div className="modal-content modal-background-lightgrey small-modal">
          <div className="modal-header pb-0">
            <p className='h3'>Anode auswählen</p>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className='modal-body pt-0'>
            <label htmlFor="anodeNames" className="form-label ms-1 mb-0">Namen</label>
            <input 
              className="form-control input-lg text-md border-bottom-primary margin-top-0" 
              list="selectAnode" 
              id="anode" 
              placeholder={'Anode wählen...'}
              value={inputs}
              name='anode'
              onChange={(event) => handleChange(event)}
            />
            <datalist id="selectAnode">
              {listOfAnodes.map((option: IBackendAnodes) => (
                <option key={option.name} value={option.name}>
                  {option.name}
                </option>
              ))}
            </datalist>
          </div>
          <div className='modal-footer pt-0'>
            <button 
              type="button" 
              className="btn-main margin-top-15" 
              data-bs-dismiss="modal"> Abbrechen
            </button>
            <button
              className='btn-main margin-top-15'
              type='button'
              disabled={selectedAnode === null}
              onClick={event => handleSelect(selectedAnode)} 
              data-bs-dismiss="modal"
              > Auswählen
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};
