import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

//Import Data
import ModularSearchHeader from '../../components/ModularSearchHeader/ModularSearchHeader';
import { Table } from '../../components/Table';
import {
  ApiCharacteristics,
  CharacteristicsConverter,
} from '../../backend/ApiCharacteristics';
import { ApiWorkBlock } from '../../backend/ApiWorkBlock';
import { ApiAuth } from '../../backend/ApiAuth';
import Loading from '../../components/Ladebalken';

// TODO: Daten anders verwalten

export default function Charakterisierung() {
  const navigate = useNavigate();
  const [initializedTable, setInitializedTable] = useState(false);
  const tableHeaders = [
    'Arbeitsblock',
    'Typ',
    'Verantwortlicher',
    'Datum',
    'Status',
    'Bearbeiten',
  ];
  const [displayedTable, setDisplayedTable] = useState<string[][]>([]);
  const [userDisplayNamesList, setUserDisplayNamesList] = useState<string[]>(
    []
  );
  const [userLoginNamesList, setUserLoginNamesList] = useState<string[]>([]);
  const [userIDList, setUserIDsList] = useState<string[]>([]);
  useEffect(() => {
    document.title = 'Charakterisierung';
    if (!initializedTable) {
      setOpenLoadingModal(true);
      ApiCharacteristics.ListAll().then((data) => {
        setDisplayedTable(
          CharacteristicsConverter.ConvertFromApiToTableList(data)
        );
        setInitializedTable(true);
      });
      ApiAuth.ListAll()
      .then((data)=>{
        let users = data.filter( x=> !x.deleted && x.state.name !== 'gesperrt' && x.state.name !== 'gelöscht');
        setUserDisplayNamesList(users.map(x => x.displayName));
        setUserLoginNamesList(users.map(x => x.loginName));
        setUserIDsList(users.map(x => x.id));
      })
      .catch((e)=>{
        console.log(e);
      });
      setOpenLoadingModal(false);
    }
  });
  const [inputs, setInputs] = useState({});

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const getInitialTable = () => {
    return displayedTable;
  };
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const handleEdit = async (index: number) => {
    setOpenLoadingModal(true);
    let characteristicID:string = displayedTable[index][0] ?? '';
    let dataCharacteristic : any = null;
    let blockID:string = '';
    let blockname: string = '';
    let packageId: string = '';
    let packagename: string = '';
    // get types of characteristics
    await ApiCharacteristics.Get(characteristicID).then((data)=>{
      blockID = data.workblock.id;
    });
    //get characteristic ID
    await ApiWorkBlock.Get(blockID).then((data)=>{
      dataCharacteristic = data.characteristics;
      blockname = data.name;
      packageId = data.workpackage.id;
      packagename = data.workpackage.name;
    })
    navigate('/charakterisierung/ablauf', {
      state: {
        workblockID: blockID,
        workblockName: blockname,
        workpackageID: packageId,
        workpackageName: packagename,
        characteristicsList: ['','',displayedTable[index][2]],
        characteristicID: characteristicID,
        characteristic: dataCharacteristic,
        userList: userDisplayNamesList,
        userLoginList: userLoginNamesList,
        userIDList: userIDList
      },      
    });
  };


  /*
  const arbeitsbloeckeFunktionen = {
    func: [handleEdit, handleDuplicate],
    val: ['Bearbeiten', 'Duplizieren'],
  };
  */
  const arbeitsbloeckeFunktionen = {
    func: [handleEdit],
    val: ['Bearbeiten'],
    defaultState: ["aktiv"],
    modal: [false]
  };

  return (
    <div className='content-main-container'>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}
      {initializedTable ? (
        <ModularSearchHeader
          title='Charakterisierung'
          handleChange={handleChange}
          setDisplayedTable={setDisplayedTable}
          displayTable={displayedTable}
          dropdownOptions={tableHeaders.slice(0, -1)}
          getInitialTable={getInitialTable}
        />
      ) : (
        <></>
      )}
      <Table
        modalIDList={[]}
        headers={tableHeaders}
        valuesList={displayedTable}
        stateList={null}
        functionsList={arbeitsbloeckeFunktionen}
      />
    </div>
  );
}
