import Config from '../util/Config';
import {
  __BatteryTestProcessingConverted,
  __BatteryTestWishConverted,
  __EvaluationConverted,
  __Platform,
  __WorkBlockState,
  CreateBasicResponse,
} from './types/__GeneralStructures';
import { ApiClient } from './ApiClient';
import BasicResponse from './BasicResponse';
import * as requestTypes from './types/__RequestStructures';
import * as responseTypes from './types/__ResponseStructures';
import { ApiPrepareRequest } from './ApiPrepareRequest';

export class ApiBatteryTest {
  static basePath: string = '/batterytest';
  static baseUrl: string = Config.BackendUrl + ApiBatteryTest.basePath;

  static Get(id: string = ''): Promise<responseTypes.IBackendBatteryTestGet> {
    let path = '/get';

    // prepare query
    let query = ApiPrepareRequest.PrepareGetQuery(id);
    let url = ApiBatteryTest.baseUrl + path + query;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.get(url);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject =
        (await response.json()) as BasicResponse<responseTypes.IBackendBatteryTestGet>;

      resolve(responseObject.data);
    });
  }
  // TODO: add filter and search
  /**
   * /auth/list
   */
  static ListAll(): Promise<responseTypes.IBackendBatteryTestList[]> {
    let path = '/list';
    let url = ApiBatteryTest.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.get(url);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<
        responseTypes.IBackendBatteryTestList[]
      >;

      resolve(responseObject.data);
    });
  }
  static Create(
    requestData: requestTypes.__BatteryTestCreate
  ): Promise<string> {
    let path = '/create';
    let url = ApiBatteryTest.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let convertedRequestData = {
        platform: requestData.platform,
        workBlock: requestData.workBlock,
        state: requestData.state,
        evaluation: JSON.parse(
          JSON.stringify(requestData.evaluation)
        ) as __EvaluationConverted,
        responsibleUser: requestData.responsibleUser,
        comment: requestData.comment,
        batteryTestWishes: requestData.batteryTestWishes,
        batteryTestProcess: JSON.parse(
          JSON.stringify(requestData.batteryTestProcess)
        ) as __BatteryTestProcessingConverted,
      };
      let response = await ApiClient.post(url, convertedRequestData);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject =
        (await response.json()) as CreateBasicResponse<string>;

      resolve(responseObject.id);
    });
  }
  static Edit(requestData: requestTypes.__BatteryTestEdit): Promise<boolean> {
    let path = '/edit';
    let url = ApiBatteryTest.baseUrl + path;
    return new Promise(async (resolve, reject) => {
      let convertedRequestData = {
        id: requestData.id,
        state: requestData.state,
        evaluation: requestData.evaluation,
        responsibleUser: requestData.responsibleUser,
        comment: requestData.comment,
        date: requestData.date,
        batteryTestProcess: JSON.parse(
          JSON.stringify(requestData.batteryTestProcess)
        ) as __BatteryTestProcessingConverted,
        processIDListToDisconnect: requestData.processIDListToDisconnect
      };
      let response = await ApiClient.put(url, convertedRequestData);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.success);
    });
  }
  static EditWish(requestData: requestTypes.__BatteryTestEditWish): Promise<boolean> {
    let path = '/editwish';
    let url = ApiBatteryTest.baseUrl + path;
    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.put(url, requestData);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.success);
    });
  }
  static Delete(requestData: requestTypes.__IDRequest): Promise<boolean> {
    let path = '/delete';
    let url = ApiBatteryTest.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.post(url, requestData);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.success);
    });
  }
  static Lock(requestData: requestTypes.__IDRequest): Promise<boolean> {
    let path = '/lock';
    let url = ApiBatteryTest.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.post(url, requestData);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.success);
    });
  }
  static Activate(requestData: requestTypes.__IDRequest): Promise<boolean> {
    let path = '/activate';
    let url = ApiBatteryTest.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.post(url, requestData);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.success);
    });
  }
  static Close(requestData: requestTypes.__IDRequest): Promise<boolean> {
    let path = '/close';
    let url = ApiBatteryTest.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.post(url, requestData);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.success);
    });
  }

  //generatepdf
  static GeneratePdf(id: string): Promise<string> {
    let path = '/generatepdf';
    let query = '?id=' + id;
    let url = ApiBatteryTest.baseUrl + path + query;

    return new Promise(async (resolve, reject) => {
    let response = await ApiClient.get(url);
    if (!response.ok) {
        if (response.status === 401) {
        reject('Unauthorized!');
        } else {
        reject(`Error! status: ${response.status}`);
        }
    }

    let responseObject = (await response.json()) as BasicResponse<string>;

    resolve(responseObject.data);
    });
  };
  //generatepdfwish
  static GeneratePdfWish(id: string): Promise<string> {
    let path = '/generatepdfwish';
    let query = '?id=' + id;
    let url = ApiBatteryTest.baseUrl + path + query;

    return new Promise(async (resolve, reject) => {
    let response = await ApiClient.get(url);
    if (!response.ok) {
        if (response.status === 401) {
        reject('Unauthorized!');
        } else {
        reject(`Error! status: ${response.status}`);
        }
    }

    let responseObject = (await response.json()) as BasicResponse<string>;

    resolve(responseObject.data);
    });
  };
}

export class BatteryTestConverter {
  // Temporärer Hinweis: Gedacht für die Tabellen Planung und Arbeitspakete
  static ConvertFromApiToTableList(
    data: responseTypes.IBackendBatteryTestList[]
  ) {
    // [1, 'Anode 1', 'Tony', '15.03.2023', 'abgeschlossen', 'Halle'],
    let simpleList: string[][] = [];
    data.forEach((item) => {
      let typeOfBattery:string = item.batteryTestProcessing.length > 0 
      ? item.batteryTestProcessing[0].battery?.batteryData.typeID 
        ?? item.batteryTestWishes[0].type.name 
          ?? ''
      : item.batteryTestWishes.length > 0 
        ? item.batteryTestWishes[0].type.name 
          ?? ''
        : ''
      simpleList.push([
        item.id,
        item.workblock.name ?? '',
        typeOfBattery === 'Halbzelle' ? 'HZ' : 'VZ',
        //'',//item.name,
        item.responsibleUser[0].displayName ?? '',
        new Date(item.date).toLocaleDateString(),
        item.state.name ?? '',
        item.batteryTestProcessing.length > 0 
        ? item.batteryTestProcessing[0].battery.locOfMeasuring.name 
        : 'Halle'
      ]);
    });

    return simpleList;
  }
}
