import {
  ChangeEvent,
  FormEvent,
  FormEventHandler,
  useEffect,
  useState,
} from 'react';
import InputField from '../../UI/InputField';
import { Button } from '../../UI/Button';
import { useNavigate } from 'react-router-dom';
import { ApiAuth } from '../../backend/ApiAuth';
import Loading from '../../components/Ladebalken';
export default function Login({ handleLogin } : any) {
  const navigate = useNavigate();
  const [inputs, setInputs] = useState({ username: '', password: '' });
  const [wrongCredentials, setWrongCredentals] = useState(false);
  const [errorText, setErrorText] = useState('Ungültiger Nutzer oder falsches Passwort.');
  useEffect(() => {
    document.title = 'Login';

    ApiAuth.IsAuthenticated()
    .then(data => {
      navigate("/dashboard");
    })
    .catch(_ => { });
  }, []);


  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    setOpenLoadingModal(true);
    let response = ApiAuth.Login(inputs.username, inputs.password)
      .then(function (success) {
        if (success) {
          ApiAuth.GetMyself().then((user) => {handleLogin(user);});
          setWrongCredentals(false);
          navigate('/dashboard');
        } else {
          setWrongCredentals(true);
        }
      })
      .catch(function (error) {
        setWrongCredentals(true);
        setOpenLoadingModal(false);
      });
  };
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  return (
    <div className='login-container margin-top-100'>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}
      <div className='login-body'>
        <form className='login-form' onSubmit={handleSubmit}>
          <h1 className='text-center margin-top-5'>Login</h1>
          <div>
            <InputField
              inputType='text'
              name='username'
              placeholder='Username'
              changeHandler={handleChange}
              inputClass='text-center text-md margin-top-15 border-bottom-primary'
            />
          </div>
          <div>
            <InputField
              inputType='password'
              name='password'
              placeholder='Password'
              changeHandler={handleChange}
              inputClass='text-center text-md margin-top-5 border-bottom-primary'
            />
          </div>
          <div className='margin-top-20 margin-bottom-20'>
            <div className='login-submit-container'>
              <Button
                className='btn login-submit'
                type='submit'
                value='LOGIN'
              />
            </div>
          </div>
          {wrongCredentials?
            <p className='text-err-color-font text-center'>{errorText}</p>
            : <></>
          }
        </form>
      </div>
    </div>
  );
}
