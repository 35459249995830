import { ChangeEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
//Import Components
import ArbeitsblockDetail from './ArbeitsblockDetail';
import ArbeitsblockNeu from './ArbeitsblockNeu';

//Import Data
import ModularSearchHeader from '../../components/ModularSearchHeader/ModularSearchHeader';
import { Table } from '../../components/Table';
import { ApiWorkBlock, WorkblockConverter } from '../../backend/ApiWorkBlock';

// TODO: Daten anders verwalten

export default function ArbeitsbloeckeUebersicht() {
  const navigate = useNavigate();
  const [initializedTable, setInitializedTable] = useState(false);

  const [displayedTable, setDisplayedTable] = useState<string[][]>([]);
  const tableHeaders = [
    'Name',
    'Verantwortlicher',
    'Datum',
    'Status',
    'Ort',
    'Bearbeiten',
  ];
  useEffect(() => {
    document.title = 'Arbeitsblöcke Übersicht';
    if (!initializedTable) {
      ApiWorkBlock.ListAll().then((data) => {
        setDisplayedTable(WorkblockConverter.ConvertFromApiToTableList(data));
        setInitializedTable(true);
      });
    }
  });
  const [inputs, setInputs] = useState({});

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(() => ({ [name]: value }));
  };
  const getInitialTable = () => {
    return displayedTable;
  };
  const handleEdit = (index: number) => {
    navigate(`/arbeitsbloecke/details`, {
      state: {
        id: displayedTable[index][0],
      },
    });
  };
  const handleDuplicate = (index: number) => {
    navigate(`/arbeitsbloecke/neu`);
  };
  /*
  const arbeitsbloeckeFunktionen = {
    func: [handleEdit, handleDuplicate],
    val: ['Bearbeiten', 'Duplizieren'],
  };
  */
 
  const arbeitsbloeckeFunktionen = {
    func: [handleEdit],
    val: ['Bearbeiten'],
    defaultState: ["aktiv"],
    modal:[false]
  };

  return (
    <div className='content-main-container'>
      {initializedTable ? (
        <ModularSearchHeader
          title='Arbeitsblöcke'
          handleChange={handleChange}
          setDisplayedTable={setDisplayedTable}
          displayTable={displayedTable}
          getInitialTable={getInitialTable}
          dropdownOptions={tableHeaders.slice(0, -1)}
        />
      ) : (
        <></>
      )}
      <Table
        modalIDList={[]}
        headers={tableHeaders}
        valuesList={displayedTable}
        stateList={null}
        functionsList={arbeitsbloeckeFunktionen}
      />
    </div>
  );
}
