import { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import InputField from '../../UI/InputField';
//import { enums } from '../../data';
import { ApiBackendData } from '../../backend/ApiBackendData';
import { ApiMaterial } from '../../backend/ApiMaterial';
import Config from '../../util/Config';
import * as requestTypes from '../../backend/types/__RequestStructures';
import Loading from '../../components/Ladebalken';

const defaultMaterial = {
  id: '',
  name: 'Materialname',
  type: 'Anode',
  total: '0',
  available: '0',
  orderNumber: '',
  state: 'gebraucht',
  origin: 'gekauft',
  annotation: '...',
  deleted: 'false',
  //editionaly params on surface
  aufbewahrungsort: '',
  hersteller: '',
  zulieferer: '',
  bestellinformationen: '',
};
let recentMaterial = defaultMaterial;
let materialId: any = null;

export default function EditMaterials() {
  // call Apis for basic data
  const location = useLocation();
  const [materialLoaded, setMaterialLoaded] = useState(false);
  //ApiAuth.GetUserNamesList().then((data) => { userNamesList = data; });

  useEffect(() => {
    document.title = location.state.title;

    if (!materialLoaded && location.state !== null) {
      if (location.state.currentMaterialIndex !== null) {
        materialId = location.state.currentMaterialIndex;
        recentMaterial.id = materialId;
        setMaterial(materialId);
      } else {
        recentMaterial = defaultMaterial;
        setMaterialLoaded(true);
      }
    }
  });
  const setMaterial = async (id: string) => {
    let material = await ApiMaterial.Get(id);
    if (material !== null) {
      recentMaterial.annotation = material.annotation;
      recentMaterial.available = material.available.toString();
      recentMaterial.name = material.name;
      recentMaterial.orderNumber = material.orderNumber;
      recentMaterial.origin = material.origin.name;
      recentMaterial.state = material.state.name;
      recentMaterial.total = material.total.toString();
      recentMaterial.type = material.type.name;
      recentMaterial.deleted = material.deleted.toString();
      recentMaterial.aufbewahrungsort = material.placeOfStorage;
      recentMaterial.bestellinformationen = material.orderInformation;
      recentMaterial.hersteller = material.producer;
      recentMaterial.zulieferer = material.delieverer;
      setMaterialLoaded(true);
    }
  };

  return (
    <div className='content-main-container'>
      <h1>{location.state.title}</h1>
      {!materialLoaded ? <></> : <MaterialEditForm />}
    </div>
  );
}
const MaterialEditForm = () => {
  // call Apis for basic data
  let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
  //ApiAuth.GetUserNamesList().then((data) => { userNamesList = data; });

  const navigate = useNavigate();
  const [inputs, setInputs] = useState({
    ...recentMaterial,
  });
  const [emptyName,setEmptyname] = useState(false);

  useEffect(()=>{
    if(inputs.name === ''){
      setEmptyname(true);
    }
    else{
      setEmptyname(false);
    }
  },[inputs.name]);
  const setDataObject = () => {
    let dataObject: requestTypes.__MaterialUnconverted = {
      id: inputs.id,
      platform: Config.Platform,
      name: inputs.name,
      materialType: inputs.type,
      total: inputs.total.replace(',','.'),
      available: inputs.available.replace(',','.'),
      orderNumber: inputs.orderNumber,
      state: inputs.state,
      origin: inputs.origin,
      annotation: inputs.annotation,
      deleted: inputs.deleted,
      delieverer: inputs.zulieferer,
      placeOfStorage: inputs.aufbewahrungsort,
      producer: inputs.hersteller,
      orderInfo: inputs.bestellinformationen
    };
    return dataObject;
  };
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    setOpenLoadingModal(true);
    event.preventDefault();
    let data = setDataObject();
    if (materialId !== null) {
      let requestData = JSON.parse(
        JSON.stringify(data)
      ) as requestTypes.__MaterialUnconverted;
      //send data to edit

      await ApiMaterial.Edit(requestData);
    } else {
      let requestData = JSON.parse(
        JSON.stringify(data)
      ) as requestTypes.__MaterialUnconverted;
      // create and directly forward to details send data to api
      await ApiMaterial.Create(requestData);
    }
    let userList = JSON.parse(sessionStorage.getItem('userList') as string);
    let userLoginList = JSON.parse(
      sessionStorage.getItem('userLoginList') as string
    );

    navigate('/material', {
      state: {
        currentMaterialIndex: null,
        userList: userList,
        userLoginList: userLoginList,
      },
    });
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setInputs({ ...inputs, [name]: value });
  };

  return (
    <form onSubmit={handleSubmit}>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}
      <div className='row'>
        <div className='col-4'>
          <label htmlFor=''>Name</label>
          <InputField
            inputType='text'
            name='name'
            placeholder={recentMaterial.name}
            value={inputs.name}
            changeHandler={handleChange}
            disabled={false}
            inputClass={`text-center text-md ${emptyName ? "border-bottom-error":"border-bottom-primary"} cut-text-overflow margin-top-5`}
          />
          {emptyName ?
                  <p className = 'text-err margin-bottom-10'>Name darf nicht leer sein.</p> : 
                  <></>
          }
        </div>
        <div className='col-4'>
          <label htmlFor=''>Typ</label>
          <div style={{ visibility: 'hidden', height: 0 }}>
            <InputField
              inputType='text'
              name='aufbewahrungsort'
              placeholder={recentMaterial.aufbewahrungsort}
              value={inputs.aufbewahrungsort}
              changeHandler={handleChange}
              disabled={false}
              inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
            />
          </div>
          <select
            name='type'
            value={inputs.type}
            onChange={(event) => handleOptionChange(event)}
            className='input-lg text-center text-md border-bottom-primary  margin-top-5'
          >
            {enums.MaterialType.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className='col-4'>
          <label htmlFor=''>Zustand</label>
          <div style={{ visibility: 'hidden', height: 0 }}>
            <InputField
              inputType='text'
              name='state'
              placeholder={recentMaterial.state}
              value={inputs.state}
              changeHandler={handleChange}
              disabled={false}
              inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
            />
          </div>
          <select
            name='state'
            value={inputs.state}
            onChange={(event) => handleOptionChange(event)}
            className='input-lg text-center text-md border-bottom-primary  margin-top-5'
          >
            {enums.MaterialState.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='row margin-top-5'>
        <div className='col-4'>
          <label htmlFor=''>Gesamtmenge</label>
          <InputField
            inputType='number'
            name='total'
            min='0'
            placeholder={recentMaterial.total}
            value={inputs.total}
            changeHandler={handleChange}
            disabled={false}
            inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
          />
        </div>
        <div className='col-4'>
          <label htmlFor=''>Vorhanden</label>
          <InputField
            inputType='number'
            name='available'
            min='0'
            placeholder={recentMaterial.available}
            value={inputs.available}
            changeHandler={handleChange}
            disabled={false}
            inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
          />
        </div>
        <div className='col-4'>
          <label htmlFor=''>Aufbewahrungsort</label>
          <InputField
            inputType='text'
            name='aufbewahrungsort'
            placeholder={recentMaterial.aufbewahrungsort}
            value={inputs.aufbewahrungsort}
            changeHandler={handleChange}
            disabled={false}
            inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
          />
        </div>
      </div>

      <div className='row margin-top-5'>
        <div className='col-4'>
          <label htmlFor=''>Herkunft</label>
          <div style={{ visibility: 'hidden', height: 0 }}>
            <InputField
              inputType='text'
              name='origin'
              placeholder={recentMaterial.origin}
              value={inputs.origin}
              changeHandler={handleChange}
              disabled={false}
              inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
            />
          </div>
          <select
            name='origin'
            value={inputs.origin}
            onChange={(event) => handleOptionChange(event)}
            className='input-lg text-center text-md border-bottom-primary  margin-top-5'
          >
            {enums.MaterialOrigin.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className='col-4'>
          <label htmlFor=''>Hersteller</label>
          <InputField
            inputType='text'
            name='hersteller'
            placeholder={recentMaterial.hersteller}
            value={inputs.hersteller}
            changeHandler={handleChange}
            disabled={false}
            inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
          />
        </div>
        <div className='col-4'>
          <label htmlFor=''>Zulieferer</label>
          <InputField
            inputType='text'
            name='zulieferer'
            placeholder={recentMaterial.zulieferer}
            value={inputs.zulieferer}
            changeHandler={handleChange}
            disabled={false}
            inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
          />
        </div>
      </div>
      <div className='row margin-top-5'>
        <div className='col-4'>
          <label htmlFor=''>Bestellnummer</label>
          <InputField
            inputType='text'
            name='orderNumber'
            placeholder={recentMaterial.orderNumber}
            value={inputs.orderNumber}
            changeHandler={handleChange}
            disabled={false}
            inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
          />
        </div>
        <div className='col-4'>
          <label htmlFor=''>Bestellinformationen</label>
          <InputField
            inputType='text'
            name='bestellinformationen'
            placeholder={recentMaterial.bestellinformationen}
            value={inputs.bestellinformationen}
            changeHandler={handleChange}
            disabled={false}
            inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
          />
        </div>
      </div>
      <div className='row margin-top-5'>
        <div className='col-8'>
          <label htmlFor='beschreibung'>Beschreibung</label>
          <textarea
            className='input-lg-textarea text border-bottom-primary cut-text-overflow margin-top-5'
            name='annotation'
            id='annotation'
            placeholder={recentMaterial.annotation}
            value={inputs.annotation}
            onChange={handleInputChange}
          ></textarea>
        </div>
      </div>
      <div className='mt-5'>
        <input
          type='button'
          value='Zurück'
          onClick={()=>navigate(-1)}
          className='btn-main text-center text-md padding-5 margin-top-10 margin-right-15'
        />
        <input
          type='submit'
          value='Speichern'
          disabled={inputs.name === ''}
          className='btn-main text-center text-md padding-5 margin-top-10'
        />
      </div>
    </form>
  );
};

export { MaterialEditForm as MaterialCreationForm };
