import configJson from '../config/config.json';

export default class Config {
  static BackendUrl: string = '';
  static Platform: string = '';

  static SetConfiguration(config: any) {
    console.log('configure');
    if (config.BackendUrl) {
      Config.BackendUrl = config.BackendUrl;
    }
    if (config.Platform) {
      Config.Platform = config.Platform;
    }
  }

  static LoadConfiguration() {
    Config.SetConfiguration(configJson);
  }
}

Config.LoadConfiguration();
