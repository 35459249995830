import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import InputField from '../../UI/InputField';
import { TypeofCharacteristic, __RamanDataConverted, __RamanResponse, __FileResponse } from '../../backend/types/__GeneralStructures';
import { ApiCharacteristics } from '../../backend/ApiCharacteristics';
import { __RamanCreate, __RamanEdit } from '../../backend/types/__RequestStructures';
import { ApiBackendData } from '../../backend/ApiBackendData';
import Config from '../../util/Config';
import { ConfirmationModal, InfoModal } from '../../components/Modal';
import Loading from '../../components/Ladebalken';
import FileUploadAccordeon from '../../components/FileUpload';
import { ApiFile } from '../../backend/ApiFiles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { ApiAuth } from '../../backend/ApiAuth';

type InputElementTable = {
    id: string,
    number: string;
    probeName: string;
    person: string;
    date: string;
    postMortem: string;
    comment: string;
    [key: string]: string; // add an index signature
};
interface CreateTableEntryProps {
  inputElement: any;
  index: number;
  keyName: string;
  subIndex: number;
  handleChangeTable: (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
};
const createChangeHandler =
  <T extends { [key: string]: string }>(
    table: T[],
    setInputs: React.Dispatch<React.SetStateAction<T[]>>
  ) =>
  (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const name = event.target.name;
    const value = event.target.value;
    const list = [...table];
    list[index] = { ...list[index], [name]: value };
    setInputs(list);
  };

export function CreateTableEntry({
  inputElement,
  index,
  keyName,
  handleChangeTable,
}: CreateTableEntryProps) {
  return (
    <InputField
      inputType='text'
      name={keyName}
      placeholder={''}
      value={inputElement}
      changeHandler={(event) => handleChangeTable(event, index)}
      disabled={false}
      inputClass='text-center   cut-text-overflow margin-top-5'
    />
  );
}
/**
   * Charakterisierung
   * Umbenennungen:
      Hafttest -> Sonstiges
      Post Mortem -> PM Foto
      Raman -> PM REM
      Mikroskopie -> LiMi
  */
export default function Raman() {
  const navigate = useNavigate();
  const location = useLocation();
  let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
  const [initialized, setInitialization] = useState(false);
  const [deletedData, setDeletedData] = useState<string[]>([]);
  const characteristicsID:string = location.state.characteristicsID;

  const [userList, setUserList] = useState<string[]>(location.state.userList);
  const [userLoginList, setUserLoginList] = useState<string[]>(location.state.userLoginList);
  const [userIDList, setUserIDsList] = useState<string[]>(location.state.userIDList);

  const [characteristic, setCharacteristicData] = useState<__RamanResponse>({
    id: '',
    platformID: '',
    date: '',
    stateID: '',
    data:[],
    responsibleUser: [],
    comment: '',
    characteristicID: '',
    files: []
  });
  const [files, setFiles] = useState<__FileResponse[]>([]);
  const [loadFiles, setLoadFiles] = useState(false);
  const [openLoadingModal, setOpenLoadingModal] = useState(false);

  let currentFullDate = new Date();
  let month: number|string = currentFullDate.getMonth()+1;
  let day: number|string = currentFullDate.getDay() +1;
  if(day< 10){
    day = '0'+day.toString();
  }
  else{
    day = day.toString();
  }
  if (month < 10){
    month = '0' + month.toString();
  }
  else{
    month = month.toString();
  }
  let cur =  [currentFullDate.getFullYear().toString(), month,day].join('-');
  const [currentDate, setCurrentDate] = useState<string>(cur);

  const [inputsHeader,setInputsHeader] = useState(
    {workBlockID:  location.state.workblockName 
      ? location.state.workblockName
      : '',
    packageID: location.state.workpackageName
      ? location.state.workpackageName
      : '',
    date: location.state.data 
        ? new Date(location.state.data.date)
        : new Date(),
    responsibleUser: location.state.data
      ? location.state.data.responsibleUser[0].displayName
      : userList[0],
    userList: [userList[0]],
    comment: location.state.data
      ? location.state.data.comment
      : '',
    status: location.state.data 
      ? location.state.data.state ? 
        location.state.data.state.name
        : enums.WorkBlockState[0]
      : enums.WorkBlockState[0]
    }
  );
  const emptyTable: InputElementTable ={
    id: '',
    number: (1).toString(),
    probeName: '',
    person: userList[0],
    date: currentDate,
    postMortem: 'false',
    comment: '',
  };
  const [inputsTable, setInputsTable] = useState<InputElementTable[]>([emptyTable]);
  const [selectedOption, setSelectedOption] = useState<string>('');


  const loadUserList = async() => {
    await ApiAuth.ListAll()
      .then((data)=>{
        let users = data.filter( x=> !x.deleted && x.state.name !== 'gesperrt' && x.state.name !== 'gelöscht');
        setUserList(users.map(x => x.displayName));
        setUserLoginList(users.map(x => x.loginName));
        setUserIDsList(users.map(x => x.id));
      })
      .catch((e)=>{
        console.log(e);
      });
  };

  //Handle Functions
  const createInitialTableData = (test:__RamanResponse): InputElementTable[] => {
    let idx = 0;
    let temp = test.data.map((data: any) => {
      idx += 1;
      let userName = userList[userIDList.indexOf(data.userID)];
      return {
        id: data.id?? '',
        number: idx.toString(),
        probeName: data.name,
        person: userName,
        date: data.date.split('T')[0],
        postMortem: data.postMortem.toString(),
        comment: data.comment,
      };
    });
    setInputsTable(temp);
    return temp;
  };
  
  const loadData = async() =>{
    // get data for adhesiontest
    await ApiCharacteristics.Get(characteristicsID).then((data)=>{
      if(data.raman){
        let testData: __RamanResponse = {
          id: data.raman.id,
          platformID: data.raman.platformID,
          date: data.raman.date.split('T')[0],
          stateID: data.raman.stateID,
          data: data.raman.data,
          responsibleUser: data.raman.responsibleUser,
          comment: data.raman.comment,
          characteristicID: data.raman.characteristicID,
          files: data.raman.files ?? []
        }
        setCharacteristicData(testData);
        createInitialTableData(testData);
        let inputsHeaderWithUserList = {...inputsHeader};
        inputsHeaderWithUserList.userList = data.raman.responsibleUser.map(x =>x.displayName) ?? [userList[0]];
        inputsHeaderWithUserList.status = data.raman.stateID ?? enums.WorkBlockState[0];
        inputsHeaderWithUserList.responsibleUser =  data.raman.responsibleUser[0].displayName ?? userList[0];
        inputsHeaderWithUserList.comment = data.raman.comment ?? '';
        setInputsHeader(inputsHeaderWithUserList);       
      }
    });
    setInitialization(true);
    setOpenLoadingModal(false);
  };
  const loadFileData = async()=>{
    await ApiCharacteristics.Get(characteristicsID).then((data)=>{
      if(data.raman){
        setFiles(data.raman?.files ?? []);
      }
    });
    setLoadFiles(true);
  };
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputsHeader((values) => ({ ...values, [name]: value }));
  };
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setInputsHeader((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    let inputs = {...inputsHeader};
    if(name === 'responsibleUser'){
      inputs.userList.push(value);
      inputs.responsibleUser = value;
      setInputsHeader(inputs);
    }
    else if(name === 'status' && value === 'abgeschlossen'){
      let newInputs = {...inputsHeader};
      newInputs.date = new Date();
      newInputs.status = value;
      setInputsHeader(newInputs);
    }
    else{
      setInputsHeader((prevState) => ({ ...prevState, [name]: value}));
    }
  };
  const handleOptionChangeTable = (event: React.ChangeEvent<HTMLSelectElement>,index:number) => {
    setSelectedOption(event.target.value);
    setSelectedOption(selectedOption);
    const { name, value } = event.target;
    setInputsTable(inputsTable.map((input, i) =>
        i === index ? { ...input, [name]: value } : input
      )
    );
  };
  const handleCheckboxChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, checked } = event.target;

    setInputsTable((prevInputs) =>
      prevInputs.map((input, i) =>
        i === index
          ? { ...input, [name]: checked.toString() }
          : input
      )
    );
  };
  const handleChangeTable = createChangeHandler<InputElementTable>(
    inputsTable,
    setInputsTable
  );
  const handleInputChangeTable = (
    event: ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    setInputsTable((prevInputs) =>
      prevInputs.map((input, i) =>
        i === index ? { ...input, [name]: value } : input
      )
    );
  };
  const handleAddRaman = () => {
    let newTable = JSON.parse(JSON.stringify(emptyTable)); // copy
    newTable.number = (inputsTable.length + 1).toString();
    setInputsTable([...inputsTable, newTable]);
  };

  const handleSave = () => {
    setOpenLoadingModal(true);
    //date ??
    //responsible User ??
    let user:string[] = inputsHeader.userList.map(name => userLoginList[userList.indexOf(name)]);
    let lastUserLoginName = user[user.length-1];
    let lastUserDisplayName = inputsHeader.userList[inputsHeader.userList.length-1];

    let temp: __RamanDataConverted['data'] = inputsTable.map((items)=>{
      let newDate = new Date(items.date);  
      let userTemp = userLoginList[userList.indexOf(items.person)];
      return{
          id: items.id?? '',
          responsibleUser: userTemp ?? lastUserLoginName,
          date: newDate,
          name: items.probeName,
          postMortem: items.postMortem === 'true',
          comment: items.comment
        }
      });
    if(characteristic.id && characteristic.id!== ''){
      let requestData: __RamanEdit ={
        id: characteristic.id ?? '',
        date: new Date(inputsHeader.date),
        changes:{
          id: characteristic.id ?? '',
          evaluation: null, /////,
          state: inputsHeader.status,
          comment: inputsHeader.comment,
          responsibleUser: lastUserLoginName,
          data: temp
        },
        deletedData: deletedData
      }
      ApiCharacteristics.EditRaman(requestData)
      .then(()=>{
        setInitialization(false);
        loadData();
      })
      .catch(e=>{
        console.log(e);
      });
    }
    else{
      let requestData: __RamanCreate ={
        platform: Config.Platform,
        characteristicID: characteristicsID,
        raman:{
          id: characteristic.id ?? '',
          evaluation: null, /////,
          state: inputsHeader.status,
          comment: inputsHeader.comment,
          responsibleUser: lastUserLoginName,
          data: temp
        }
      }
      ApiCharacteristics.CreateRaman(requestData)
      .then(()=>{
        setInitialization(false);
        loadData();
      })
      .catch(e=>{
        console.log(e);
      });
    }
  };
  const handleGenerateDocument = () => {
    setOpenLoadingModal(true);
    let id = characteristicsID; // the active characteristics data
    ApiCharacteristics.GeneratePdf({id:id, type: TypeofCharacteristic.raman}).then((url) => {
      window.open(url, '_blank');
      setOpenLoadingModal(false);
    })
    .catch((e:unknown)=>{
      setOpenLoadingModal(false);
      //open error modal to show fail
      let errorModal = document.getElementById("ErrorDownloadFailed");
      if(!errorModal) return;
      $('#ErrorDownloadFailed').modal('show');
    });
  };
  const handleBack = () => {
    navigate('/gesamtuebersicht');
  };
  const handleReloadPage = async () => {
    setLoadFiles(false);
  };
  const submitModalCloseAccordion = (response:FormData)  =>{
    sendFileData(response);
    // send formdata to backend
    return response;
  };
  const sendFileData = async(data:FormData) =>{
    const id = characteristic.id;
    if(characteristic.id !== ''){
      await ApiFile.UploadFileForCharacteristics(data,'/characteristics/uploadraman',id).then(()=>{setLoadFiles(false);});
    }
  };

  //Use Effect
  useEffect(() => {
    document.title = 'PM Rem';
    if(!userList || !userLoginList || !userIDList){
      loadUserList();
    }
    if(characteristicsID && !initialized){
      setOpenLoadingModal(true);
      loadData();
    }
    if(characteristicsID && !loadFiles){
      loadFileData();
    }
  },[]);
  useEffect(()=>{
    loadFileData();
  },[loadFiles]);

  return (
    <div className='content-main-container'>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}

      {initialized ?
        <>
          <h1>PM REM</h1>
          <form action='' className='margin-top-15'>
            <div className='col-6'>
              <div className='row ps-1'>
                  <p className='h6'>Arbeitspaket: {inputsHeader.packageID}</p>
                </div>
                <div className='row ps-1'>
                <p className='h6'>Arbeitsblock: {inputsHeader.workBlockID}</p>
              </div>
            </div>
            <div className='row mt-2'>
              <div className='col'>
                <label htmlFor='responsibleUser'>Verantwortlicher:</label>
                <div style={{ visibility: 'hidden', height: 0 }}>
                  <InputField
                    inputType='text'
                    name='responsibleUser'
                    placeholder= {``}
                    value={inputsHeader.responsibleUser}
                    changeHandler={handleChange}
                    disabled={false}
                    inputClass='text-center   border-bottom-primary cut-text-overflow margin-top-5'
                  />
                </div>
                <select
                  name='responsibleUser'
                  value={inputsHeader.responsibleUser}
                  onChange={(event) => handleOptionChange(event)}
                  className='input-lg   border-bottom-primary margin-top-5'
                >
                  {userList.map((option: string) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col'>
                <label htmlFor='status'>Status:</label>
                <div style={{ visibility: 'hidden', height: 0 }}>
                  <InputField
                    inputType='text'
                    name='status'
                    placeholder='aktiv'
                    value={inputsHeader.status}
                    changeHandler={handleChange}
                    disabled={false}
                    inputClass='text-center   border-bottom-primary cut-text-overflow margin-top-5'
                  />
                </div>
                <select
                  name='status'
                  value={inputsHeader.status}
                  onChange={(event) => handleOptionChange(event)}
                  className='input-lg   border-bottom-primary margin-top-5'
                >
                  {enums.WorkBlockState.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='margin-top-5 col-6'>
              <label htmlFor='comment'>Übergreifender Kommentar</label>
              <textarea
                className='input-lg-textarea   border-bottom-primary cut-text-overflow margin-top-5'
                name='comment'
                value={inputsHeader.comment}
                onChange={handleInputChange}
              />
            </div>
          </form>
          <div className="table-responsive margin-top-15">
            <table className='table main-table table-hover align-middle' style={{width:'100%'}}>
              <thead>
                <tr className=" align-middle">
                  <th></th>
                  <th>#</th>
                  <th>Probenname</th>
                  <th>Person</th>
                  <th>Datum</th>
                  <th>Post Mortem</th>
                </tr>
              </thead>
              <tbody>
                {inputsTable.map((input, index) => (
                  <>
                  <tr key={index}>
                    <td rowSpan={2} style={{maxWidth:'30px'}}>
                      <button
                        className='btn-delete'
                        type='button'
                        data-bs-toggle='modal'
                        data-bs-target={'#Raman_DeleteModal_'+String(index)}
                      ><FontAwesomeIcon
                        icon={faXmark}
                        size='sm'
                        />
                      </button>
                      <ConfirmationModal
                        modalID={'Raman_DeleteModal_'+String(index)}
                        modalText='Soll der Eintrag wirklich gelöscht werden?'
                        confirmationText='löschen'
                        handleConfirm={() => {
                          if(inputsTable[index].id!== ''){
                            deletedData.push(inputsTable[index].id);
                          }
                          setInputsTable((prevTable) => {
                            const newTable = [...prevTable];
                            newTable.splice(index, 1);
                            return newTable;
                          });
                          }}
                      />
                    </td>
                    
                    <td>{input.number}</td>
                    <td>
                      <InputField
                        inputType='text'
                        name='probeName'
                        placeholder= ''
                        value={input.probeName}
                        disabled={false}
                        changeHandler={(event:any) => handleChangeTable(event, index)}
                        inputClass ='text-center   cut-text-overflow margin-top-5'
                      />
                    </td>
                    <td>
                      <div style={{ visibility: 'hidden', height: 0 }}>
                        <InputField
                          inputType='text'
                          name='person'
                          placeholder={userList[0]}
                          value={input.person}
                          changeHandler={(event) => handleChangeTable(event,index)}
                          inputClass='text-center   cut-text-overflow margin-top-5'
                        />
                      </div>
                      <select
                        name='person'
                        value={input.person}
                        onChange={(event) => handleOptionChangeTable(event,index)}
                        className='input-lg   border-bottom-primary margin-top-5'
                          >
                          {userList.map((option: string) => (
                          <option key={option} value={option}>
                            {option}
                        </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <InputField
                        inputType='date'
                        name='date'
                        placeholder={''} //characteristic.data[index].date.toString()
                        value={input.date}
                        disabled={false}
                        changeHandler={(e) => handleChangeTable(e, index)}
                        inputClass ='text-center   cut-text-overflow margin-top-5'
                      />
                    </td>
                    <td>
                      <input
                        type='checkbox'
                        name='postMortem'
                        id='postMortem'
                        checked={inputsTable[index].postMortem === 'true'}
                        onChange={(e) => handleCheckboxChange(e, index)}
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={5}>
                      <textarea
                        style={{ width: '100%', margin: '0', lineHeight: 'auto' }}
                        name='comment'
                        id='comment'
                        value={input.comment}
                        onChange={(e) => handleInputChangeTable(e,index)}
                      />
                    </td>
                  </tr>
                  </>
                ))}
              </tbody>
            </table>
          </div>
          <div className='grid colum-gap-3 float-none'>
            <input
              className='btn-main margin-top-5'
              type='button'
              value='+ PM REM hinzufügen'
              onClick={handleAddRaman}
            />
          </div>
          

          <div className='grid colum-gap-3 float-start'>
            <div className='row mt-2'>
              <div className='col'>
                <input
                  className='btn-main'
                  type='button'
                  value='Zurück'
                  onClick={handleBack}
                />
              </div>
              <div className='col'>
                <input
                  className='btn-main col'
                  type='button'
                  value='Speichern'
                  onClick={handleSave}
                />
              </div> 
              <div className='col'>
                {characteristic.id && characteristic.id!== '' ?
                  <input
                  className='btn-main col'
                  type='button'
                  value='Dokument generieren'
                  onClick={handleGenerateDocument}
                />
                :(<></>)
                }
              </div>       
            </div>
          </div>
          <div className='clearfix'></div> {/*cleart float*/}

          {loadFiles && characteristic.id !== '' ?
          <div className='float-none'>
            <div className='mt-4 paket-information-container row'>
              <div className='column'>
                <FileUploadAccordeon
                  handleConfirm={submitModalCloseAccordion}
                  handleReloadPage={handleReloadPage}
                  files={files}
                />
              </div>
            </div>
          </div>
          
          : <></>
          }
          <InfoModal
            modalID='ErrorDownloadFailed'
            infoText='Download fehlgeschlagen'
            confirmationText='Ok'
          />
        </>
      : <></>
    }
    </div>
  );
};
