import React from 'react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface ConfirmationModalProps {
  modalID: string;
  confirmationText: string;
  modalText: string;
  handleConfirm: () => void;
};
const ConfirmationModal = ({
  modalID,
  modalText,
  confirmationText,
  handleConfirm,
}: ConfirmationModalProps) => {
  const [confirmed, setConfirmed] = useState(false);

  const handlePromptConfirmation = () => {
    handleConfirm();
    setConfirmed(true);
  };
  return (
    <div className='modal fade mt-4' id={modalID} tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-middle">
        <div className="modal-content modal-background-lightgrey small-modal">
          <div className="modal-body pb-0 mb-0 px-0">
            <p className='p-2  pb-0 fw-normal' style={{width: '90%'}}>{modalText}</p>
          </div>
          <div className='modal-footer px-0 d-flex justify-content-center'>
            <button
              type='button'
              name='confirmButton'
              data-bs-dismiss="modal"
              className='btn-main margin-top-15'
              onClick={handlePromptConfirmation}
            >{confirmationText}
            </button>
            <button
              type='button'
              name='cancelButton'
              className='btn-main margin-top-15 margin-left-15'
              data-bs-dismiss="modal"
            >Abbrechen
            </button>
          </div>
        </div>,
      </div>
    </div>
  );
};


interface InfoModalProps {
  modalID: string;
  infoText:string;
  confirmationText:string;
};
const InfoModal = ({
  modalID,
  infoText,
  confirmationText
}: InfoModalProps) => {
  return (
    <div className='modal fade mt-4' id={modalID} tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-middle" style={{maxWidth:'300px'}}>
        <div className="modal-content modal-background-lightgrey small-modal">
          <div className="modal-body my-0 pb-0 d-flex justify-content-center">
            <p className='p-2  pb-0 fw-normal text-center' style={{width: '90%'}}>{infoText}</p>
          </div>
          <div className='modal-footer px-4 m-1 d-flex justify-content-center row'>
            <button
              type='button'
              name='confirmButton'
              data-bs-dismiss="modal"
              className='btn-main'
            >{confirmationText}
            </button>
          </div>
        </div>,
      </div>
    </div>
  );
};

interface NavigationModalProps {
  modalID: string;
  infoText:string;
  confirmationText:string;
  path:string;
};
const NavigationModal = ({
  modalID,
  infoText,
  confirmationText,
  path
}: NavigationModalProps) => {
  const navigate = useNavigate();

  return (
    <div className='modal fade' id={modalID} tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-middle" style={{maxWidth:'300px'}}>
        <div className="modal-content modal-background-lightgrey small-modal">
          <div className="modal-body my-0 pb-0 d-flex justify-content-center">
            <p className='p-2  pb-0 fw-normal text-center' style={{width: '90%'}}>{infoText}</p>
          </div>
          <div className='modal-footer px-0 d-flex justify-content-center'>
            <button
              type='button'
              name='cancelButton'
              className='btn-main margin-top-15'
              data-bs-dismiss="modal"
              onClick={() => navigate(-1)}
            > Zurück
            </button>
            <button
              type='button'
              name='confirmButton'
              data-bs-dismiss="modal"
              className='btn-main margin-top-15 margin-left-15'
              onClick={() => navigate(path)}
            >{confirmationText}
            </button>
          </div>
        </div>,
      </div>
    </div>
  );
};
export { ConfirmationModal, InfoModal, NavigationModal};
