import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {ConfirmationModal, InfoModal, NavigationModal } from '../../components/Modal';

import BatteriebauInputModalEdit, { BatteriebauInputModalForVariation, NamesAndIDOfMaterials } from './BatteriebauInputModal';

//import { batteriebauDetailTest, enums, userNamesList } from '../../data';
import { ApiBackendData } from '../../backend/ApiBackendData';
import { ApiBatteryConstruction } from '../../backend/ApiBatteryConstruction';
import {
  InputElementTable,
  tableHeaders,
  emptyTable,
  BatteryDataForModal,
  AnodeForBatteryData,
  CathodeForBatteryData,
  CaseForBatteryData,
  ElectrolytForBatteryData,
  SpacerForBatteryData,
  SeparatorForBatteryData,
  FeatherForBatteryData,
} from './BatteryConstructionData';
import { IBackendBatteryConstructionGet, IBackendMaterialList } from '../../backend/types/__ResponseStructures';
import InputField from '../../UI/InputField';
import {
  __BatteryConverted,
  __BatteryDataConverted,
  __BatteryDataUnconverted,
  __BatteryResponse,
  __BatteryVariationConverted,
  __BatteryVariationWithMaterialNames,
  __DataAnodeConverted,
  __DataCaseConverted,
  __DataCathodeConverted,
  __DataElectrolytConverted,
  __DataFeatherConverted,
  __DataSeperatorConverted,
  __DataSpacerConverted,
  __FileResponse
} from '../../backend/types/__GeneralStructures';
import { faChevronDown, faChevronUp, faXmark } from '@fortawesome/free-solid-svg-icons';
import { __BatteryConstructionEdit } from '../../backend/types/__RequestStructures';
import { ApiWorkBlock } from '../../backend/ApiWorkBlock';
import Loading from '../../components/Ladebalken';
import FileUploadAccordeon from '../../components/FileUpload';
import { ApiFile } from '../../backend/ApiFiles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiMaterial } from '../../backend/ApiMaterial';
import { ApiAuth } from '../../backend/ApiAuth';

/**
 * Table Helper Function
 */
interface CreateTableEntryProps {
  inputElement: any;
  index: number;
  keyName: string;
  subIndex: number;
  handleChangeTable: (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => void;
}
const createChangeHandler =
  <T extends { [key: string]: string }>(
    table: T[],
    setInputs: React.Dispatch<React.SetStateAction<T[]>>
  ) =>
  (
    event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const name = event.target.name;
    const value = String(event.target.value);
    const list = [...table];
    list[index] = { ...list[index], [name]: value };
    setInputs(list);
  };

export function CreateTableEntry({
  inputElement,
  index,
  keyName,
  handleChangeTable,
}: CreateTableEntryProps) {
  return (
    <InputField
      inputType='text'
      name={keyName}
      placeholder={''}
      value={inputElement}
      changeHandler={(event) => handleChangeTable(event, index)}
      disabled={false}
      inputClass='text-center cut-text-overflow margin-top-5'
    />
  );
}

export default function BatteriebauDetail() {
  //set id of batteryConstructions
  const location = useLocation();
  const navigate = useNavigate();

  const [userList, setUserList] = useState<string[]>(location.state.userList);
  const [userLoginNamesList, setUserLoginNamesList] = useState<string[]>(location.state.userLoginList);

  const [initialized, setInitialization] = useState(false);
  const [files, setFiles] = useState<__FileResponse[]>([]);
  const [loadFiles, setLoadFiles] = useState(false);
  const [deletedVariations, setDeletedVariations] = useState<string[]>([]);
  const [packagename, setPackagename] = useState('');
  const [blockname, setBlockname] = useState('');
  const [errorTextBatterieName, setErrorTextBatterieName] = useState('');
  const [errorTextVariation, setErrorTextVariation] = useState('');
  const [updateVariation, setUpdateVariation] = useState(false);
  const [inputsTable, setInputsTable] = useState<InputElementTable[]>([]);
  const [checkMaterialStates, setCheckMaterialStates] = useState(false);

  
  const [batteries, setBatteriesList] = useState<__BatteryConverted[]>([]);
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [validatedInputs, setValidatedInputs] = useState(false);

  const [materials, setMaterials] = useState<IBackendMaterialList[]>([]);
  const [materialNames, setMaterialNames] = useState<NamesAndIDOfMaterials>({
    anode: [],
    cathode: [],
    electrolyt: [],
    feather: [],
    separator: [],
    spacer: [],
    case: [],
  });
  const [loadMaterialNames, setLoadMaterialNames] = useState(false);

  const batteryConstructionID = location.state.data.id;
  const [batteryConstruction, setBatteryConstruction] = useState<IBackendBatteryConstructionGet>(location.state.data);

  let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
  const celltypeList: string[] = location.state 
    ? location.state.type === 'HZ'
      ? location.state.halbzellenList
      : location.state.vollzellenList
    : enums.CellType

  const Location = enums.Location;
  let anode: AnodeForBatteryData | null = null;
  let cathode: CathodeForBatteryData | null = null;
  let electrolyt: ElectrolytForBatteryData | null = null;
  let separator: SeparatorForBatteryData | null = null;
  let feather: FeatherForBatteryData | null = null;
  let spacer: SpacerForBatteryData | null = null;
  let caseOfBattery: CaseForBatteryData | null = null;

  const [batteryData, setBatteryData] = useState<BatteryDataForModal>({
    responsibleUser: [userList[0]],
    celltype: celltypeList[0],
    date: new Date(),
    state: enums.WorkBlockState[0],
    anode: null,
    kathode: null,
    elektolyt: null,
    spacer: null,
    separator: null,
    feder: null,
    gehaeuse: null,
  });

  //Loading
  const loadUserList = async() => {
    await ApiAuth.ListAll()
      .then((data)=>{
        let users = data.filter( x=> !x.deleted && x.state.name !== 'gesperrt' && x.state.name !== 'gelöscht');
        setUserList(users.map(x => x.displayName));
        setUserLoginNamesList(users.map(x => x.loginName));
      })
      .catch((e)=>{
        console.log(e);
      });
  };
  const loadData = () =>{
    getBatteryData();
    getPackageName();
    setOpenLoadingModal(false);
  };
  const loadFileData = async() =>{
    if(batteryConstructionID && batteryConstructionID !== ''){
      await ApiBatteryConstruction.Get(batteryConstructionID).then((data)=>{
        const fileList = data.files ?? [];
        setFiles(fileList);
      });
    }
    setLoadFiles(true);
  };
  const loadMaterial = () => {
    ApiMaterial.ListAll()
      .then((data)=> {
        setMaterials(data);
        let newMaterialNames: NamesAndIDOfMaterials = {
          anode: [],
          cathode: [],
          electrolyt: [],
          feather: [],
          separator: [],
          spacer: [],
          case: [],
        };
      for (let item of data) {
        if (item.type.name === 'Anode') {
          newMaterialNames.anode.push({
            name: item.name,
            id : item.id,
            state: item.state.name === 'gelöscht' || item.state.name === 'gesperrt' || item.deleted
          });
        }
        else if (item.type.name === 'Kathode') {
          newMaterialNames.cathode.push({
            name: item.name,
            id : item.id,
            state: item.state.name === 'gelöscht' || item.state.name === 'gesperrt' || item.deleted
          });
        }
        else if (item.type.name === 'Gehäuse') {
          newMaterialNames.case.push({
            name: item.name,
            id : item.id,
            state: item.state.name === 'gelöscht' || item.state.name === 'gesperrt' || item.deleted
          });
        }
        else if (item.type.name === 'Feder') {
          newMaterialNames.feather.push({
            name: item.name,
            id : item.id,
            state: item.state.name === 'gelöscht' || item.state.name === 'gesperrt' || item.deleted
          });
        } 
        else if (item.type.name === 'Elektrolyt') {
          newMaterialNames.electrolyt.push({
            name: item.name,
            id : item.id,
            state: item.state.name === 'gelöscht' || item.state.name === 'gesperrt' || item.deleted
          });
        }
        else if (item.type.name === 'Spacer') {
          newMaterialNames.spacer.push({
            name: item.name,
            id : item.id,
            state: item.state.name === 'gelöscht' || item.state.name === 'gesperrt' || item.deleted
          });
        }
        else if (item.type.name === 'Separator') {
          newMaterialNames.separator.push({
            name: item.name,
            id : item.id,
            state: item.state.name === 'gelöscht' || item.state.name === 'gesperrt' || item.deleted
          });
        }
        //sort names:
        newMaterialNames.anode = newMaterialNames.anode.sort((a,b)=>{
          if(a.name.toLowerCase() < b.name.toLowerCase()){
            return -1;
          }
          if(a.name.toLowerCase() > b.name.toLowerCase()){
            return 1;
          }
          return 0;
        });
        newMaterialNames.cathode = newMaterialNames.cathode.sort((a,b)=>{
          if(a.name.toLowerCase() < b.name.toLowerCase()){
            return -1;
          }
          if(a.name.toLowerCase() > b.name.toLowerCase()){
            return 1;
          }
          return 0;
        });
        newMaterialNames.case = newMaterialNames.case.sort((a,b)=>{
          if(a.name.toLowerCase() < b.name.toLowerCase()){
            return -1;
          }
          if(a.name.toLowerCase() > b.name.toLowerCase()){
            return 1;
          }
          return 0;
        });
        newMaterialNames.feather = newMaterialNames.feather.sort((a,b)=>{
          if(a.name.toLowerCase() < b.name.toLowerCase()){
            return -1;
          }
          if(a.name.toLowerCase() > b.name.toLowerCase()){
            return 1;
          }
          return 0;
        });
        newMaterialNames.electrolyt = newMaterialNames.electrolyt.sort((a,b)=>{
          if(a.name.toLowerCase() < b.name.toLowerCase()){
            return -1;
          }
          if(a.name.toLowerCase() > b.name.toLowerCase()){
            return 1;
          }
          return 0;
        });
        newMaterialNames.separator = newMaterialNames.separator.sort((a,b)=>{
          if(a.name.toLowerCase() < b.name.toLowerCase()){
            return -1;
          }
          if(a.name.toLowerCase() > b.name.toLowerCase()){
            return 1;
          }
          return 0;
        });
        newMaterialNames.spacer = newMaterialNames.spacer.sort((a,b)=>{
          if(a.name.toLowerCase() < b.name.toLowerCase()){
            return -1;
          }
          if(a.name.toLowerCase() > b.name.toLowerCase()){
            return 1;
          }
          return 0;
        });
          setMaterialNames(newMaterialNames);
          setLoadMaterialNames(true);
        }
        if( newMaterialNames.anode.length < 1 || 
          newMaterialNames.cathode.length < 1 || 
          newMaterialNames.case.length < 1 || 
          newMaterialNames.electrolyt.length < 1 || 
          newMaterialNames.feather.length < 1 || 
          newMaterialNames.separator.length < 1 || 
          newMaterialNames.spacer.length < 1 )
        {
          console.log( newMaterialNames.anode.length < 1 , 
          newMaterialNames.cathode.length < 1 ,
          newMaterialNames.case.length < 1 ,
          newMaterialNames.electrolyt.length < 1 , 
          newMaterialNames.feather.length < 1 , 
          newMaterialNames.separator.length < 1 ,
          newMaterialNames.spacer.length < 1)
          let errorModal = document.getElementById("ErrorMissingMaterial");
          if(!errorModal) return;
          $('#ErrorMissingMaterial').modal('show')
        }
        if( newMaterialNames.anode.filter(x => !x.state).length < 1 || 
            newMaterialNames.cathode.filter(x => !x.state).length < 1 || 
            newMaterialNames.case.filter(x => !x.state).length < 1 || 
            newMaterialNames.electrolyt.filter(x => !x.state).length < 1 || 
            newMaterialNames.feather.filter(x => !x.state).length < 1 || 
            newMaterialNames.separator.filter(x => !x.state).length < 1 || 
            newMaterialNames.spacer.filter(x => !x.state).length < 1 ){
          setCheckMaterialStates(true);
        }
      })
  };
  const getVariationNames = (
    variation: __BatteryVariationWithMaterialNames
  ) => {
    let text = '';
    if (variation.dataAnode) {
      text += variation.dataAnode.material.name + ',';
    }
    if (variation.dataSpacer) {
      text += variation.dataSpacer.material.name + ',';
    }
    if (variation.dataCathode) {
      text += variation.dataCathode.material.name + ',';
    }
    if (variation.dataCase) {
      text += variation.dataCase.material.name + ',';
    }
    if (variation.dataElectrolyt) {
      text += variation.dataElectrolyt.material.name + ',';
    }
    if (variation.dataFeather) {
      text += variation.dataFeather.material.name + ',';
    }
    if (variation.dataSeparator) {
      text += variation.dataSeparator.material.name;
    }
    return text;
  };
  const createTableData = (): InputElementTable[] => {
    let batteryConstructionCopy: IBackendBatteryConstructionGet = {...batteryConstruction};
    let listOfBatteries: __BatteryConverted[] = [];
    let temp: InputElementTable[] = [];
    if(batteryConstructionCopy.isVariations.length > 0){
      let filteredBatteries = batteryConstructionCopy.battery.filter(function (
        battery: __BatteryResponse
      ) {
        if (battery.batteryConstructionID === null) {
          return false;
        }
        return true;
      });
    
      temp = filteredBatteries.map((battery: __BatteryResponse) => {
        //get batteries list
        //put table Data in array for batteries
        let dataAnode: __DataAnodeConverted | undefined = undefined;
        if (battery.variation.dataAnode) {
          dataAnode = {
            id: battery.variation.dataAnode.id,
            material: battery.variation.dataAnode.material.id,
            materialName: battery.variation.dataAnode.material.name,
            diameter: battery.variation.dataAnode.diameter,
          };
        }
        let dataCathode: __DataCathodeConverted | undefined = undefined;
        if (battery.variation.dataCathode) {
          dataCathode = {
            id: battery.variation.dataCathode.id,
            material: battery.variation.dataCathode.material.id,
            materialName: battery.variation.dataCathode.material.name,
            diameter: battery.variation.dataCathode.diameter,
          };
        }
        let dataElectrolyt: __DataElectrolytConverted | undefined = undefined;
        if (battery.variation.dataElectrolyt) {
          dataElectrolyt = {
            id: battery.variation.dataElectrolyt.id,
            material: battery.variation.dataElectrolyt.material.id,
            materialName: battery.variation.dataElectrolyt.material.name,
            volume: battery.variation.dataElectrolyt.volume,
            volumeText: battery.variation.dataElectrolyt.volumeText,
            volumeUnit: battery.variation.dataElectrolyt.volumeUnit,
          };
        }
        let dataSeparator: __DataSeperatorConverted | undefined = undefined;
        if (battery.variation.dataSeparator) {
          dataSeparator = {
            id: battery.variation.dataSeparator.id,
            material: battery.variation.dataSeparator.material.id,
            materialName: battery.variation.dataSeparator.material.name,
            diameter: battery.variation.dataSeparator.diameter,
            numberOfSeperators:
              battery.variation.dataSeparator.numberOfSeparators,
          };
        }
        let dataSpacer: __DataSpacerConverted | undefined = undefined;
        if (battery.variation.dataSpacer) {
          dataSpacer = {
            id: battery.variation.dataSpacer.id,
            material: battery.variation.dataSpacer.material.id,
            materialName: battery.variation.dataSpacer.material.name,
            diameter: battery.variation.dataSpacer.diameter,
            thickness: battery.variation.dataSpacer.thickness,
          };
        }
        let dataCase: __DataCaseConverted | undefined = undefined;
        if (battery.variation.dataCase) {
          dataCase = {
            id: battery.variation.dataCase.id,
            material: battery.variation.dataCase.material.id,
            materialName: battery.variation.dataCase.material.name,
          };
        }
        let dataFeather: __DataFeatherConverted | undefined = undefined;
        if (battery.variation.dataFeather) {
          dataFeather = {
            id: battery.variation.dataFeather.id,
            materialName: battery.variation.dataFeather.material.name,
            material: battery.variation.dataFeather.material.id,
          };
        }
        let variation = {
          id: battery.variation.id,
          anode: dataAnode,
          cathode: dataCathode,
          electrolyt: dataElectrolyt,
          separator: dataSeparator,
          spacer: dataSpacer,
          caseData: dataCase,
          feather: dataFeather,
        };
        let oneBattery: __BatteryConverted = {
          id: battery.id,
          name: battery.name,
          locationOfMeasuring: battery.locOfMeasuringID,
          constructor: battery.constructorOfCell.map((user) => user.loginName),
          trys: battery.trys,
          u1: battery.u1,
          u2: battery.u2,
          annotation: battery.annotation,
          variation: variation,
        };
        listOfBatteries.push(oneBattery);
        let variationNames = getVariationNames(battery.variation);
        return {
          id: oneBattery.id ?? '',
          name: battery.name,
          variation: variationNames,
          location: battery.locOfMeasuringID,
          constructor: battery.constructorOfCell[0].displayName,
          trys: battery.trys.toString(),
          u1: battery.u1,
          u2: battery.u2,
          annotation: battery.annotation,
        };
      });
    }
    else{
      let idx = 0;
      //get batteries list
      //put table Data in array for batteries
      let dataAnode: __DataAnodeConverted[] = [];
      if (batteryConstructionCopy.wishVariationBatteryData.anode.length > 0) {
        dataAnode = batteryConstructionCopy.wishVariationBatteryData.anode.map((entry)=>{
          return{
            id: '',
            material: entry.materialID,
            materialName: materialNames.anode.filter(x => x.id === entry.materialID).length > 0 ? materialNames.anode.filter(x => x.id === entry.materialID)[0].name : '',
            diameter: entry.diameter
          }
        });
      }
      let dataCathode: __DataCathodeConverted[] = [];
      if (batteryConstructionCopy.wishVariationBatteryData.cathode.length > 0) {
        dataCathode = batteryConstructionCopy.wishVariationBatteryData.cathode.map((entry)=>{
          return{
            id: '',
            material: entry.materialID,
            materialName: materialNames.cathode.filter(x => x.id === entry.materialID).length > 0 ? materialNames.cathode.filter(x => x.id === entry.materialID)[0].name : '',
            diameter: entry.diameter
          }
        });
      }
      let dataElectrolyt: __DataElectrolytConverted [] = [];
      if (batteryConstructionCopy.wishVariationBatteryData.electrolyt.length > 0) {
        dataElectrolyt = batteryConstructionCopy.wishVariationBatteryData.electrolyt.map((entry)=>{
            return {
            id: '',
            material: entry.materialID,
            materialName: materialNames.electrolyt.filter(x => x.id === entry.materialID).length > 0 ? materialNames.electrolyt.filter(x => x.id === entry.materialID)[0].name : '',
            volume: entry.volume,
            volumeText: entry.volumeText,
            volumeUnit: entry.volumeUnit,
          };
        });
      }
      let dataSeparator: __DataSeperatorConverted[] = [];
      if (batteryConstructionCopy.wishVariationBatteryData.seperator.length > 0) {
        dataSeparator = batteryConstructionCopy.wishVariationBatteryData.seperator.map((entry)=>{
          return {
            id: '',
            material:entry.materialID,
            materialName: materialNames.separator.filter(x => x.id === entry.materialID).length > 0 ? materialNames.separator.filter(x => x.id === entry.materialID)[0].name : '',
            diameter: entry.diameter,
            numberOfSeperators: entry.numberOfSeparators,
          }
        });
      }
      let dataSpacer: __DataSpacerConverted[] = [];
      if (batteryConstructionCopy.wishVariationBatteryData.spacer.length > 0) {
        dataSpacer = batteryConstructionCopy.wishVariationBatteryData.spacer.map((entry)=>{
          return{
            id: '',
            material: entry.materialID,
            materialName: materialNames.spacer.filter(x => x.id === entry.materialID).length > 0 ? materialNames.spacer.filter(x => x.id === entry.materialID)[0].name : '',
            diameter: entry.diameter,
            thickness: entry.thickness,
          };
        });
      }
      let dataCase: __DataCaseConverted[] = [];
      if (batteryConstructionCopy.wishVariationBatteryData.case.length > 0) {
        dataCase = batteryConstructionCopy.wishVariationBatteryData.case.map((entry)=>{
          return{
            id: '',
            material: entry.materialID,
            materialName: materialNames.case.filter(x => x.id === entry.materialID).length > 0 ? materialNames.case.filter(x => x.id === entry.materialID)[0].name : '',
          };
        });
      }
      let dataFeather: __DataFeatherConverted[] = [];
      if (batteryConstructionCopy.wishVariationBatteryData.feather.length > 0) {
        dataFeather = batteryConstructionCopy.wishVariationBatteryData.feather.map((entry)=>{
          return{
            id: '',
            material: entry.materialID,
            materialName: materialNames.feather.filter(x => x.id === entry.materialID).length > 0 ? materialNames.feather.filter(x => x.id === entry.materialID)[0].name : '',
          };
        });
      }
      temp = batteryConstructionCopy.batteryWishes.map((item)=>{
        let variation = {
          anode: dataAnode[idx] ?? undefined,
          cathode: dataCathode[idx] ?? undefined,
          electrolyt: dataElectrolyt[idx] ?? undefined,
          separator: dataSeparator[idx] ?? undefined,
          spacer: dataSpacer[idx] ?? undefined,
          caseData: dataCase[idx] ?? undefined,
          feather: dataFeather[idx] ?? undefined,
        };
        idx += 1;
        let oneBattery: __BatteryConverted = {
          id: null,
          name: '',
          locationOfMeasuring: item.locationOfConstructionID,
          constructor: batteryConstructionCopy.responsibleUser.map((x)=> x.loginName),
          trys: 0,
          u1: '',
          u2: '',
          annotation: '',
          variation: variation,
        };
        let batteryVariationWithNames: __BatteryVariationWithMaterialNames = {
          id: '',
          platformID: '',
          batteryID: '',
          batteryWishesID: '',
          dataAnode: variation.anode
           ? {
            id: '',
            material:{
              name: variation.anode.materialName ?? '',
              id: variation.anode.id ?? ''
            },
            diameter: variation.anode.diameter
           }
           : null,
          dataCathode: variation.cathode 
            ? {
              id: '',
              material: {
                name: variation.cathode.materialName ?? '',
                id: variation.cathode.material,
              },
              diameter: variation.cathode.diameter
            }
            : null,
          dataElectrolyt: variation.electrolyt
            ? {
              id: '',
              material: {
                name: variation.electrolyt.materialName ?? '',
                id: variation.electrolyt.material,
              },
              volume: variation.electrolyt.volume,
              volumeText: variation.electrolyt.volumeText,
              volumeUnit: variation.electrolyt.volumeUnit
            }
            : null,
          dataSeparator: variation.separator
            ? {
              id: '',
              material: {
                name: variation.separator.materialName ?? '',
                id: variation.separator.material,
              },
              diameter: variation.separator.diameter,
              numberOfSeparators: variation.separator.numberOfSeperators
            }
            : null,
          dataSpacer: variation.spacer
            ? {
              id: '',
              material: {
                name: variation.spacer.materialName ?? '',
                id: variation.spacer.material,
              },
              diameter: variation.spacer.diameter,
              thickness: variation.spacer.thickness
            }
            : null,
          dataCase: variation.caseData
            ? {
              id: '',
              material: {
                name: variation.caseData.materialName ?? '',
                id: variation.caseData.material,
              }
            }
            : null,
          dataFeather: variation.feather
            ? {
              id: '',
              material: {
                name: variation.feather.materialName ?? '',
                id: variation.feather.material,
              },
            }
            : null
        }
        listOfBatteries.push(oneBattery);
        let variationNames = getVariationNames(batteryVariationWithNames);
        return {
          id: oneBattery.variation.id ?? '',
          name: '',
          variation: variationNames,
          location: item.locationOfConstructionID,
          constructor: batteryConstructionCopy.responsibleUser[0].displayName,
          trys: '0',
          u1: '',
          u2: '',
          annotation: '',
        }
      });
    }
    setBatteriesList(listOfBatteries);
    setInputsTable(temp);
    return temp;
  };
  emptyTable.constructor = userList[0];
  const tHeaders = (
    <thead>
      <tr className=" align-middle">
        {tableHeaders.map((header) => (
          <th key={header}>{header}</th>
        ))}
      </tr>
    </thead>
  );
  const getPackageName = async() =>{
    const blockId: string = location.state.workblockID;
    await ApiWorkBlock.Get(blockId).then((data)=>{
      setPackagename(data.workpackage.name);
      setBlockname(data.name);
    });
  };
  const getBatteryData = () => {
    //define anode
    if (batteryConstruction.fixedBatteryData?.anode) {
      anode = {
        id: batteryConstruction.fixedBatteryData.anode.id,
        anodeId: batteryConstruction.fixedBatteryData.anode.materialID,
        anodeName: batteryConstruction.fixedBatteryData.anode.materialName,
        anodengroesse: batteryConstruction.fixedBatteryData.anode.diameter,
      };
    } else {
      anode = null;
    }
    //define cathode
    if (batteryConstruction.fixedBatteryData?.cathode) {
      cathode = {
        id: batteryConstruction.fixedBatteryData.cathode.id,
        kathodeName: batteryConstruction.fixedBatteryData.cathode.materialName,
        kathodeId: batteryConstruction.fixedBatteryData.cathode.materialID,
        kathodengroesse: batteryConstruction.fixedBatteryData.cathode.diameter,
      };
    } else {
      cathode = null;
    }

    //define electrolyt
    if (batteryConstruction.fixedBatteryData?.electrolyt) {
      electrolyt = {
        id: batteryConstruction.fixedBatteryData.electrolyt.id,
        elektrolytName:
          batteryConstruction.fixedBatteryData.electrolyt.materialName,
        elektrolytId:
          batteryConstruction.fixedBatteryData.electrolyt.materialID,
        elektrolytvolumen:
          batteryConstruction.fixedBatteryData.electrolyt.volumeText,
        elektrolytgesamtvolumen:
          batteryConstruction.fixedBatteryData.electrolyt.volume,
      };
    } else {
      electrolyt = null;
    }
    //define separator
    if (batteryConstruction.fixedBatteryData?.separator) {
      separator = {
        id: batteryConstruction.fixedBatteryData.separator.id,
        separatorName:
          batteryConstruction.fixedBatteryData.separator.materialName,
        separatorId: batteryConstruction.fixedBatteryData.separator.materialID,
        separatorgroesse:
          batteryConstruction.fixedBatteryData.separator.diameter,
        separatoranzahl:
          batteryConstruction.fixedBatteryData.separator.numberOfSeparators,
      };
    } else {
      separator = null;
    }
    //define feather
    if (batteryConstruction.fixedBatteryData?.feather) {
      feather = {
        id: batteryConstruction.fixedBatteryData.feather.id,
        federName: batteryConstruction.fixedBatteryData.feather.materialName,
        federId: batteryConstruction.fixedBatteryData.feather.materialID,
      };
    } else {
      feather = null;
    }
    //define spacer
    if (batteryConstruction.fixedBatteryData?.spacer) {
      spacer = {
        id: batteryConstruction.fixedBatteryData.spacer.id,
        spacerName: batteryConstruction.fixedBatteryData?.spacer.materialName,
        spacerId: batteryConstruction.fixedBatteryData?.spacer.materialID,
        spacergroesse: batteryConstruction.fixedBatteryData.spacer.diameter,
        spacerdicke: batteryConstruction.fixedBatteryData.spacer.thickness,
      };
    } else {
      spacer = null;
    }
    //define caseOfBattery
    if (batteryConstruction.fixedBatteryData?.case) {
      caseOfBattery = {
        id: batteryConstruction.fixedBatteryData.case.id,
        gehaeuseName: batteryConstruction.fixedBatteryData.case.materialName,
        gehaeuseId: batteryConstruction.fixedBatteryData.case.materialID,
      };
    } else {
      caseOfBattery = null;
    }
    let data: BatteryDataForModal = {
      responsibleUser: batteryConstruction.responsibleUser.map(
        (user) => user.displayName
      ),
      date: new Date(batteryConstruction.date),
      celltype: batteryConstruction.fixedBatteryData.typeID,
      state: batteryConstruction.state.name,
      anode: anode ?? null,
      kathode: cathode ?? null, 
      elektolyt: electrolyt ?? null,
      spacer: spacer ?? null,
      separator: separator ?? null,
      feder: feather ?? null,
      gehaeuse: caseOfBattery ?? null,
    };
    setBatteryData(data);
  };
  const reloadBatteryMainData = async() => {
    await setDeletedVariations([]);
    await setInitialization(false);
    await ApiBatteryConstruction.Get(batteryConstructionID)
      .then((data)=>{
        setBatteryConstruction(data);
      })
  };

  //Validation
  const validateInputFields = () => {
    setErrorTextVariation('');
    setErrorTextBatterieName('');
    for(let entry of batteries) {
      if((entry.variation.anode === null && batteryData.anode === null) || 
        (entry.variation.caseData === null && batteryData.gehaeuse === null) || 
        (entry.variation.cathode === null && batteryData.kathode === null) || 
        (entry.variation.electrolyt === null && batteryData.elektolyt === null) || 
        (entry.variation.feather === null && batteryData.feder === null) || 
        (entry.variation.separator === null && batteryData.separator === null) || 
        (entry.variation.spacer === null && batteryData.spacer === null)){
        setValidatedInputs(false);
        setErrorTextVariation('Bitte Variation auswählen!');
        return;
      }
    }
    for(let entry of inputsTable){
      if(entry.name === ""){
        setValidatedInputs(false);
        setErrorTextBatterieName('Bitte Batterienamen eingeben!');
        return;
      }
    }
    setValidatedInputs(true);
  };

  //Handle Functions
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>,index:number) => {
    setSelectedOption(event.target.value);
    setSelectedOption(selectedOption);
    const { name, value } = event.target;
    setInputsTable(inputsTable.map((input, i) =>
        i === index ? { ...input, [name]: value } : input
      )
    );

  };
  const handleChangeTable = createChangeHandler<InputElementTable>(
    inputsTable,
    setInputsTable
  );
  const handleAddRowTable = () => {
    emptyTable.constructor = userList[0];
    let newTable = JSON.parse(JSON.stringify(emptyTable)); // copy
    newTable.name = 'Batterie ' + (inputsTable.length + 1).toString();
    setInputsTable([...inputsTable, newTable]);
    let newBattery: __BatteryConverted = {
      id: null,
      name: newTable.name,
      locationOfMeasuring: newTable.location,
      constructor: [newTable.constructor],
      trys: Number(newTable.trys),
      u1: newTable.u1,
      u2: newTable.u2,
      annotation: newTable.annotation,
      variation: {
        id: undefined,
        anode: null,
        caseData: null,
        cathode: null,
        electrolyt: null,
        separator: null,
        spacer: null,
        feather: null,
      },
    };
    batteries.push(newBattery);
    setBatteriesList(batteries);
  };
  const submitModalClose = (item:BatteryDataForModal) =>{
    setUpdateVariation(true);
    setNewBatteriesAfterDeleteFixedData(item);
    return item;
  };
  const setNewBatteriesAfterDeleteFixedData = async(item:BatteryDataForModal) => {
    let newBatteries = [...batteries];
    //if fixed data was deleted it need to be added to variations
    let idx = 0;
    // loop over batteries (type: BatteryConverted)
    // set variation if kind of fixed data was deleted
    // BatteryConverted.variation: __BatteryVariationConverted 
    for(let battery of newBatteries){
      //set data if batteryData and variation is null (means fixed data was deleted)
      //anode
      if(batteryData.anode && !item.anode && !battery.variation.anode){
        newBatteries[idx].variation.anode = {
          id: undefined,
          material: batteryData.anode.anodeId,
          materialName: batteryData.anode.anodeName,
          diameter: batteryData.anode.anodengroesse
        }
      }
      // kathode
      if(batteryData.kathode && !item.kathode && !battery.variation.cathode){
        newBatteries[idx].variation.cathode = {
          id: undefined,
          material: batteryData.kathode.kathodeId,
          materialName: batteryData.kathode.kathodeName,
          diameter: batteryData.kathode.kathodengroesse
        }
      }
      // elektrolyt
      if(batteryData.elektolyt && !item.elektolyt && !battery.variation.electrolyt){
        newBatteries[idx].variation.electrolyt = {
          id: undefined,
          material: batteryData.elektolyt.elektrolytId,
          materialName: batteryData.elektolyt.elektrolytName,
          volume: batteryData.elektolyt.elektrolytgesamtvolumen ?? 0,
          volumeText: batteryData.elektolyt.elektrolytvolumen  ?? '',
          volumeUnit: ''
        }
      }
      // separator
      if(batteryData.separator && !item.separator && !battery.variation.separator){
        newBatteries[idx].variation.separator = {
          id: undefined,
          material: batteryData.separator.separatorId,
          materialName: batteryData.separator.separatorName,
          diameter: batteryData.separator.separatorgroesse,
          numberOfSeperators: batteryData.separator.separatoranzahl
        }
      }
      // spacer
      if(batteryData.spacer && !item.spacer && !battery.variation.spacer){
        newBatteries[idx].variation.spacer = {
          id: undefined,
          material: batteryData.spacer.spacerId,
          materialName: batteryData.spacer.spacerName,
          diameter: batteryData.spacer.spacergroesse,
          thickness: batteryData.spacer.spacerdicke
        }
      }
      // gehaeuse
      if(batteryData.gehaeuse && !item.gehaeuse && !battery.variation.caseData){
        newBatteries[idx].variation.caseData = {
          id: undefined,
          material: batteryData.gehaeuse.gehaeuseId,
          materialName: batteryData.gehaeuse.gehaeuseName
        }
      }
      // feder
      if(batteryData.feder && !item.feder && !battery.variation.feather){
        newBatteries[idx].variation.feather = {
          id: undefined,
          material: batteryData.feder.federId,
          materialName: batteryData.feder.federName
        }
      }
      setBatteriesList(newBatteries);
      setNewVariationData(newBatteries[idx].variation, idx);
      idx += 1;
    }
    setBatteryData(item);
  };
  const setNewVariationData = (item:__BatteryVariationConverted, idx: number) => {
    setInitialization(false);
    let convertedVariation:__BatteryVariationWithMaterialNames= {
      id: '',
      platformID: '',
      batteryID: '',
      batteryWishesID:  '',
      dataAnode: item.anode?{
        id: '',material: {name:item.anode.materialName!, id:item.anode.material},diameter: item.anode.diameter}:null,
      dataCathode: item.cathode?{
        id: '',material: {name:item.cathode.materialName!, id:item.cathode.material},diameter: item.cathode.diameter}:null,
      dataElectrolyt:item.electrolyt?{
        id: '',material: {name:item.electrolyt.materialName!, id:item.electrolyt.material},volume:item.electrolyt.volume,volumeText:item.electrolyt.volumeText,volumeUnit:item.electrolyt.volumeUnit}:null,
      dataSeparator:item.separator?{
        id: '',material: {name:item.separator.materialName!, id:item.separator.material},diameter:item.separator.diameter,numberOfSeparators:item.separator.numberOfSeperators}:null,
      dataSpacer:item.spacer?{
        id: '',material: {name:item.spacer.materialName!, id:item.spacer.material},diameter: item.spacer.diameter,thickness:item.spacer?.thickness}:null,
      dataCase:item.caseData?{
        id: '',material: {name:item.caseData?.materialName!, id:item.caseData?.material}}:null,
      dataFeather:item.feather?{
        id: '',material: {name:item.feather.materialName!, id:item.feather.material}}:null,
  }
    let variationNames = getVariationNames(convertedVariation);

    let newInputsTable = [...inputsTable]
    newInputsTable[idx].variation = variationNames;
    setInputsTable(newInputsTable);
  };
  const submitModalCloseVariation = (item:__BatteryVariationConverted, idx: number) =>{
    batteries[idx].variation = item;
    setBatteriesList(batteries);
    setNewVariationData(item,idx);
    validateInputFields();
    return item;
  };
  const handleEditVariation = (index:number) => {
    if(batteryData.anode !== null && batteryData.kathode !== null  && 
      batteryData.elektolyt !== null  && batteryData.feder !== null  && 
      batteryData.gehaeuse !== null  && batteryData.separator !== null  && 
      batteryData.spacer !== null ){
        return;
    }
    //open variation modal
    let component = '#BatteryConstructionVariationChange_' + String(index);
    $(component).modal('show');
  };
  const getBatteriesForSaving =() =>{
    let listOfBatteries: __BatteryConverted[] =[]
    if(batteries){
      for(let idx =0; idx < inputsTable.length; idx++){
        //put table Data in array for batteries
        let responsibleUser: string[] = [userLoginNamesList[userList.indexOf(inputsTable[idx].constructor)]];
        let oneBattery: __BatteryConverted = {
          id: batteries[idx].id,
          name: inputsTable[idx].name.trim(),
          locationOfMeasuring: inputsTable[idx].location !== "" ? inputsTable[idx].location : 'Halle',
          constructor: responsibleUser?? [userLoginNamesList[0]],
          trys: Number(inputsTable[idx].trys) ?? 0,
          u1: inputsTable[idx].u1 ?? '',
          u2: inputsTable[idx].u2 ?? '',
          annotation: inputsTable[idx].annotation ?? '',
          variation: batteries[idx].variation ?? null,
        };
        listOfBatteries.push(oneBattery);
      }
    }
    return listOfBatteries;
  };
  const handleSave = () => {
    setOpenLoadingModal(true);
    //refresh battery data
    let listOfBatteries = getBatteriesForSaving();
    //get fixed batterydata
    let fixBatteryData: __BatteryDataConverted = {
      id: batteryConstruction.fixedBatteryData.id,
      celltype: batteryData.celltype,
      anode: null,
      cathode: null,
      electrolyt: null,
      separator: null,
      feather: null,
      spacer: null,
      case: null,
    };
    if (batteryConstruction.fixedBatteryData.anode && batteryData.anode) {
      fixBatteryData.anode = {
        id: batteryConstruction.fixedBatteryData.anode.id,
        diameter: batteryData.anode.anodengroesse,
        material: batteryData.anode.anodeId,
      };
    }
    if (batteryConstruction.fixedBatteryData.case && batteryData.gehaeuse) {
      fixBatteryData.case = {
        id: batteryConstruction.fixedBatteryData.case.id,
        material: batteryData.gehaeuse.gehaeuseId,
      };
    }
    if (batteryConstruction.fixedBatteryData.cathode && batteryData.kathode) {
      fixBatteryData.cathode = {
        id: batteryConstruction.fixedBatteryData.cathode.id,
        diameter: batteryData.kathode.kathodengroesse,
        material: batteryData.kathode.kathodeId,
      };
    }
    if (
      batteryConstruction.fixedBatteryData.electrolyt &&
      batteryData.elektolyt &&
      batteryConstruction.fixedBatteryData.electrolyt.volumeUnit
    ) {
      fixBatteryData.electrolyt = {
        id: batteryConstruction.fixedBatteryData.electrolyt.id,
        volume: batteryData.elektolyt.elektrolytgesamtvolumen,
        material: batteryData.elektolyt.elektrolytId,
        volumeText: batteryData.elektolyt.elektrolytvolumen,
        volumeUnit: batteryConstruction.fixedBatteryData.electrolyt.volumeUnit,
      };
    }
    if (batteryConstruction.fixedBatteryData.feather && batteryData.feder) {
      fixBatteryData.feather = {
        id: batteryConstruction.fixedBatteryData.feather.id,
        material: batteryData.feder.federId,
      };
    }
    if (
      batteryConstruction.fixedBatteryData.separator &&
      batteryData.separator
    ) {
      fixBatteryData.separator = {
        id: batteryConstruction.fixedBatteryData.separator.id,
        diameter: batteryData.separator.separatorgroesse,
        material: batteryData.separator.separatorId,
        numberOfSeperators: batteryData.separator.separatoranzahl,
      };
    }
    if (batteryConstruction.fixedBatteryData.spacer && batteryData.spacer) {
      fixBatteryData.spacer = {
        id: batteryConstruction.fixedBatteryData.spacer.id,
        diameter: batteryData.spacer.spacergroesse,
        material: batteryData.spacer.spacerId,
        thickness: batteryData.spacer.spacerdicke,
      };
    }
    //get batteries
    let responsibleUser: string[] = batteryData.responsibleUser.map(names => userLoginNamesList[userList.indexOf(names)]);
    let requestData: __BatteryConstructionEdit = {
      id: batteryConstructionID,
      battery: listOfBatteries,
      date: new Date(batteryData.date),
      responsibleUser:  responsibleUser,
      evaluation:  null,
      state: batteryData.state,
      fixBatteryData: fixBatteryData,
      batterieIDListToDelete: deletedVariations
    };
    ApiBatteryConstruction.Edit(requestData)
      .then(()=>{
        setOpenLoadingModal(true);
        reloadBatteryMainData();
      }
      )
      .catch(()=>{
        setOpenLoadingModal(false);
      });
  };
  const handleBack = () => {
    navigate('/gesamtuebersicht');
  };
  const handleGeneratePdf = async () => {
    let id = batteryConstructionID;
    setOpenLoadingModal(true);
    ApiBatteryConstruction.GeneratePdf(id).then((url) => {
      window.open(url, '_blank');
      setOpenLoadingModal(false);
    })
    .catch((e:unknown)=>{
      setOpenLoadingModal(false);
      //open error modal to show fail
      let errorModal = document.getElementById("ErrorDownloadFailed");
      if(!errorModal) return;
      $('#ErrorDownloadFailed').modal('show');
    });
  };
  const handleGeneratePdfWunsch = async () => {
    setOpenLoadingModal(true);
    let id = batteryConstructionID
    ApiBatteryConstruction.GeneratePdfWish(id).then((url) => {
      window.open(url, '_blank');
      setOpenLoadingModal(false);
    })
    .catch((e:unknown)=>{
      setOpenLoadingModal(false);
      //open error modal to show fail
      let errorModal = document.getElementById("ErrorDownloadFailed");
      if(!errorModal) return;
      $('#ErrorDownloadFailed').modal('show');
    });
  };
  const handleReloadPage = async () => {
    setLoadFiles(false);
  };
  const submitModalCloseAccordion = (response:FormData)  =>{
    sendFileData(response);
    // send formdata to backend
    return response;
  };
  const sendFileData = async(data:FormData) =>{
    if(batteryConstruction){
      const id = batteryConstructionID;
      await ApiFile.Upload(data,'/battery',id).then(async ()=>{
        await ApiBatteryConstruction.Get(id).then((data) => {
          const fileList = data.files?? []
          setFiles(fileList);
        });
      });
    }
    setLoadFiles(false);
  };

  //UseEffects
  useEffect(() => {
    document.title = 'Batteriebau Bearbeiten';
    if(!userList || !userLoginNamesList){
      loadUserList();
    }
    if(!initialized){
      setOpenLoadingModal(true);
      if(!loadMaterialNames){
        loadData();
        loadMaterial();
      }
    }
    if(!loadFiles){
      loadFileData();
    }
  },[]);
  useEffect(() => {
    if(batteryConstruction && loadMaterialNames){
      createTableData();
    }
    else{
      setInitialization(true);
    }
  },[loadMaterialNames, batteryConstruction]);
  useEffect(() => {
    validateInputFields();
    setInitialization(true);
    setOpenLoadingModal(false);
  }, [inputsTable]);
  useEffect(() => {
    setUpdateVariation(false);
  },[batteryData]);
  useEffect(()=>{
    loadFileData();
  },[loadFiles]);
  
  return (
    <div className='content-main-container'>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}

      {initialized ? 
        <>
          <h1>Batteriebau {location.state.type}</h1>
          <div className='margin-top-20 paket-information-container row'>
            <div className='column'>
              <div className='row mb-0'>
                <div className='col-2 fw-bold'>Arbeitspaket:</div>
                <div className='col-2'>{packagename}</div>
                <div className='col-1 fw-bold'>Anode:</div>
                <div className='col-7'>{batteryData.anode ? batteryData.anode?.anodeName + ', Größe: ' +
                    String(batteryData.anode?.anodengroesse) + ' mm' : ''}</div>
              </div>
              <div className='row my-0'>
                <div className='col-2 fw-bold'>Arbeitsblock:</div>
                <div className='col-2'>{blockname}</div>
                <div className='col-1 fw-bold'>Kathode:</div>
                <div className='col-7'>{batteryData.kathode ? batteryData.kathode?.kathodeName + ', Größe: ' +
                    String(batteryData.kathode?.kathodengroesse) + ' mm' : ''}</div>
              </div>
              <div className='row my-0'>
                <div className='col-2 fw-bold'>Zelltyp:</div>
                <div className='col-2'>{batteryData.celltype}</div>
                <div className='col-1 fw-bold'>Elektrolyt:</div>
                <div className='col-7'>{batteryData.elektolyt ? batteryData.elektolyt?.elektrolytName + ', Volumen: ' +
                    String(batteryData.elektolyt?.elektrolytvolumen) + ' , Gesamtvolumen: ' + String(batteryData.elektolyt?.elektrolytgesamtvolumen) + 'ul ': ''}</div>
              </div>
              <div className='row my-0'>
                <div className='col-2 fw-bold'>Verantwortlicher:</div>
                <div className='col-2'>{batteryData.responsibleUser.map((user) => user.concat(', '))}</div>
                <div className='col-1 fw-bold'>Separator:</div>
                <div className='col-7'>{batteryData.separator ? batteryData.separator?.separatorName + ', Größe: ' +
                    String(batteryData.separator?.separatorgroesse) + ' mm, Anzahl: ' + String(batteryData.separator?.separatoranzahl) : ''}</div>
              </div>
              <div className='row my-0'>
                <div className='col-2 fw-bold'>Status:</div>
                <div className='col-2'>{batteryData.state}</div>
                <div className='col-1 fw-bold'>Feder:</div>
                <div className='col-7'>{batteryData.feder ? batteryData.feder?.federName : ''}</div>
              </div>
              <div className='row my-0'>
                <div className='col-4'></div>
                <div className='col-1 fw-bold'>Spacer:</div>
                <div className='col-7'>{batteryData.spacer ? batteryData.spacer?.spacerName + ', Größe: ' +
                    String(batteryData.spacer?.spacergroesse) + ' mm, Dicke: ' + String(batteryData.spacer?.spacerdicke) + ' mm': ''}</div>
              </div>
              <div className='row my-0'>
                <div className='col-4'></div>
                <div className='col-1 fw-bold'>Gehäuse:</div>
                <div className='col-7'>{batteryData.gehaeuse ? batteryData.gehaeuse?.gehaeuseName : ''}</div>
              </div>
            </div>
          </div>
          <button
            className='btn-main margin-top-5'
            type='button'
            data-bs-toggle='modal'
            data-bs-target={'#BatteryConstructionEditStaticData_'}
            >Bearbeiten
              
          </button>
          <BatteriebauInputModalEdit
                modalID='BatteryConstructionEditStaticData_'
                changeHandler={submitModalClose}
                materialNames={materialNames}
                date={batteryData.date}
                selectedCelltype = {batteryConstruction.fixedBatteryData.typeID}
                constructorName= {batteryConstruction.responsibleUser.map((name) => name.displayName)}
                userList ={userList}
                state ={batteryConstruction.state.name}
                anode={batteryData.anode}
                kathode={batteryData.kathode}
                elektrolyt={batteryData.elektolyt}
                separator={batteryData.separator}
                feder={batteryData.feder}
                spacer={batteryData.spacer}
                gehaeuse={batteryData.gehaeuse}
                celltypeList={celltypeList}
              />
          

          <div className="table-responsive margin-top-15">
            <table className='table main-table table-hover align-middle' style={{width:'100%'}}>
              {tHeaders}
              <tbody>
                {inputsTable.map((object, index) => (
                      <tr key={index}>
                          <td key=''style={{width:'55px'}}>
                            <button
                              className='btn-delete'
                              type='button'
                              data-bs-toggle='modal'
                              data-bs-target={'#BatteriebauEdit_DeleteModal_'+String(index)}
                            ><FontAwesomeIcon
                              icon={faXmark}
                              size='sm'
                              />
                            </button>
                            <ConfirmationModal
                              modalID={'BatteriebauEdit_DeleteModal_'+String(index)}
                              modalText={inputsTable.length > 1 
                                ? 'Soll der Eintrag wirklich gelöscht werden?'
                                : 'Achtung! Sie entfernen die letzte Batterie. Sollten Sie die Tabelle so abspeichern, werden beim nächsten Laden die gewünschten Variationen angezeigt.'}
                              confirmationText='löschen'
                              handleConfirm={() => {
                                if(inputsTable[index].id !== ''){
                                  deletedVariations.push(inputsTable[index].id);
                                }
                                const newBattery = batteries;
                                newBattery.splice(index,1)
                                setBatteriesList(newBattery);
                                setInputsTable((prevTable) => {
                                  const newTable = [...prevTable];
                                  newTable.splice(index, 1);
                                  return newTable;
                                });
                              }}
                              />
                          </td>
                          
                          <td key='name' style={{width:'160px'}}>
                          <CreateTableEntry
                              inputElement={object.name}
                              index={index}
                              keyName='name'
                              subIndex={index}
                              handleChangeTable={handleChangeTable}
                            />  
                          </td>
                          <td style={{maxWidth:'200px' }}>
                            {!updateVariation ? 
                              <>
                                <InputField
                                  inputType='text'
                                  name='variation'
                                  placeholder={''}
                                  value={object.variation}
                                  changeHandler={(event) => handleChangeTable(event, index)}
                                  disabled={batteryData.anode !== null && batteryData.kathode !== null  && batteryData.elektolyt !== null  && batteryData.feder !== null  && batteryData.gehaeuse !== null  && batteryData.separator !== null  && batteryData.spacer !== null }
                                  onClick={() => handleEditVariation(index)}
                                  icon={openDropdown ? faChevronUp : faChevronDown}
                                  inputClass ='text-center cut-text-overflow margin-top-5'
                                />
                                <BatteriebauInputModalForVariation
                                  modalID={'BatteryConstructionVariationChange_'+String(index)}
                                  changeHandler={submitModalCloseVariation}
                                  materialNames={materialNames}
                                  anode= {batteryData.anode}
                                  kathode={batteryData.kathode}
                                  elektrolyt={batteryData.elektolyt}
                                  separator={batteryData.separator}
                                  feder={batteryData.feder}
                                  spacer={batteryData.spacer}
                                  gehaeuse={batteryData.gehaeuse}
                                  checkAnode = {batteryData.anode===null}
                                  checkKathode = {batteryData.kathode===null}
                                  checkElektrolyt = {batteryData.elektolyt===null}
                                  checkFeder = {batteryData.feder===null}
                                  checkGehaeuse = {batteryData.gehaeuse===null}
                                  checkSeparator = {batteryData.separator===null}
                                  checkSpacer = {batteryData.spacer===null}
                                  variation={batteries[index].variation}
                                  index =  {index}
                                />
                              </>
                              : <></>
                            }
                            
                          </td>
                          <td style={{width:'120px'}}>
                            <div style={{ visibility: 'hidden', height: 0}}>
                                <InputField
                                  inputType='text'
                                  name='location'
                                  placeholder={''}
                                  value={object.location}
                                  changeHandler={(event) => handleChangeTable(event, index)}
                                  disabled={false}
                                  inputClass='text-center text-md cut-text-overflow margin-top-5'
                                />
                              </div>
                              <select
                                name='location'
                                value={object.location}
                                onChange={(event) => handleOptionChange(event,index)}
                                className='input-lg border-bottom-primary margin-top-5'
                                  >
                                  {Location.map((option: string) => (
                                  <option key={option} value={option}>
                                    {option}
                                </option>
                                ))}
                              </select>
                          </td>
                          <td>
                              <div style={{ visibility: 'hidden', height: 0, maxWidth:'200px' }}>
                                <InputField
                                  inputType='text'
                                  name='constructor'
                                  placeholder={''}
                                  value={object.constructor}
                                  changeHandler={(event) => handleChangeTable(event,index)}
                                  disabled={false}
                                  inputClass='text-center cut-text-overflow margin-top-5'
                                />
                              </div>
                              <select
                                name='constructor'
                                value={object.constructor}
                                onChange={(event) => handleOptionChange(event,index)}
                                className='input-lg border-bottom-primary margin-top-5'
                                  >
                                  {userList.map((option: string) => (
                                  <option key={option} value={option}>
                                    {option}
                                </option>
                                ))}
                              </select>
                          </td>
                          <td key='trys' style={{width:'100px'}}>
                            <input
                              type='number'
                              name='trys'
                              value={Number(object.trys)}
                              min = '0'
                              onChange={(event) =>handleChangeTable(event,index)}
                              className='input-lg  border-bottom-primary'
                            />   
                          </td>
                          <td key='u1' style={{width:'100px'}}>
                            <CreateTableEntry
                              inputElement={object.u1}
                              index={index}
                              keyName='u1'
                              subIndex={index}
                              handleChangeTable={handleChangeTable}
                            /> 
                          </td>
                          <td key='u2' style={{width:'100px'}}>
                            <CreateTableEntry
                              inputElement={object.u2}
                              index={index}
                              keyName='u2'
                              subIndex={index}
                              handleChangeTable={handleChangeTable}
                            /> 
                          </td>
                          <td key='annotation' style={{minWidth:'300px'}}>
                            <CreateTableEntry
                              inputElement={object.annotation}
                              index={index}
                              keyName='annotation'
                              subIndex={index}
                              handleChangeTable={handleChangeTable}
                            /> 
                          </td>

                      </tr>
                ))}
              </tbody>
            </table>
          </div>
          {
            errorTextBatterieName !== '' ?
              <p className = 'text-err-color-font imargin-bottom-10'>{errorTextBatterieName}</p>
            :<></>
          }
          {
            errorTextVariation !== '' ?
              <p className = 'text-err-color-font margin-bottom-10'>{errorTextVariation}</p>
            :<></>
          }

          <input
            className='btn-main margin-top-5'
            type='button'
            value='+ Batterie hinzufügen'
            onClick={handleAddRowTable}
            disabled={checkMaterialStates}
          />
          {checkMaterialStates ? 
              <p className = 'text-err-color-font margin-bottom-10'>Nicht genügend Materialien vorhanden, um weitere Batterien zu erstellen!</p>
              : <></>
          }
          <div className='row margin-top-10'>
            <div className='col'>
              <input
                className='btn-main margin-right-15'
                type='button'
                value='Zurück'
                onClick={handleBack}
              />
              <input
                className='btn-main margin-right-15'
                type='button'
                value='Speichern'
                onClick={handleSave}
                disabled = {!validatedInputs}
              />
              <input
                className='btn-main margin-right-15'
                type='button'
                value='PDF generieren'
                onClick={handleGeneratePdf}
              />
              <input
                className='btn-main'
                type='button'
                value='PDF Wunsch anzeigen'
                onClick={handleGeneratePdfWunsch}
              />
            </div>
          </div>
        </>
       : <></>
      }
      {loadFiles ?
        <div className='margin-top-20 paket-information-container row'>
            <div className='column'>
              <FileUploadAccordeon
                handleConfirm={submitModalCloseAccordion}
                handleReloadPage={handleReloadPage}
                files={files}
              />
            </div>
          </div>
        :<></>
      }
      <InfoModal
        modalID='ErrorDownloadFailed'
        infoText='Download fehlgeschlagen'
        confirmationText='Ok'
      />
      <NavigationModal 
        modalID='ErrorMissingMaterial'
        infoText='Bitte erstellen Sie alle Typen an Materialien mindestens einmal!'
        confirmationText='Ok'
        path='/material'
      />
    </div>
  );
};

