import { ChangeEvent, useEffect, useState } from 'react';
import InputField from '../../UI/InputField';
import { useNavigate, useLocation } from 'react-router-dom';
//import { enums, userNamesList } from '../../data';
import { ApiBackendData } from '../../backend/ApiBackendData';
import Loading from '../../components/Ladebalken';
import { ApiAuth } from '../../backend/ApiAuth';

interface workpackageCreateProps{
  paketName: string,
  initiator: string,
  locationOfSynthesis: string,
  descriptionForWorkblock: string,
  description: string,
  anzahlSheets: number,
  anzahlSheetsR2R: number,
  anzahlHalbzellen: number,
  anzahlVollzellen: number,
  anzahlPouchzellen: number,
  anodenbauCheckbox: boolean,
  sheet2sheetCheckbox: boolean,
  roll2rollCheckbox: boolean,
  batteriebauCheckbox: boolean,
  halbzellenCheckbox: boolean,
  vollzellenCheckbox: boolean,
  batterietestCheckbox: boolean,
  halbzellenTestCheckbox: boolean,
  vollzellenTestCheckbox: boolean,
  charakterisierungCheckbox: boolean,
  adhesionCheckbox: boolean,
  microscopyCheckbox: boolean,
  remCheckbox: boolean,
  xrdCheckbox: boolean,
  ramanCheckbox: boolean,
  postMortemAnalysisCheckbox: boolean,
  anodenmaterial:string,
  selectedIndex: number,
}
export default function ArbeitspaketNeu() {
  const location = useLocation();

  const [userList, setUserList] = useState<string[]>(location.state.userList);
  const [userLoginList, setUserLoginList] = useState<string[]>(location.state.userLoginList);

  // call Apis for basic data
  let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
  const navigate = useNavigate();

  const loadUserList = async() => {
    await ApiAuth.ListAll()
      .then((data)=>{
        let users = data.filter( x=> !x.deleted && x.state.name !== 'gesperrt' && x.state.name !== 'gelöscht');
        setUserList(users.map(x => x.displayName));
        setUserLoginList(users.map(x => x.loginName));
      })
      .catch((e)=>{
        console.log(e);
      });
  };

  useEffect(()=>{
    if(!userList || !userLoginList){
      loadUserList();
    }
  },[]);
  useEffect(() => {
    document.title = 'Arbeitspaket Neu';
    checkBoxCheck();
  });
  const [validate, setValidation] = useState(false);
  const checkBoxCheck = () => {
    setValidation(false);
    let anode: boolean = inputs.anodenbauCheckbox 
      ? (inputs.anodenbauCheckbox && ((inputs.sheet2sheetCheckbox && inputs.anzahlSheets > 0) || inputs.roll2rollCheckbox))
      : true; 
    let batterybau:boolean = inputs.batteriebauCheckbox 
      ? (inputs.batteriebauCheckbox && (inputs.halbzellenCheckbox || inputs.vollzellenCheckbox))
      : true;
    let batterytest:boolean = inputs.batterietestCheckbox 
      ? (inputs.batterietestCheckbox && (inputs.halbzellenTestCheckbox || inputs.vollzellenTestCheckbox))
      : true;
    let characteristic:boolean = inputs.charakterisierungCheckbox 
      ? (inputs.charakterisierungCheckbox && (inputs.adhesionCheckbox || inputs.microscopyCheckbox || inputs.xrdCheckbox || inputs.remCheckbox || inputs.ramanCheckbox || inputs.postMortemAnalysisCheckbox)) 
      : true; 
    if(inputs.anodenbauCheckbox || inputs.batteriebauCheckbox || inputs.batterietestCheckbox || inputs.charakterisierungCheckbox){
      if(anode && batterybau && batterytest && characteristic)
      setValidation(true);
      else{
        setValidation(false);
      }
    }
    return;
  }
  const [inputs, setInputs] = useState<workpackageCreateProps>(
    location.state.arbeitspaket ??{
    paketName: '',
    initiator: userList[0],
    locationOfSynthesis: enums.Location[0],
    descriptionForWorkblock: '',
    description: '',
    anzahlSheets: 0,
    anzahlSheetsR2R: 0,
    anzahlHalbzellen: 0,
    anzahlVollzellen: 0,
    anzahlPouchzellen: 0,
    anodenbauCheckbox: false,
    sheet2sheetCheckbox: false,
    roll2rollCheckbox: false,
    batteriebauCheckbox: false,
    halbzellenCheckbox: false,
    vollzellenCheckbox: false,
    batterietestCheckbox: false,
    halbzellenTestCheckbox: false,
    vollzellenTestCheckbox: false,
    charakterisierungCheckbox: false,
    adhesionCheckbox: false,
    microscopyCheckbox: false,
    remCheckbox: false,
    xrdCheckbox: false,
    ramanCheckbox: false,
    postMortemAnalysisCheckbox: false,
    anodenmaterial: '',
    selectedIndex: 0,
  });
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setInputs((prevState) => ({ ...prevState, [name]: checked }));
    checkBoxCheck();
  };
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setInputs((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
      ['selectedIndex']: event.target.options.selectedIndex,
    }));
  };
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const handleSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOpenLoadingModal(true);
    navigate(`/arbeitspakete/pruefen`, {
      state: {
        arbeitspaket: {
          ...inputs,
        },
        userList: userList,
        userLoginList: userLoginList,
        selectedIndex: inputs.selectedIndex,
      },
    });
  };
  return (
    <div className='content-main-container'>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}
      <h1>Neues Arbeitspaket erstellen</h1>

      <form onSubmit={handleSubmit} className='margin-top-15'>
        <div className='row'>
          <div className='col-5'>
            <div className='row margin-top-15'>
              <div className='col-7'>
                <label htmlFor='initiator'>Verantwortlicher</label>
                <select
                  name='initiator'
                  value={inputs.initiator}
                  onChange={handleOptionChange}
                  className='input-lg text-center text-md border-bottom-primary margin-top-5'
                >
                  {userList.map((option: string) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
              <div className='col-5'>
                <label htmlFor='locationOfSynthesis'>Syntheseort</label>
                <select
                  name='locationOfSynthesis'
                  value={inputs.locationOfSynthesis}
                  onChange={handleOptionChange}
                  className='input-lg text-center text-md border-bottom-primary  margin-top-5'
                >
                  {enums.Location.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='row margin-top-15 margin-right-1 margin-left-1'>
              <label htmlFor='descriptionForWorkblock'>Kurzbeschreibung</label>
              <textarea
                className='input-lg-textarea text-md border-bottom-primary cut-text-overflow margin-top-5'
                name='descriptionForWorkblock'
                value={inputs.descriptionForWorkblock}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className='margin-top-15 col-7'>
            <label htmlFor='description'>Projekt Beschreibung</label>
            <textarea
              className='input-lg-textarea text-md border-bottom-primary cut-text-overflow margin-top-5'
              name='description'
              value={inputs.description}
              onChange={handleInputChange}
            />
          </div>
        </div>
        <div className='row margin-top-15'>
          <div className='col-4'>
            <h2>Anode</h2>
            <div className='row margin-top-15'>
              <div className='col'>
                <input
                  type='checkbox'
                  name='anodenbauCheckbox'
                  id='anodenbauCheckbox'
                  checked={inputs.anodenbauCheckbox}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor='anodenbauCheckbox' className='margin-left-5'>
                  Anodenbau
                </label>
              </div>
              <div className='row'>
                <div className='col-1'></div>
                <div
                  className='col-11 mt-2'
                  style={
                    !inputs.anodenbauCheckbox ? { visibility: 'hidden' } : {}
                  }
                >
                  <div className=''>
                    <input
                      type='checkbox'
                      name='sheet2sheetCheckbox'
                      id='sheet2sheetCheckbox'
                      checked={inputs.sheet2sheetCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      htmlFor='sheet2sheetCheckbox'
                      className='margin-left-5'
                    >
                      Sheet-to-Sheet
                    </label>
                  </div>

                  <div
                    style={
                      !inputs.sheet2sheetCheckbox ? { visibility: 'hidden' } : {}
                    }
                  >
                    <label htmlFor='anzahlSheets'>Anzahl der Sheets</label>
                    <InputField
                      inputType='number'
                      name='anzahlSheets'
                      changeHandler={handleChange}
                      value={inputs.anzahlSheets}
                      min='0'
                      placeholder=''
                      inputClass={`text-md ${inputs.sheet2sheetCheckbox && inputs.anzahlSheets < 1 ? "border-bottom-error" : "border-bottom-primary"}`}
                    />
                    {inputs.anzahlSheets < 1 ?
                      <p className = 'text-err margin-bottom-10'>Anzahl muss größer als 0 sein.</p> : 
                      <></>
                    }
                  </div>
                  <div className='mt-2'>
                    <input
                      type='checkbox'
                      name='roll2rollCheckbox'
                      id='roll2rollCheckbox'
                      checked={inputs.roll2rollCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor='roll2rollCheckbox' className='margin-left-5'>
                      Roll-to-Roll
                    </label>
                  </div>
                  <div
                    style={
                      !inputs.roll2rollCheckbox ? { visibility: 'hidden' } : {}
                    }
                  >
                    <label htmlFor='anzahlSheetsR2R'>Anzahl der Sheets</label>
                    <InputField
                      inputType='number'
                      name='anzahlSheetsR2R'
                      changeHandler={handleChange}
                      value={inputs.anzahlSheetsR2R}
                      min='0'
                      placeholder=''
                      inputClass={`text-md ${inputs.roll2rollCheckbox && inputs.anzahlSheetsR2R < 1 ? "border-bottom-error" : "border-bottom-primary"}`}
                    />
                    {inputs.anzahlSheetsR2R < 1 ?
                      <p className = 'text-err margin-bottom-10'>Anzahl muss größer als 0 sein.</p> : 
                      <></>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-4'>
            <h2>Batterie</h2>
            <div className='row margin-top-15'>
              <div className='col'>
                <input
                  type='checkbox'
                  name='batteriebauCheckbox'
                  id='batteriebauCheckbox'
                  checked={inputs.batteriebauCheckbox}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor='batteriebauCheckbox' className='margin-left-5'>
                  Batteriebau
                </label>
              </div>
              <div className='row'>
                <div className='col-1'></div>
                  <div
                    className='col-11 mt-2'
                    style={
                      !inputs.batteriebauCheckbox ? { visibility: 'hidden' } : {}
                    }
                  >
                    <div>
                      <input
                        type='checkbox'
                        name='halbzellenCheckbox'
                        id='halbzellenCheckbox'
                        checked={inputs.halbzellenCheckbox}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor='halbzellenCheckbox' className='margin-left-5'>
                        Halbzellen
                      </label>
                    </div>
                    <div
                      style={
                        !inputs.halbzellenCheckbox ? { visibility: 'hidden' } : {}
                      }
                    >
                      <label htmlFor='anzahlHalbzellen'>
                        Anzahl der Halbzellen
                      </label>
                      <InputField
                        inputType='number'
                        name='anzahlHalbzellen'
                        changeHandler={handleChange}
                        value={inputs.anzahlHalbzellen}
                        min='0'
                        placeholder=''
                        inputClass='text-md border-bottom-primary margin-bottom-10'
                      />
                    </div>
                    <div>
                      <input
                        type='checkbox'
                        name='vollzellenCheckbox'
                        id='vollzellenCheckbox'
                        checked={inputs.vollzellenCheckbox}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor='vollzellenCheckbox' className='margin-left-5'>
                        Vollzellen
                      </label>
                    </div>
                    <div
                      style={
                        !inputs.vollzellenCheckbox ? { visibility: 'hidden' } : {}
                      }
                    >
                      <label htmlFor='anzahlVollzellen'>
                        Anzahl der Vollzellen
                      </label>
                      <InputField
                        inputType='number'
                        name='anzahlVollzellen'
                        changeHandler={handleChange}
                        value={inputs.anzahlVollzellen}
                        min='0'
                        placeholder=''
                        inputClass='text-md border-bottom-primary margin-bottom-10'
                      />
                      <label htmlFor='anzahlPouchzellen'>
                        Anzahl der Pouchzellen
                      </label>
                      <InputField
                        inputType='number'
                        name='anzahlPouchzellen'
                        value={inputs.anzahlPouchzellen}
                        changeHandler={handleChange}
                        min='0'
                        placeholder=''
                        inputClass='text-md border-bottom-primary margin-bottom-10'
                      />
                    </div>
                  </div>
                </div>
            </div>
            <div className='row  margin-top-15'>
              <div className='col'>
                <input
                  type='checkbox'
                  name='batterietestCheckbox'
                  id='batterietestCheckbox'
                  checked={inputs.batterietestCheckbox}
                  onChange={handleCheckboxChange}
                />
                <label htmlFor='batterietestCheckbox' className='margin-left-5'>
                  Batterietest
                </label>
              </div>
              <div className='row'>
                <div className='col-1'></div>
                <div
                  className='col-11 mt-2'
                  style={
                    !inputs.batterietestCheckbox ? { visibility: 'hidden' } : {}
                  }
                >
                  <div>
                    <input
                      type='checkbox'
                      name='halbzellenTestCheckbox'
                      id='halbzellenTestCheckbox'
                      checked={inputs.halbzellenTestCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      htmlFor='halbzellenTestCheckbox'
                      className='margin-left-5'
                    >
                      Halbzellen
                    </label>
                  </div>
                  <div className='mt-2'>
                    <input
                      type='checkbox'
                      name='vollzellenTestCheckbox'
                      id='vollzellenTestCheckbox'
                      checked={inputs.vollzellenTestCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      htmlFor='vollzellenTestCheckbox'
                      className='margin-left-5'
                    >
                      Vollzellen
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-4'>
            <h2>Analyse</h2>
            <div className='row margin-top-15'>
              <div className='col'>
                <input
                  type='checkbox'
                  name='charakterisierungCheckbox'
                  id='charakterisierungCheckbox'
                  checked={inputs.charakterisierungCheckbox}
                  onChange={handleCheckboxChange}
                />
                <label
                  htmlFor='charakterisierungCheckbox'
                  className='margin-left-5'
                >
                  Charakterisierung
                </label>
              </div>
              <div className='row'>
                <div className='col-1'></div>
                <div
                  className='col-11'
                  style={
                    !inputs.charakterisierungCheckbox
                      ? { visibility: 'hidden' }
                      : {}
                  }
                >
                  {/*
                    * Charakterisierung
                    * Umbenennungen:
                        Hafttest -> Sonstiges
                        Post Mortem -> PM Foto
                        Raman -> PM REM
                        Mikroskopie -> LiMi
                  */}
                  
                  <div className='mt-2'>
                    <input
                      type='checkbox'
                      name='microscopyCheckbox'
                      id='microscopyCheckbox'
                      checked={inputs.microscopyCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor='microscopyCheckbox' className='margin-left-5'>
                      LiMi
                    </label>
                  </div>
                  <div className='mt-2'>
                    <input
                      type='checkbox'
                      name='remCheckbox'
                      id='remCheckbox'
                      checked={inputs.remCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor='remCheckbox' className='margin-left-5'>
                      REM
                    </label>
                  </div>
                  <div className='mt-2'>
                    <input
                      type='checkbox'
                      name='xrdCheckbox'
                      id='xrdCheckbox'
                      checked={inputs.xrdCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor='xrdCheckbox' className='margin-left-5'>
                      XRD
                    </label>
                  </div>
                  <div className='mt-2'>
                    <input
                      type='checkbox'
                      name='ramanCheckbox'
                      id='ramanCheckbox'
                      checked={inputs.ramanCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor='ramanCheckbox' className='margin-left-5'>
                      PM REM
                    </label>
                  </div>
                  <div className='mt-2'>
                    <input
                      type='checkbox'
                      name='postMortemAnalysisCheckbox'
                      id='postMortemAnalysisCheckbox'
                      checked={inputs.postMortemAnalysisCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    <label
                      htmlFor='postMortemAnalysisCheckbox'
                      className='margin-left-5'
                    >
                      PM Foto
                    </label>
                  </div>
                  <div className='mt-2'>
                    <input
                      type='checkbox'
                      name='adhesionCheckbox'
                      id='adhesionCheckbox'
                      checked={inputs.adhesionCheckbox}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor='adhesionCheckbox' className='margin-left-5'>
                      Sonstiges
                    </label>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
        <input
            className='btn-main margin-top-10'
            type='button'
            value='Zurück'
            onClick={() => navigate(-1)}
          />
        <input
          type='submit'
          value='Arbeitspaket Überprüfen'
          className='btn-main margin-left-15'
          disabled = {!validate}
        />
      </form>
    </div>
  );
}
