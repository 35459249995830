// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

body {
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
  height: 100%;
  background: #f6f6f6;
  margin: 0;
}

#root {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,qBAAqB;AACvB;;AAEA;EACE,uBAAuB;EACvB,YAAY;AACd;;AAEA;EACE,qCAAqC;EACrC,kBAAkB;EAClB,YAAY;EACZ,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,YAAY;AACd","sourcesContent":["* {\n  box-sizing: border-box;\n  list-style: none;\n  text-decoration: none;\n}\n\nhtml {\n  scroll-behavior: smooth;\n  height: 100%;\n}\n\nbody {\n  font-family: 'Montserrat', sans-serif;\n  overflow-x: hidden;\n  height: 100%;\n  background: #f6f6f6;\n  margin: 0;\n}\n\n#root {\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
