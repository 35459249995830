import { ChangeEvent, useEffect, useState } from 'react';
import ModularSearchHeader from '../../components/ModularSearchHeader/ModularSearchHeader';
import { Table } from '../../components/Table';
import { useNavigate } from 'react-router-dom';
import {
  ApiBatteryConstruction,
  BatteryConstructionConverter,
} from '../../backend/ApiBatteryConstruction';
import { IBackendMaterialList } from '../../backend/types/__ResponseStructures';
import { ApiMaterial } from '../../backend/ApiMaterial';
import { ApiAuth } from '../../backend/ApiAuth';
import { ApiBackendData } from '../../backend/ApiBackendData';
import Loading from '../../components/Ladebalken';
import { InfoModal } from '../../components/Modal';

// TODO: Daten anders verwalten

export default function Batteriebau() {
  const navigate = useNavigate();
  const [initializedTable, setInitializedTable] = useState(false);
  const [displayedTable, setDisplayedTable] = useState<string[][]>([]);
  const [userDisplayNamesList, setUserDisplayNamesList] = useState<string[]>(
    []
  );

  const [userLoginNamesList, setUserLoginNamesList] = useState<string[]>([]);
  const [materials, setMaterials] = useState<IBackendMaterialList[]>();
  let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
  let halbzellenList = ['Halbzelle'];
  let vollzellenList = enums.CellType.filter(x=> x!== 'Halbzelle');

  const tableHeaders = [
    'Arbeitsblock',
    'Typ',
    'Verantwortlicher',
    'Datum',
    'Status',
    'Ort',
    'Bearbeiten',
  ];
  useEffect(() => {
    document.title = 'Batteriebau';
    if (!initializedTable) {
      setOpenLoadingModal(true);
      ApiMaterial.ListAll().then((data) => {
        setMaterials(data);
      });
      ApiBatteryConstruction.ListAll().then((data) => {
        setDisplayedTable(
          BatteryConstructionConverter.ConvertFromApiToTableList(data)
        );
        setInitializedTable(true);
      });
      ApiAuth.ListAll()
      .then((data)=>{
        let users = data.filter( x=> !x.deleted && x.state.name !== 'gesperrt' && x.state.name !== 'gelöscht');
        setUserDisplayNamesList(users.map(x => x.displayName));
        setUserLoginNamesList(users.map(x => x.loginName));
      })
      .catch((e)=>{
        console.log(e);
      });
      setOpenLoadingModal(false);
    }
  });

  const [inputs, setInputs] = useState({});
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const getInitialTable = () => {
    return displayedTable;
  };
  const handleDetail = async (index: number) => {
    await ApiBatteryConstruction.Get(displayedTable[index][0])
      .then((data) =>{ 
        navigate('/batteriebau/details',{
          state:{
            data: data,
            userList: userDisplayNamesList,
            userLoginList: userLoginNamesList,
            materials: materials,

            workblockID: data.workblock.id, 
            halbzellenList: halbzellenList,
            vollzellenList: vollzellenList,
            type: data.fixedBatteryData.typeID === 'Halbzelle' ? 'HZ' : 'VZ'
          }
        });
      })
      .catch((err)=>{
        console.log(err);
      });
  };
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const handlePDF = async (index: number) => {
    setOpenLoadingModal(true);
    let id = displayedTable[index][0];
    ApiBatteryConstruction.GeneratePdf(id).then((url) => {
      window.open(url, '_blank');
      setOpenLoadingModal(false);
    })
    .catch((e:unknown)=>{
      setOpenLoadingModal(false);
      //open error modal to show fail
      let errorModal = document.getElementById("ErrorDownloadFailed");
      if(!errorModal) return;
      $('#ErrorDownloadFailed').modal('show');
    });
  };

  const someFunctions = {
    func: [handleDetail, handlePDF],
    val: ['Details', 'PDF'],
    defaultState: ["aktiv", "aktiv"],
    modal: [false,false]
  };
  return (
    <div className='content-main-container'>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}
      {initializedTable ? (
        <>
          <ModularSearchHeader
            title='Batteriebau'
            dropdownOptions={tableHeaders.slice(0, -1)}
            handleChange={handleChange}
            setDisplayedTable={setDisplayedTable}
            displayTable={displayedTable}
            getInitialTable={getInitialTable}
          />
        </>
      ) : (
        <></>
      )}
      <Table
        modalIDList={[]}
        headers={tableHeaders}
        valuesList={displayedTable}
        stateList={null}
        functionsList={someFunctions}
      />
      <InfoModal
        modalID='ErrorDownloadFailed'
        infoText='Download fehlgeschlagen'
        confirmationText='Ok'
      />
    </div>
  );
}
