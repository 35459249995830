import Config from "../util/Config";
import { CreateBasicResponse} from "./types/__GeneralStructures";
import { ApiClient } from "./ApiClient";
import BasicResponse from "./BasicResponse";
import * as requestTypes from "./types/__RequestStructures"
import * as responseTypes from "./types/__ResponseStructures"
import { ApiPrepareRequest } from './ApiPrepareRequest';

export class ApiMaterial {

    static basePath: string = "/material";
    static baseUrl: string = Config.BackendUrl + ApiMaterial.basePath;
    //Get
    static Get(id: string = ''): Promise<responseTypes.IBackendMaterialGet> {
        let path = '/get';

        // prepare query
        let query = ApiPrepareRequest.PrepareGetQuery(id);
        let url = ApiMaterial.baseUrl + path + query;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.get(url);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject = (await response.json()) as BasicResponse<responseTypes.IBackendMaterialGet>;
        resolve(responseObject.data);
        });
    };
    // TODO: add filter and search
    /**
     * /auth/list
     */
    static ListAll(): Promise<responseTypes.IBackendMaterialList[]> {

        let path = "/list";
        let url = ApiMaterial.baseUrl + path;

        return new Promise(async (resolve, reject) => {

            let response = await ApiClient.get(url);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }

            let responseObject = (await response.json()) as BasicResponse<responseTypes.IBackendMaterialList[]>;
            resolve(responseObject.data);

        });

    };
    static Create(requestData: requestTypes.__MaterialUnconverted): Promise<string> {
        let path = "/create";
        let url = ApiMaterial.baseUrl + path;
    
        let convertedRequestData = JSON.parse(JSON.stringify(requestData)) as requestTypes.__MaterialConverted;
        return new Promise(async (resolve, reject) => {
    
            let response = await ApiClient.post(url, convertedRequestData);
            if (!response.ok) {
                if (response.status === 401) {
                    reject("Unauthorized!");
                }
                else {
                    reject(`Error! status: ${response.status}`);
                }
            }
    
            let responseObject = (await response.json()) as CreateBasicResponse<string>;
    
            resolve(responseObject.id);
        });
    };
    static Edit(requestData: requestTypes.__MaterialUnconverted):Promise<boolean>{
        let path = "/edit";
        let url = ApiMaterial.baseUrl + path;
        //convert material
        let convertedRequestData = JSON.parse(JSON.stringify(requestData)) as requestTypes.__MaterialConverted;

        return new Promise(async (resolve, reject) => {
          let response = await ApiClient.put(url, convertedRequestData);
          if (!response.ok) {
              if (response.status === 401) {
                  reject("Unauthorized!");
              }
              else {
                  reject(`Error! status: ${response.status}`);
              }
          }
    
          let responseObject = (await response.json()) as BasicResponse<string>;
    
          resolve(responseObject.success);
      });
    };
      //delete
    static Delete(requestData: requestTypes.__IDRequest): Promise<boolean> {
        let path = '/delete';
        let url = ApiMaterial.baseUrl + path;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject =
            (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
        });
    };
    //lock
    static Lock(requestData: requestTypes.__IDRequest): Promise<boolean> {
        let path = '/lock';
        let url = ApiMaterial.baseUrl + path;

        return new Promise(async (resolve, reject) => {
        let response = await ApiClient.post(url, requestData);
        if (!response.ok) {
            if (response.status === 401) {
            reject('Unauthorized!');
            } else {
            reject(`Error! status: ${response.status}`);
            }
        }

        let responseObject =
            (await response.json()) as BasicResponse<string>;

        resolve(responseObject.success);
        });
    };
    }


export class MaterialConverter {
    // Temporärer Hinweis: Gedacht für die Tabellen Planung und Arbeitspakete
    static ConvertFromApiToTableList(data: responseTypes.IBackendMaterialList[]) {
        // [1, 'Gold', 'Material', 1337, 4200, 'vorhanden', '19.03.2023'],
        let simpleList: string[][] = [];
        data.forEach(item => {
            if(!item.deleted) {
                let dateOfAdjustment = new Date(item.dateOfAdjustment.toString());
                simpleList.push([
                    item.id,
                    item.name,
                    item.type.name,
                    item.available.toString(),
                    item.total.toString(),
                    item.state.name,
                    dateOfAdjustment.toLocaleDateString()
                ]);
            }
        });

        return simpleList;
    }
}
