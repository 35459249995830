import Config from '../util/Config';
import {
  __Location,
  __PlannedStepsConverted,
  __Platform,
  __WorkBlockState,
  __WorkPackageResponse,
  CreateBasicResponse as CreateBasicResponse,
} from './types/__GeneralStructures';
import { ApiPrepareRequest } from './ApiPrepareRequest';
import { ApiClient } from './ApiClient';
import BasicResponse from './BasicResponse';
import * as requestTypes from './types/__RequestStructures';
import * as responseTypes from './types/__ResponseStructures';

// Sample: /workpackage/list ? filter = { "state": { "name": "inPlanung" } }
export interface IBackendWorkPackageFilter {
  state?: { name: string };
}

export class ApiWorkPackage {
  static basePath: string = '/workpackage';
  static baseUrl: string = Config.BackendUrl + ApiWorkPackage.basePath;

  //Get
  static Get(id: string): Promise<responseTypes.IBackendWorkPackage> {
    let path = '/get';

    // prepare query
    let query = ApiPrepareRequest.PrepareGetQuery(id);
    let url = ApiWorkPackage.baseUrl + path + query;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.get(url);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject =
        (await response.json()) as BasicResponse<responseTypes.IBackendWorkPackage>;

      resolve(responseObject.data);
    });
  }
  // TODO: add filter and search
  /**
   * /workpackage/list
   */
  static ListAll(
    filter: IBackendWorkPackageFilter = {},
    search: string = ''
  ): Promise<responseTypes.IBackendWorkPackageList[]> {
    let path = '/list';

    // prepare query
    let query = ApiPrepareRequest.PrepareListQuery(filter, search);
    let url = ApiWorkPackage.baseUrl + path + query;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.get(url);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<
        responseTypes.IBackendWorkPackageList[]
      >;

      resolve(responseObject.data);
    });
  }

  /**
   * /workpackage/create
   */
  static Create(
    requestData: requestTypes.__WorkPackageCreate
  ): Promise<string> {
    let path = '/create';
    let url = ApiWorkPackage.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let convertedRequestData = {
        platform: requestData.platform,
        initiator: requestData.initiator,
        state: requestData.state,
        responsibleUser: requestData.responsibleUser,
        shortDescription: requestData.shortDescription,
        projectDescription: requestData.projectDescription,
        descriptionForWorkblock: requestData.descriptionForWorkblock,
        locationOfSynthesis: requestData.locationOfSynthesis,
        plannedSteps: JSON.parse(
          JSON.stringify(requestData.plannedSteps)
        ) as __PlannedStepsConverted,
      };
      let workpackage = { workpackage: convertedRequestData };
      let response = await ApiClient.post(url, workpackage);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject =
        (await response.json()) as CreateBasicResponse<string>;

      resolve(responseObject.id);
    });
  }

  /**
   * /workpackage/edit
   */
  static Edit(requestData: requestTypes.__WorkPackageEdit): Promise<boolean> {
    let path = '/edit';
    let url = ApiWorkPackage.baseUrl + path;
    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.put(url, requestData);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.success);
    });
  }
  //delete
  static Delete(requestData: requestTypes.__IDRequest): Promise<boolean> {
    let path = '/delete';
    let url = ApiWorkPackage.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.post(url, requestData);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.success);
    });
  }
  //lock
  static Lock(requestData: requestTypes.__IDRequest): Promise<boolean> {
    let path = '/lock';
    let url = ApiWorkPackage.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.post(url, requestData);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.success);
    });
  }
  //activate
  static Activate(requestData: requestTypes.__IDRequest): Promise<boolean> {
    let path = '/activate';
    let url = ApiWorkPackage.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.post(url, requestData);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.success);
    });
  }
  //set state back to planning
  static Plan(requestData: requestTypes.__IDRequest): Promise<boolean> {
    let path = '/plan';
    let url = ApiWorkPackage.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.post(url, requestData);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.success);
    });
  }
  //close
  static Close(requestData: requestTypes.__IDRequest): Promise<boolean> {
    let path = '/close';
    let url = ApiWorkPackage.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.post(url, requestData);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.success);
    });
  }
  //upload
  static Upload(requestData: requestTypes.__UploadFiles): Promise<boolean> {
    let path = '/upload';
    let url = ApiWorkPackage.baseUrl + path;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.post(url, requestData);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.success);
    });
  }
  //generatepdf
  static GeneratePdf(id: string): Promise<string> {
    let path = '/generatepdf';
    let query = '?id=' + id;
    let url = ApiWorkPackage.baseUrl + path + query;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.get(url);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.data);
    });
  }
  
  //generatepdfaddition
  static GeneratePdfAddition(id: string): Promise<string> {
    let path = '/generatepdfaddition';
    let query = '?id=' + id;
    let url = ApiWorkPackage.baseUrl + path + query;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.get(url);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.data);
    });
  }
  
  //generatepdfsummary
  static GeneratePdfSummary(id: string): Promise<string> {
    let path = '/generatepdfsummary';
    let query = '?id=' + id;
    let url = ApiWorkPackage.baseUrl + path + query;

    return new Promise(async (resolve, reject) => {
      let response = await ApiClient.get(url);
      if (!response.ok) {
        if (response.status == 401) {
          reject('Unauthorized!');
        } else {
          reject(`Error! status: ${response.status}`);
        }
      }

      let responseObject = (await response.json()) as BasicResponse<string>;

      resolve(responseObject.data);
    });
  }
}

export class WorkpackageConverter {
  // Temporärer Hinweis: Gedacht für die Tabellen Planung und Arbeitspakete
  static ConvertFromApiToTableList(data: responseTypes.IBackendWorkPackageList[]) {
    // [1, 'Paket 1', 'Max', '15.03.2023', 'abgeschlossen', 'Halle'],
    let simpleList: string[][] = [];
    data.forEach((item) => {
      simpleList.push([
        item.id,
        item.name,
        item.responsibleUser[0].displayName,

        new Date(item.date).toLocaleDateString() +
          '\n' +
          new Date(item.date).toLocaleTimeString(),

        item.state.name === "inPlanung" ? "in Planung" : item.state.name,
        item.locationOfSynthesis.name,
      ]);
    });

    return simpleList;
  }
}
