export type InputsHeader = {
  name: string;
  verwantwortlicher: string;
  syntheseort: string;
  vorgehensbeschreibung: string;
};

export type InputElementTableOne = {
  //deleteEntry: string;
  schicht: string;
  material: string;
  pendelhuebe: string;
  hoehe: string;
  blitz: string[];
  blitzList: {id:string,label:string}[]
  kommentar: string;
  [key: string]: string|any; // add an index signature
};

export const emptyTableOne: InputElementTableOne = {
  //deleteEntry: 'false',
  schicht: '',
  material: '',
  pendelhuebe: '',
  hoehe: '',
  blitz: [],
  blitzList:[],
  kommentar: '',
};

export type InputElementTableTwo = {
  blitz: string;
  anzahl: number;
  energie: string;
  position: string;
  mms: string;
  impulslaenge: string;
  intervallaenge: string;
  kommentar: string;
  [key: string]: string|number; // add an index signature
};

export const emptyTableTwo: InputElementTableTwo = {
  blitz: '',
  anzahl: 0,
  energie: '',
  position: '',
  mms: '',
  impulslaenge: '',
  intervallaenge: '',
  kommentar: '',
};

export const emptyinputsHeader: InputsHeader = {
  name: '',
  verwantwortlicher: '',
  syntheseort: '',
  vorgehensbeschreibung: '',
};

export const tableHeadersOne = [
  '',
  'Schritt',
  'Material',
  'Pendelhübe',
  'Höhe (nm)',
  'Blitz',
  'Kommentar',
];

export const tableHeadersTwo = [
  '',
  'Blitz',
  'Anzahl',
  'Energie (kV)',
  'Position',
  'mm/s',
  'Impulslänge (ms)',
  'Intervalllänge (ms)',
  'Kommentar',
];
