import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import InputField from '../../UI/InputField';
//import { enums } from '../../data';
import { ApiBackendData } from '../../backend/ApiBackendData';
import { ApiAuth } from '../../backend/ApiAuth';
import { __UserEdit } from '../../backend/types/__RequestStructures';

interface BenutzerbearbeitungProps {
  handleModal: () => void;
  handleDismiss: (showModal:boolean) => void;
  loginName: string;
  role: string;
  position: string;
  displayName: string;
  state: string;
  loginNameList: string[];
  displayNameList: string[];
  modalID:string;
};
export default function Benutzerbearbeitung({
  loginName,
  role,
  state,
  position,
  displayName,
  loginNameList,
  displayNameList,
  modalID,
  handleModal,
  handleDismiss
}: BenutzerbearbeitungProps) {
  // call Apis for basic data
  let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();

  const [inputs, setInputs] = useState({
    loginName: loginName,
    role: role,
    position: position,
    displayName: displayName,
    state: state,
    password: '',
    passwordRepetition: '',
  });
  const [passwordError, setpasswordError] = useState(false);
  const [passwordEmpty, setpasswordEmpty] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const [displayNameEmpty, setdisplayNameEmpty] = useState(false);
  const [missingInput, setMissingInputs] = useState(false);
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    let requestData: __UserEdit;
    // if password is equal, set new password
    console.log(editPassword, inputs)
    if(inputs.password === inputs.passwordRepetition && inputs.password !== '' && editPassword){
      requestData ={
        userLoginName: loginName,
        displayName: inputs.displayName,
        state: inputs.state,
        position: inputs.position,
        role: inputs.role,
        password: inputs.password
      }
      ApiAuth.Edit(requestData).then(()=>{
        handleModal();
        ApiAuth.GetUserNamesList(true);
      });
      
    }
    else if(inputs.password === inputs.passwordRepetition && inputs.password === '' && !editPassword){
      requestData ={
        userLoginName: loginName,
        displayName: inputs.displayName,
        state: inputs.state,
        position: inputs.position,
        role: inputs.role,
      }
      ApiAuth.Edit(requestData).then(()=>{
        handleModal();
        ApiAuth.GetUserNamesList(true);
      });
    }
    
  };
  const validateFields = () =>{
    setdisplayNameEmpty(false);
    setpasswordError(false);
    setpasswordEmpty(false);
    setMissingInputs(false);
    
    if(inputs.displayName === ''){
      setdisplayNameEmpty(true);
      setMissingInputs(true);
    }

    if(editPassword){
      if(inputs.password !== inputs.passwordRepetition){
        setpasswordError(true);
        setMissingInputs(true);
      }
      if(inputs.password === '' || inputs.passwordRepetition === ''){
        setpasswordEmpty(true);
        setMissingInputs(true);
      }
    }
  };
  useEffect(() => {
    console.log('edit')
    validateFields();
  }, [inputs, editPassword]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    setInputs({ ...inputs, [name]: value });
  };

  const modalElement = document.getElementById(modalID) as HTMLElement;
  useEffect(()=>{
    if(modalElement){
      modalElement.addEventListener('hidden.bs.modal', () => handleDismiss(false) );
    }
  });
  return (
    <div className='modal fade mt-4' id= {modalID} tabIndex={-1} aria-hidden="true">
      <div className="modal-dialog modal-dialog-middle">
        <div className="modal-content px-3 modal-background-lightgrey benutzererstellung-modal">
          <div className="modal-header">
            <h2 className="modal-title">Benutzer bearbeiten</h2>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <label htmlFor=''>Login-Name</label>
            <InputField
              inputType='text'
              name='loginName'
              placeholder={inputs.loginName}
              value={inputs.loginName}
              changeHandler={handleChange}
              disabled= {true}
              inputClass='text-md border-bottom-primary margin-bottom-5'
            />
            <div>
              <label htmlFor=''>Anzeige-Name</label>
              <InputField
                value={inputs.displayName}
                inputType='text'
                name='displayName'
                placeholder={inputs.displayName}
                changeHandler={handleChange}
                inputClass={displayNameEmpty? ' text-md border-bottom-error':' text-md border-bottom-primary margin-bottom-5'}
              />
              {
                displayNameEmpty ?
                  <p className = 'text-err margin-bottom-5'>Feld darf nicht leer sein.</p>
                : <></>
              }
            </div>
            <label className='margin-top-0' htmlFor=''>
              Position
            </label>
            <InputField
              inputType='text'
              name='position'
              placeholder= {inputs.position}
              value={inputs.position}
              changeHandler={handleChange}
              inputClass=' text-md border-bottom-primary margin-bottom-5'
            />
            <label className='margin-top-0' htmlFor=''>
              Typ
            </label>
            <div style={{ visibility: 'hidden', height: 0 }}>
              <InputField
                inputType='text'
                name='role'
                placeholder={inputs.role}
                value={inputs.role}
                changeHandler={handleChange}
                disabled={false}
                inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
              />
            </div>
            <select
              name='role'
              value={inputs.role}
              onChange={(event) => handleOptionChange(event)}
              className='input-lg text-md border-bottom-primary margin-bottom-5'
            >
              {enums.Role.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            
            <div className="form-check mt-2">
              <input className="form-check-input border-dark" type="checkbox" value={String(editPassword)} id="flexCheckPasswordChange" onClick={event => setEditPassword(!editPassword)}/>
              <label className="form-check-label" htmlFor="flexCheckPasswordChange">
                Passwort bearbeiten
              </label>
            </div>
            {editPassword ?
              <div>
                <label htmlFor=''>Passwort</label>
                <InputField
                  inputType='password'
                  name='password'
                  placeholder='*****'
                  value={inputs.password}
                  changeHandler={handleChange}
                  inputClass={ (passwordError || passwordEmpty)?' text-md border-bottom-error':' text-md border-bottom-primary margin-bottom-5'}
                />
                <label htmlFor=''>Passwort wiederholen</label>
                <InputField
                  inputType='password'
                  name='passwordRepetition'
                  placeholder='*****'
                  value={inputs.passwordRepetition}
                  changeHandler={handleChange}
                  inputClass={ (passwordError || passwordEmpty)?' text-md border-bottom-error':' text-md border-bottom-primary margin-bottom-5'}
                />
                {
                    passwordError ?
                      <p className = {`text-err ${passwordEmpty ? 'mb-0' : 'margin-bottom-5'}`}>Passwörter stimmmen nicht überein.</p>
                    :<></>
                  }
                  {
                    passwordEmpty ?
                      <p className = 'text-err margin-bottom-5'>Felder dürfen nicht leer sein.</p>
                    : <></>
                  }
              </div>
              : <></>
            }
          </div>
          <div className='modal-footer'>
            <button 
              type="button" 
              className="btn-main margin-top-5" 
              data-bs-dismiss="modal"> Abbrechen
            </button>
            <button
              className='btn-main margin-top-5'
              type='button'
              disabled={missingInput}
              onClick={handleSubmit} 
              data-bs-dismiss="modal"
              > Speichern
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
