import { ChangeEvent, useState, useEffect } from 'react';
import InputField from '../../UI/InputField';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { TableUtil } from '../../util/TableUtil';

// Decalre interfaces for props
interface ITableFilter {
  index: number;
  searchString: string;
}
interface FilterDropdownProps {
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setDisplayedTable?: (table: any[]) => void;
  getInitialTable: () => any[];
  dropdownOptions: string[];
  displayedTable: any[];
}
export default function FilterDropdown({
  handleChange,
  setDisplayedTable,
  getInitialTable,
  dropdownOptions,
  displayedTable,
}: FilterDropdownProps) {
  const defaultSelectedOption = {
    index: 0,
    searchString: '',
  };
  const [selectedOptions, setSelectedOptions] = useState<ITableFilter[]>([
    defaultSelectedOption,
  ]);

  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      if (getInitialTable) {
        let temp = getInitialTable();
        displayedTable = temp;
      }
    }
  }, []);
  /*
  Both functions need to be at top level, because they are passed down to the child components
  They are used to update the state of the parent component and the filter input field
  */
  const updateTable = (updatedSelectedElement: ITableFilter[]) => {
    if (setDisplayedTable) {
      setDisplayedTable(
        TableUtil.handleTableFilter(displayedTable, updatedSelectedElement)
      );
    }
  };
  const handleDeleteEntry = (index:number ) => {
    let newSelect= [...select];
    if(newSelect.length > 0){
      newSelect.splice(index,1);
      setSelect(newSelect);
    }
    let selectOP = [...selectedOptions];
    selectOP.splice(index,1);
    setSelectedOptions(selectOP);
    
    
  };
  const updateMainInputValue = (updatedSelectedElement: ITableFilter[]) => {
    let temp = '';
    updatedSelectedElement.forEach((element) => {
      temp += element.searchString;
      temp += ', ';
    });
    setMainInputValue(temp);
  };

  // State of the component
  const [openDropdown, setOpenDropdown] = useState(false);
  const [select, setSelect] = useState<React.ReactNode[]>([
    <FilterDropdownSelect
      key={Math.random()}
      dropdownOptions={dropdownOptions}
      filterIndex={0}
      setSelectedElement={setSelectedOptions}
      updateTable={updateTable}
      updateMainInputValue={updateMainInputValue}
      handleDelete={handleDeleteEntry}
    />,
  ]);
  const [mainInputValue, setMainInputValue] = useState('');

  // Handler functions for the component
  const handleClick = () => {
    setOpenDropdown(!openDropdown);
  };
  const handleSelectAddition = () => {
    setSelect((prevSelect) => [
      ...prevSelect,
      <FilterDropdownSelect
        key={Math.random()}
        dropdownOptions={dropdownOptions}
        filterIndex={prevSelect.length}
        setSelectedElement={setSelectedOptions}
        updateTable={updateTable}
        updateMainInputValue={updateMainInputValue}
        handleDelete={handleDeleteEntry}
      />,
    ]);
    selectedOptions.push(defaultSelectedOption);
  };

  const handleResetTable = () => {
    setSelect([
      <FilterDropdownSelect
        key={Math.random()}
        dropdownOptions={dropdownOptions}
        filterIndex={0}
        setSelectedElement={setSelectedOptions}
        updateTable={updateTable}
        updateMainInputValue={updateMainInputValue}
        handleDelete={handleDeleteEntry}
      />,
    ]);
    setSelectedOptions([defaultSelectedOption]);
    updateTable([defaultSelectedOption]);
    updateMainInputValue([defaultSelectedOption]);
  };

  return !initialized ? (
    <></>
  ) : (
    <div className='input-wrapper'>
      <InputField
        inputType='text'
        name='sortierung'
        placeholder='Alle'
        value={mainInputValue}
        changeHandler={handleChange}
        disabled={true}
        inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
        icon={openDropdown ? faChevronUp : faChevronDown}
        onClick={handleClick}
      />
      <div
        className={
          openDropdown
            ? `filter-dropdown-wrapper `
            : `disabled filter-dropdown-wrapper`
        }
      >
        {select}
        <div className={openDropdown ? `disabled padding-5` : `padding-5`}>
          <input
            type='button'
            value='Filter hinzufügen'
            className='btn-main text-center text-md padding-5'
            onClick={handleSelectAddition}
          />
          <input
            type='button'
            value='Filter zurücksetzen'
            className=' btn-main text-center text-md margin-left-5'
            onClick={handleResetTable}
          />
        </div>
      </div>
    </div>
  );
}
interface FilterDropdownSelectProps {
  setSelectedElement: React.Dispatch<React.SetStateAction<ITableFilter[]>>;
  updateTable: (updatedSelectedElement: ITableFilter[]) => void;
  updateMainInputValue: (updatedSelectedElement: ITableFilter[]) => void;
  handleDelete: (index:number) => void;
  dropdownOptions: string[];
  filterIndex: number;
}
const FilterDropdownSelect = ({
  setSelectedElement,
  updateTable,
  updateMainInputValue,
  handleDelete,
  dropdownOptions,
  filterIndex,
}: FilterDropdownSelectProps) => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [input, setInput] = useState<string>('');

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
    let index = event.target.selectedIndex;
    setSelectedElement((prevSelectedElement) => {
      const updatedSelectedElement = [...prevSelectedElement];
      updatedSelectedElement[filterIndex].searchString = input;
      updatedSelectedElement[filterIndex].index = index;
      return updatedSelectedElement;
    });
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
    setSelectedElement((prevSelectedElement) => {
      const updatedSelectedElement = [...prevSelectedElement];
      updatedSelectedElement[filterIndex].searchString = event.target.value;
      updateTable(updatedSelectedElement);
      updateMainInputValue(updatedSelectedElement);
      return updatedSelectedElement;
    });
  };

  return (
    <div className='row my-2 pb-1'>
      <div className='col-1'>
        <input
          className='border-0 bg-transparent btn-delete'
          type='button'
          value='x'
          onClick={event => handleDelete(filterIndex)}
        />
      </div>
      <div className='col-5'>
        <select
          name=''
          value={selectedOption}
          onChange={handleOptionChange}
          className='input-lg text-center text-md border-bottom-primary'
        >
          {dropdownOptions.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <div className='col-6'>
        <input
          type='text'
          value={input}
          id=''
          className='input-lg text-center text-md border-bottom-primary'
          onChange={handleInputChange}
        />
      </div>
      
    </div>
  );
};
