export class ApiPrepareRequest {
  // prepare query
  static PrepareListQuery(filter: any, search: string): string {
    let query = '?';
    // add filter
    if (Object.keys(filter).length > 0) {
      query += 'filter=' + JSON.stringify(filter);
    }
    // add search
    if (search !== '') {
      if (query.length > 1) {
        query += '&';
      }
      query += 'search=' + search;
    }
    // if no filter and no search, set empty
    if (query.length === 1) {
      query = '';
    }

    return query;
  }
  static PrepareGetQuery(id: string): string {
    let query = '?';
    // add id
    if (Object.keys(id).length > 0) {
      query += 'id=' + id;
    }
    return query;
  }
}
