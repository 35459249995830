import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChangeEvent } from 'react';
interface InputFieldProps {
  inputType: string;
  step?:string;
  icon?: IconProp;
  name: string;
  changeHandler: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder: string;
  inputClass: string;
  value?: any;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  disabled?: boolean;
  min?: string;
}
const InputField = ({
  inputType,
  step,
  icon,
  name,
  changeHandler,
  placeholder,
  inputClass,
  value,
  onClick,
  disabled,
  min,
}: InputFieldProps) => {
  return (
    <div className='input-wrapper'>
      <input
        className={`input-lg ${inputClass}`}
        type={inputType}
        step={step}
        placeholder={placeholder}
        name={name}
        onChange={changeHandler}
        value={value}
        disabled={disabled}
        min={min}
      />
      {icon ? (
        <span className='input-icon' onClick={onClick}>
          <FontAwesomeIcon icon={icon} />
        </span>
      ) : (
        <></>
      )}
    </div>
  );
};

export default InputField;

