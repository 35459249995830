import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import * as bootstrap from "bootstrap";
// import * as $ from 'jquery';
import Benutzererstellung from './Benutzererstellung';
import Benutzerbearbeitung from './Benutzerbearbeitung';
import ModularSearchHeader from '../../components/ModularSearchHeader/ModularSearchHeader';
import { Table } from '../../components/Table';
import { ApiAuth, UserConverter } from '../../backend/ApiAuth';
import { ApiBackendData } from '../../backend/ApiBackendData';
import { __UserEdit } from '../../backend/types/__RequestStructures';
import { IBackendUser } from '../../backend/types/__ResponseStructures';
import Loading from '../../components/Ladebalken';


function updateButtonStates(table : string[][], disabled:boolean = false) : string[][] {
  let buttonStateTable : string[][] = [];
  for(let row of table) {
    let newStates = ["aktiv", "aktiv", "aktiv", "aktiv"];
    if(row[3] == "gesperrt") {
      newStates[1] = "hidden";
      newStates[2] = "aktiv";
    }
    else {
      newStates[1] = "aktiv"
      newStates[2] = "hidden"
    }
    if(disabled && row[3] !== "gesperrt" && row[4]=== 'Admin'){
      newStates[1] = "hidden"
      newStates[2] = "hidden"
      newStates[3] = "hidden"
    }
    if(disabled && row[3] === "gesperrt" && row[4]=== 'Admin'){
      newStates[1] = "hidden";
      newStates[2] = "aktiv";
    }
    buttonStateTable.push(newStates);
  }
  return buttonStateTable;
}

export default function Benutzerverwaltung() {
  // call Apis for basic data
  const [initializedTable, setInitializedTable] = useState(false);
  const [initializedModal, setInitializedModal] = useState(false);

  const [displayedTable, setDisplayedTable] = useState<string[][]>([]);
  const [userDataList, setUserDataList] = useState<IBackendUser[]>([]);
  const [updateKey, setUpdateKey] = useState(0);

  const [buttonStateTable, setButtonStateTable] = useState<string[][]|null>(null);

  useEffect(() => {
    document.title = 'Benutzerverwaltung';
    if (!initializedTable) {
      setOpenLoadingModal(true);
      ApiAuth.ListAll().then((data) => {
        setDisplayedTable(UserConverter.ConvertFromApiToTableList(data));
        setUserDataList(data);
        setInitializedTable(true);
        setOpenLoadingModal(false);
        setInitializedTable(true);
      });
    }
  });
  
  useEffect(() => {
    let checkIfLastAdmin: boolean = checkUserRole();
    setButtonStateTable(updateButtonStates(displayedTable, checkIfLastAdmin));
  }, [displayedTable]);

  const checkUserRole = () =>{
    let adminCount = 0;
    for(let user of userDataList){
      if(user.role.name === 'Admin' && !user.deleted && user.state.name !== 'gesperrt'){
        adminCount += 1;
      }
    }
    if(adminCount <= 1){
      return true;
    }
    return false;
  }
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const tableHeader = ['Name', 'Letzter Login', 'Status','Rolle', 'Funktionen'];


  const [inputs, setInputs] = useState({
    id: '',
    loginName: '',
    role: '',
    position: '',
    displayName: '',
    state: '',
  });
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const getInitialTable = () => {
    return displayedTable;
  };

  const handleModalClose = async() => {
    setInitializedModal(false);
    await ApiAuth.ListAll().then((data)=>{
      setDisplayedTable(UserConverter.ConvertFromApiToTableList(data));
      setUserDataList(data);
      setUpdateKey(updateKey+1);
    });
    setInitializedTable(false);
  };

  const handleEdit = (index: number) => {
    let id = displayedTable[index][0];
    let user = null;
    for(let userData of userDataList){
      if(userData.id == id) {
        user = userData;
      }
    }
    if(user == null) return;

    //set Inputs for edit modal
    setInputs({
      id: id,
      loginName: user.loginName,
      role: user.role.name,
      position: user.position,
      displayName: user.displayName,
      state: user.state.name
    });
    setInitializedModal(true);
  };  
  const handleLock = (index: number) => {
    ApiAuth.Lock({id: displayedTable[index][0]}).then(()=>{
      setDisplayedTable((prevTable) => {
        const newTable = [...prevTable];
        newTable[index][3] = 'gesperrt';
        return newTable;
      });
    });
  };
  const handleDelete = (index: number) => {
    ApiAuth.Delete({id:displayedTable[index][0]}).then(()=>{
      setDisplayedTable((prevTable) => {
        const newTable = [...prevTable];
        newTable.splice(index, 1);
        return newTable;
      });
    });
  };

  const handleUnlock = (index:number) => {
    let id = displayedTable[index][0];
    let user = null;
    for(let userData of userDataList){
      if(userData.id == id) {
        user = userData;
      }
    }
    if(user == null) return;

    let requestData : __UserEdit ={
      userLoginName: user.loginName,
      displayName: user.displayName,
      state: 'aktiv',
      position: user.position,
      role: user.role.name,
    };
    ApiAuth.Edit(requestData).then(()=>{
      setDisplayedTable((prevTable) => {
        const newTable = [...prevTable];
        newTable[index][3] = 'aktiv';
        return newTable;
      });
    });
  };
  const someFunctions = {
    func: [handleEdit, handleLock, handleUnlock, handleDelete ],
    val: ['Bearbeiten', 'Sperren', 'Entsperren', 'Löschen'],
    defaultState: ["aktiv", "aktiv", "aktiv", "aktiv"],
    modal: [false, true,true, true]
  };
  useEffect(() => {
    if(inputs.id!== '' && initializedModal){
      // open modal: benutzerbearbeitungs-modal
      let modalUser = document.getElementById("benutzerbearbeitungs-modal");
      if(!modalUser) return;
      $("#benutzerbearbeitungs-modal").modal('show');
    }
  },[inputs]);

  return (
    <div className='content-main-container'>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}
      {initializedTable ? (
        <ModularSearchHeader
          title='Benutzer'
          buttonText='Neuer Benutzer'
          modalID='createNewUserModal'
          dropdownOptions={tableHeader.slice(0, -1)}
          handleChange={handleChange}
          setDisplayedTable={setDisplayedTable}
          displayTable={displayedTable}
          getInitialTable={getInitialTable}
        />
      ) : (
        <></>
      )}
      <Table
        modalIDList={{...someFunctions}.val.map(x => 'User_' + x)}
        modalTextList={['','Soll der Eintrag wirklich gesperrt werden?','Soll der Eintrag wirklich entsperrt werden?','Soll der Eintrag wirklich gelöscht werden?']}
        headers={tableHeader}
        valuesList={displayedTable}
        stateList={buttonStateTable}
        functionsList={someFunctions}
        key={updateKey}
      />

      <Benutzererstellung 
        id={'createNewUserModal'}
        handleModal={handleModalClose}
        loginNameList = {userDataList.map(user => user.loginName)}
        displayNameList = {userDataList.map(user => user.displayName)}
      />
      {initializedModal ? 
        < Benutzerbearbeitung
          modalID={'benutzerbearbeitungs-modal'}
          handleModal={handleModalClose}
          handleDismiss={setInitializedModal}
          loginName={inputs.loginName}
          role={inputs.role}
          position={inputs.position}
          displayName={inputs.displayName}
          state={inputs.state}
          loginNameList = {userDataList.map(user => user.loginName)}
          displayNameList = {userDataList.map(user => user.displayName)}
        />
        : <></>
      }
      
    </div>
  );
}
