import { ChangeEvent, useEffect, useState } from 'react';
import ModularSearchHeader from '../../components/ModularSearchHeader/ModularSearchHeader';
import { Table } from '../../components/Table';
import { useNavigate } from 'react-router-dom';
import {
  ApiBatteryTest,
  BatteryTestConverter,
} from '../../backend/ApiBatteryTest';
import { ApiAuth } from '../../backend/ApiAuth';
import { ApiBackendData } from '../../backend/ApiBackendData';
import { ApiBatteryConstruction } from '../../backend/ApiBatteryConstruction';
import { ApiWorkBlock } from '../../backend/ApiWorkBlock';
import Loading from '../../components/Ladebalken';
import { InfoModal } from '../../components/Modal';


// TODO: Daten anders verwalten

export default function Batterietest() {
  const navigate = useNavigate();
  const [initializedTable, setInitializedTable] = useState(false);

  const [displayedTable, setDisplayedTable] = useState<string[][]>([]);
  const tableHeaders = [
    'Arbeitsblock',
    'Typ',
    'Verantwortlicher',
    'Datum',
    'Status',
    'Ort',
    'Bearbeiten',
  ];
  const [batteries, setBatteries] = useState<{ id: string; name: string }[]>();
  const [userLoginNamesList, setUserLoginNamesList] = useState<string[]>([]);
  const [userDisplayNamesList, setUserDisplayNamesList] = useState<string[]>(
    []
  );

  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
  useEffect(() => {
    let temp: string[][] = [['']];
    document.title = 'Batterietest';
    if (!initializedTable) {
      setOpenLoadingModal(true);
      setInitializedTable(true);
      ApiBatteryTest.ListAll().then((data) => {
        setDisplayedTable(BatteryTestConverter.ConvertFromApiToTableList(data));
      })
      .catch((e)=>{
        console.log(e);
      });
      ApiBatteryTest.ListAll().then((data) => {
        temp = temp.concat(
          BatteryTestConverter.ConvertFromApiToTableList(data)
        );
      })
      .catch((e)=>{
        console.log(e);
      });
      ApiAuth.ListAll()
      .then((data)=>{
        let users = data.filter( x=> !x.deleted && x.state.name !== 'gesperrt' && x.state.name !== 'gelöscht');
        setUserDisplayNamesList(users.map(x => x.displayName));
        setUserLoginNamesList(users.map(x => x.loginName));
      })
      .catch((e)=>{
        console.log(e);
      });
      ApiBatteryConstruction.ListBatteries().then((data) => {
        let batteries: { id: string; name: string }[] = [];
        data.map((element: { id: string; name: string }) => {
          batteries.push({ id: element.id, name: element.name });
        });
        setBatteries(batteries);
      })
      .catch((e)=>{
        console.log(e);
      });
      setOpenLoadingModal(false);
    }
  });

  const [inputs, setInputs] = useState({});
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const getInitialTable = () => {
    return displayedTable;
  };
  const handleDetail = async (index: number) => {
    setOpenLoadingModal(false);
    await ApiBatteryTest.Get(displayedTable[index][0])
      .then((data) => {
        let blockID:string = data.workblock.id;
        let halbzellenList = ['Halbzelle'];
        let vollzellenList = enums.CellType.filter(x=> x!== 'Halbzelle');
        let celltype = data.batteryTestProcessing.length > 0
          ? data.batteryTestProcessing[0].battery.batteryData.typeID
          : data.batteryTestWishes.length > 0
            ? data.batteryTestWishes[0].type.name
            : ''
        
        ApiWorkBlock.Get(blockID).then((block)=>{
          navigate('/batterietest/details', {
            state: {
              data: data,
              userList: userDisplayNamesList,
              userLoginList: userLoginNamesList,
              batteries: batteries,
              workblockID: blockID, 
              workblockName: block?.name,
              workpackageID: block?.workpackage.id,
              workpackageName: block?.workpackage.name,
              halbzellenList: halbzellenList,
              vollzellenList: vollzellenList,
              type: celltype === 'Halbzelle' ? 'HZ' : 'VZ'
            },
          });
        })
        
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handlePDF = async (index: number) => {
    setOpenLoadingModal(true);
    let id = displayedTable[index][0];
    ApiBatteryTest.GeneratePdf(id).then((url) => {
      window.open(url, '_blank');
      setOpenLoadingModal(false);
    })
    .catch((e:unknown)=>{
      setOpenLoadingModal(false);
      //open error modal to show fail
      let errorModal = document.getElementById("ErrorDownloadFailed");
      if(!errorModal) return;
      $('#ErrorDownloadFailed').modal('show');
    });
  };
  const someFunctions = {
    func: [handleDetail, handlePDF],
    val: ['Details', 'PDF'],
    defaultState: ["aktiv", "aktiv"],
    modal:[false,false]
  };
  return (
    
    <div className='content-main-container'>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}
      {initializedTable ? (
        <ModularSearchHeader
          title='Batterietest'
          dropdownOptions={tableHeaders.slice(0, -1)}
          handleChange={handleChange}
          setDisplayedTable={setDisplayedTable}
          displayTable={displayedTable}
          getInitialTable={getInitialTable}
        />
      ) : (
        <></>
      )}

      <Table
        modalIDList={{...someFunctions}.val.map(x => 'BatterieTest_' + x)}
        headers={tableHeaders}
        valuesList={displayedTable}
        stateList={null}
        functionsList={someFunctions}
      />
      <InfoModal
        modalID='ErrorDownloadFailed'
        infoText='Download fehlgeschlagen'
        confirmationText='Ok'
      />
    </div>
  );
}
