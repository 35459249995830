import { ChangeEvent, useState, useEffect, ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Config from '../../util/Config';
import { ApiBackendData } from '../../backend/ApiBackendData';
import { ApiBatteryTest } from '../../backend/ApiBatteryTest';
import { ConfirmationModal } from '../../components/Modal';
import InputField from '../../UI/InputField';
import Loading from '../../components/Ladebalken';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { ApiAuth } from '../../backend/ApiAuth';

interface IBatteryTestTableEntry {
  batterietyp: string;
  batterieanzahl: string;
  formingI: string;
  formingU: string;
  formingQ: string;
  cyclingI: string;
  cyclingU: string;
  cyclingQ: string;
  cyclingCV: string;
  ratentestI: string;
  ratentestU: string;
  ratentestQ: string;
  bemerkungen: string;
};

const BatterietestNeu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [enumsCelltype, setEnums] = useState<string[]>([]);
  const celltypeList: string[] = location.state 
    ? location.state.type === 'HZ'
      ? location.state.halbzellenList
      : location.state.vollzellenList
    : enumsCelltype
  const defaultTable : IBatteryTestTableEntry[] = [
    {
      batterietyp: celltypeList[0],
      batterieanzahl: '',
      formingI: '',
      formingU: '',
      formingQ: '',
      cyclingI: '',
      cyclingU: '',
      cyclingQ: '',
      cyclingCV: '',
      ratentestI: '',
      ratentestU: '',
      ratentestQ: '',
      bemerkungen: '',
    },
    {
      batterietyp: celltypeList[0],
      batterieanzahl: '',
      formingI: '',
      formingU: '',
      formingQ: '',
      cyclingI: '',
      cyclingU: '',
      cyclingQ: '',
      cyclingCV: '',
      ratentestI: '',
      ratentestU: '',
      ratentestQ: '',
      bemerkungen: '',
    },
  ];
  const [userList, setUserList] = useState<string[]>(location.state.userList);
  const [userLoginList, setUserLoginList] = useState<string[]>(location.state.userLoginList);


  let enums: Record<string, string[]> = ApiBackendData.GetEnumerationsSync();
  const [inputs, setInputs] = useState<IBatteryTestTableEntry[]>([]);
  const [inputsHeader,setInputsHeader] = useState(
    {workBlockName: location.state.workblockName 
      ? location.state.workblockName
      : '',
    packageName: location.state.workpackageName
      ? location.state.workpackageName
      : '',
    responsibleUser: userList[0],
    userList: userList,
    comment: '',
    status: 'aktiv'
    }
  );
  const [openLoadingModal, setOpenLoadingModal] = useState(false);
  const [enumsLoaded, setEnumsLoaded] = useState(false);

  //Loading Data
  const loadUserList = async() => {
    await ApiAuth.ListAll()
      .then((data)=>{
        let users = data.filter( x=> !x.deleted && x.state.name !== 'gesperrt' && x.state.name !== 'gelöscht');
        setUserList(users.map(x => x.displayName));
        setUserLoginList(users.map(x => x.loginName));
      })
      .catch((e)=>{
        console.log(e);
      });
  };

  //Validation
  const [validatedInputs, setValidatedInputs] = useState(false);
  const validateInputFields = () => {
    if( inputs.length == 0){
      setValidatedInputs(false);
      return;
    }
    setValidatedInputs(true);
  };

  //Handle Functions
  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    setInputs((prevInputs) =>
      prevInputs.map((input, i) =>
        i === index ? { ...input, [name]: value } : input
      )
    );
  };
  
  const handleOptionChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    setInputs((prevInputs) =>
      prevInputs.map((input, i) =>
        i === index || i === index + 1
          ? {
              ...input,
              [name]: value,
            }
          : input
      )
    );
  };
  const handleInputChange = (
    event: ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    const { name, value } = event.target;
    setInputs((prevInputs) =>
      prevInputs.map((input, i) =>
        i === index ? { ...input, [name]: value } : input
      )
    );
  };
  const handleAddTableRow = () => {
    setInputs(inputs.concat(defaultTable));
  };
  const handleHeaderChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setInputsHeader((values) => ({ ...values, [name]: value }));
  };
  const handleOptionHeaderChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = event.target;
    let inputs = inputsHeader;
    if(name === 'responsibleUser'){
      inputs.userList.push(value);
    }
    setInputsHeader(inputs);
    setInputsHeader((prevState) => ({ ...prevState, [name]: value}));
  };
  const handleInputHeaderChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    setInputsHeader((prevState) => ({ ...prevState, [name]: value }));
  };
  const handleCreate = () => {
    setOpenLoadingModal(true);
    //is like submitm create requestData and send it to api
    let batteryTestWishes = [];
    for (let i = 0; i < inputs.length; i += 2) {
      batteryTestWishes.push({
        numberOfBatteries: Number(inputs[i + 1].batterieanzahl),
        ch: {
          forming_I_mA: inputs[i].formingI,
          forming_U_max_min: inputs[i].formingU,
          forming_QLimit_mAh: inputs[i].formingQ,
          cycling_I_mA: inputs[i].cyclingI,
          cycling_U_max_min_V: inputs[i].cyclingU,
          cycling_QLimit_mAh: inputs[i].cyclingQ,
          cycling_CV_I_cut_mAh: inputs[i].cyclingCV,
          rateTest_I_mA: inputs[i].ratentestI,
          rateTest_Cyc_per_I: inputs[i].ratentestU,
          rateTest_Repeat: Number(inputs[i].ratentestQ.split('.')[0]),
        },
        dch: {
          forming_I_mA: inputs[i + 1].formingI,
          forming_U_max_min: inputs[i + 1].formingU,
          forming_QLimit_mAh: inputs[i + 1].formingQ,
          cycling_I_mA: inputs[i + 1].cyclingI,
          cycling_U_max_min_V: inputs[i + 1].cyclingU,
          cycling_QLimit_mAh: inputs[i + 1].cyclingQ,
          cycling_CV_I_cut_mAh: inputs[i + 1].cyclingCV,
          rateTest_I_mA: inputs[i + 1].ratentestI,
          rateTest_Cyc_per_I: inputs[i + 1].ratentestU,
          rateTest_Repeat: Number(inputs[i + 1].ratentestQ.split('.')[0]),
        },
        type: inputs[i].batterietyp,
        annotation: inputs[i+1].bemerkungen
      });
    }
    ApiBatteryTest.Create({
      platform: Config.Platform,
      workBlock: location.state.workblockID,
      responsibleUser: [userLoginList[userList.indexOf(inputsHeader.responsibleUser)]],
      state: 'aktiv',
      comment: inputsHeader.comment,
      evaluation: null,
      batteryTestWishes: batteryTestWishes,
      batteryTestProcess: [],
    }).then((id)=> {
      ApiBatteryTest.Get(id)
        .then((data)=>{
          navigate('/batterietest/details',{
            state:{
              workblockID: location.state.workblockID, 
              workblockName: inputsHeader.workBlockName,
              workpackageID: location.state.workpackageID,
              workpackageName: inputsHeader.packageName,
              data: data, 
              userList: userList, 
              userLoginList: userLoginList, 
              halbzellenList: location.state.halbzellenList,
              vollzellenList: location.state.vollzellenList,
              type: location.state.type
            }});
          })
        })
        .catch((e)=>{
          console.log(e);
        })
      .catch(()=>{
        setOpenLoadingModal(false);
      });
  };
  const handleBack = () => {
    navigate(-1);
  };

  useEffect(()=>{
    document.title = 'Batterietest Neu';
    if(!userList || !userLoginList){
      loadUserList();
    }
  },[]);
  useEffect(() => {
    if (!enumsLoaded) {
      setEnumsLoaded(true);
      ApiBackendData.GetEnumerations().then((data) => {
        setEnums(data.CellType);
        if(inputs.length > 0) {
          let tempInputs : IBatteryTestTableEntry[] = inputs;
          tempInputs[0].batterietyp = enumsCelltype[0];
          setInputs(tempInputs as IBatteryTestTableEntry[]);
          }
      }).catch(e=>{
        console.log(e);
      });
    }
  });
    useEffect(() => {
    validateInputFields();
  }, [inputs]) // Aktualisiert immer dann, wenn sich batteryData ändert


  return (
    <div style={{ overflowX: 'scroll' }} className='content-main-container'>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}
      <h1>Batterietest  {location.state.type}</h1>
      <form>
        <div className='row ps-1'>
          <p>Arbeitspaket: {inputsHeader.packageName}</p>
        </div>
        <div className='row ps-1'>
          <p>Arbeitsblock: {inputsHeader.workBlockName}</p>
        </div>
        <div className='row mt-2'>
          <div className='col-4'>
            <label htmlFor='responsibleUser'>Verantwortlicher:</label>
            <div style={{ visibility: 'hidden', height: 0 }}>
              <InputField
                inputType='text'
                name='responsibleUser'
                placeholder= {``}
                value={inputsHeader.responsibleUser}
                changeHandler={handleHeaderChange}
                disabled={false}
                inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
              />
            </div>
            <select
              name='responsibleUser'
              value={inputsHeader.responsibleUser}
              onChange={(event) => handleOptionHeaderChange(event)}
              className='input-lg text-md border-bottom-primary margin-top-5'
            >
              {userList.map((option: string) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <div className='col-4'>
            <label htmlFor='status'>Status:</label>
            <div style={{ visibility: 'hidden', height: 0 }}>
              <InputField
                inputType='text'
                name='status'
                placeholder='aktiv'
                value={inputsHeader.status}
                changeHandler={handleHeaderChange}
                disabled={false}
                inputClass='text-center text-md border-bottom-primary cut-text-overflow margin-top-5'
              />
            </div>
            <select
              name='status'
              value={inputsHeader.status}
              onChange={(event) => handleOptionHeaderChange(event)}
              className='input-lg text-md border-bottom-primary margin-top-5'
            >
              {enums.WorkBlockState.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='row mt-2'>
          <div className='col-8'>
            <label htmlFor='comment'>Übergreifender Kommentar</label>
            <textarea
              className='input-lg-textarea text-md border-bottom-primary cut-text-overflow margin-top-5'
              name='comment'
              value={inputsHeader.comment}
              onChange={handleInputHeaderChange}
            />
          </div>
        </div>
        <div className='mt-3'></div>
      </form>
      <div className="table-responsive margin-top-5">
        <table className='table main-table table-hover align-middle' style={{width:'100%'}}>
        <thead>
          <tr className=" align-middle">
            <th rowSpan={2}></th>
            <th rowSpan={2}>Batterietyp / Anzahl</th>
            <th rowSpan={2}></th>
            <th colSpan={3}>Forming</th>
            <th colSpan={4}>Cycling</th>
            <th colSpan={3}>Ratentest</th>
          </tr>
          <tr className=" align-middle">
            <th>I (mA)</th>
            <th>U_max/min(V)</th>
            <th>Q-Limit</th>
            <th>I (mA)</th>
            <th>U_max/min (V)</th>
            <th>Q-Limit (mAh)</th>
            <th>CV (I_cut)</th>
            <th>I (mA)</th>
            <th>Cyc per I</th>
            <th>Wdh</th>
          </tr>
        </thead>
        <tbody>
          {inputs.map((input, index) => (
            <>
              <tr key={index} className='table-small-inputs'>
                {index % 2 === 0 ? (
                  <>
                    <td rowSpan={3} style={{width:'40px'}}>
                      <button
                        className='btn-delete'
                        type='button'
                        data-bs-toggle='modal'
                        data-bs-target={'#BatteryTestNeu_DeleteEntryModal_'+String(index)}
                      ><FontAwesomeIcon
                        icon={faXmark}
                        size='sm'
                        />
                      </button>
                      <ConfirmationModal
                        modalID={'BatteryTestNeu_DeleteEntryModal_'+String(index)}
                        modalText='Soll der Eintrag wirklich gelöscht werden?'
                        confirmationText='löschen'
                        handleConfirm={() => setInputs((prevTable) => {
                          const newTable = [...prevTable];
                            newTable.splice(index, 2);
                          return newTable;
                          })
                        }
                      />
                    </td>
                    <td className='p-1'>
                      <select
                        name='batterietyp'
                        value={input.batterietyp}
                        onChange={(e) => handleOptionChange(e, index)}
                        className='input-lg text-center text-md border-bottom-primary margin-right-5'
                      >
                        {celltypeList.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </td>
                  </>
                ) : (
                  <>
                    <td className='p-1'>
                      <input
                        type='number'
                        name='batterieanzahl'
                        id='batterieanzahl'
                        value={Number(input.batterieanzahl)}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </td>
                  </>
                )}

                <td style={{width:'50px'}}>
                  <p>{index % 2 === 0 ? 'CH' : 'DCH'}</p>
                </td>
                <td style={{width:'100px'}}>
                  <input
                    type='text'
                    name='formingI'
                    id='formingI'
                    value={input.formingI}
                    onChange={(e) => handleChange(e, index)}
                  />
                </td>
                <td style={{width:'120px'}}>
                  <input
                    type='text'
                    name='formingU'
                    id='formingU'
                    value={input.formingU}
                    onChange={(e) => handleChange(e, index)}
                  />
                </td>
                <td style={{width:'100px'}}>
                  <input
                    type='text'
                    name='formingQ'
                    id='formingQ'
                    value={input.formingQ}
                    onChange={(e) => handleChange(e, index)}
                  />
                </td>
                <td style={{width:'100px'}}>
                  <input
                    type='text'
                    name='cyclingI'
                    id='cyclingI'
                    value={input.cyclingI}
                    onChange={(e) => handleChange(e, index)}
                  />
                </td>
                <td style={{width:'100px'}}>
                  <input
                    type='text'
                    name='cyclingU'
                    id='cyclingU'
                    value={input.cyclingU}
                    onChange={(e) => handleChange(e, index)}
                  />
                </td>
                <td style={{width:'100px'}}>
                  <input
                    type='text'
                    name='cyclingQ'
                    id='cyclingQ'
                    value={input.cyclingQ}
                    onChange={(e) => handleChange(e, index)}
                  />
                </td>
                <td style={{width:'100px'}}>
                  <input
                    type='text'
                    name='cyclingCV'
                    id='cyclingCV'
                    value={input.cyclingCV}
                    onChange={(e) => handleChange(e, index)}
                  />
                </td>
                <td style={{width:'100px'}}>
                  <input
                    type='text'
                    name='ratentestI'
                    id='ratentestI'
                    value={input.ratentestI}
                    onChange={(e) => handleChange(e, index)}
                  />
                </td>
                <td style={{width:'100px'}}>
                  <input
                    type='text'
                    name='ratentestU'
                    id='ratentestU'
                    value={input.ratentestU}
                    onChange={(e) => handleChange(e, index)}
                  />
                </td>
                <td style={{width:'100px'}}>
                  <input
                    type='number'
                    name='ratentestQ'
                    id='ratentestQ'
                    min='0'
                    value={Number(input.ratentestQ)}
                    onChange={(e) => handleChange(e, index)}
                  />
                </td>
              </tr>
              {index % 2 !== 0 ? (
                <tr>
                  <td colSpan={13}>
                    <textarea
                      style={{ width: '100%', margin: '0', lineHeight: 'auto' }}
                      name='bemerkungen'
                      id='bemerkungen'
                      value={input.bemerkungen}
                      onChange={(e) => handleInputChange(e, index)}
                    />
                  </td>
                </tr>
              ) : (
                <></>
              )}
            </>
          ))}
        </tbody>
        </table>
      </div>
      <div className='row mt-2'>
        <div className='col'>
          <input
            className='btn-main margin-right-15'
            type='button'
            value='+ Test hinzufügen'
            onClick={handleAddTableRow}
          />
        </div>
      </div>
      <div className='row mt-2'>
        <div className='col'>
          <input
            className='btn-main margin-right-15'
            type='button'
            value='Zurück'
            onClick={handleBack}
          />
          <input
            className='btn-main'
            type='button'
            value='Batterietest anlegen'
            onClick={handleCreate}
            disabled={!validatedInputs}
          />
        </div>
      </div>
    </div>
  );
};

export default BatterietestNeu;
