import { ReactElement, useEffect, useState } from 'react';
import { ChangeEvent } from 'react';
import FilterDropdown from './FilterDropdown';
import SortingDropdown from './SortingDropdown';

interface ModularSearchHeaderProps {
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  setDisplayedTable: (table: any[]) => void;
  title: string;
  dropdownOptions: string[];
  displayTable: any[];
  buttonText?: string;
  modalID?: string;
  getInitialTable: () => any[];
}
export default function ModularSearchHeader({
  handleChange,
  setDisplayedTable,
  title,
  buttonText,
  displayTable: displayedTable,
  dropdownOptions,
  modalID,
  getInitialTable,
}: ModularSearchHeaderProps) {
  const [initialTable, setInitialTable] = useState<any[]>([]);
  const [tableLoaded, setTableLoaded] = useState(false);
  useEffect(() => {
    if (!tableLoaded) {
      setInitialTable(displayedTable);
      setTableLoaded(true);
    }
  }, []);
  return (
    <>
      <h1>{title}</h1>
      <div className='row margin-top-15'>
        <form className='row' action=''>
          <div className='col-4'>
            <label htmlFor='filter'>
              Filter
            </label>
            <FilterDropdown
              handleChange={handleChange}
              dropdownOptions={dropdownOptions}
              setDisplayedTable={setDisplayedTable}
              displayedTable={initialTable}
              getInitialTable={getInitialTable}
            />
          </div>
          <div className='col-3'>
            <label htmlFor='sortierung'>Sortierung</label>
            <SortingDropdown
              dropdownOptions={dropdownOptions}
              setDisplayedTable={setDisplayedTable}
              displayedTable={displayedTable}
            />
          </div>
        </form>
      </div>
      {modalID ? (
        <div className='margin-top-15'>
          <button
            className='btn-main'
            type='button'
            data-bs-toggle='modal'
            data-bs-target={'#'+ modalID}
          >{buttonText}
          </button>
          
        </div>
      ) : (
        <> </>
      )}
    </>
  );
}
