import { SyncRequestClient } from 'ts-sync-request/dist'

export class ApiClient {

    // async

    static get(url: string) : Promise<Response> {
        return new Promise((resolve, reject) => {
            fetch(url, {
                method: 'GET',
                mode: "cors",
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            })
            .then(data => resolve(data))
            .catch(err => {
                console.log("Fehler bei Abfrage")
                reject(`Error! status: ${err}`);
            });
        });
    }

    static post(url: string, data: any): Promise<Response> {
        let options: RequestInit = {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Accept: 'application/json',
            },
            credentials: 'include',
        }

        return fetch(url, options);
    }
    static postFile(url:string, data: FormData): Promise<Response>{
         let options: RequestInit ={
            method: 'POST',
            body: data,
            headers: {
                Accept : "application/json",
            },
            credentials: 'include',
        };
        return fetch(url, options);
    }
    static put(url: string, data: any): Promise<Response>  {
        let options: RequestInit ={
            method: 'PUT',
            body: JSON.stringify(data),
            headers:{
                "Content-Type": 'application/json;charset=UTF-8',
                Accept: 'application/json',
            },
            credentials: 'include',
        };
        
        return fetch(url, options);
    }

    static delete(url: string, data: any) { 
        return fetch(url, {
            method: 'DELETE',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Accept: 'application/json',
            },
            credentials: 'include',
        });
    }


    // sync

    static getSync(url: string) {
        return new SyncRequestClient().get(url);
    }
}

