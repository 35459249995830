interface ITableFilter {
  index: number;
  searchString: string;
}

export class TableUtil {
  static handleTableFilter(table: any[], tableFilter: ITableFilter[]): any[] {
    let filtered = table;

    tableFilter.forEach((filter) => {
      filtered = filtered.filter((row) => {
        return row[filter.index + 1]
          .toString()
          .toLowerCase()
          .includes(filter.searchString.toLowerCase());
      });
    });
    return filtered;
  }

  static handleTableSorting(table: any[], index: number): any[] {
    const sorted = [...table].sort((a, b) => {
      if (a[index] < b[index]) {
        return -1;
      }
      if (a[index] > b[index]) {
        return 1;
      }
      return 0;
    });
    return sorted;
  }
}
