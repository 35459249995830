import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link } from 'react-router-dom';

interface CardProps {
  title: string;
  linkText: string;
  mainContent: string;
  path: string;
}
const Card = ({ title, linkText, mainContent, path }: CardProps) => {
  return (
    <div className='card-own-style'>
      <Link to={path}>
        <div className='card-content-own-style'>
          <p className='card-title-own-style'>{title}</p>
          <p className='card-text-own-style'>{mainContent}</p>
        </div>
        <div className='card-link-own-style'>
          <span className='card-link-text-own-style'>
            {linkText}
          </span>
          <span className='card-link-text-own-style'>
            <FontAwesomeIcon icon={faCircleArrowRight} />
          </span>
        </div>
      </Link>
    </div>
  );
};

export default Card;
