import { useState } from 'react';
import { TableUtil } from '../../util/TableUtil';
interface SortingDropdownProps {
  dropdownOptions: string[];
  setDisplayedTable: (table: any[]) => void;
  displayedTable: any[];
}
export default function SortingDropdown({
  setDisplayedTable,
  dropdownOptions,
  displayedTable,
}: SortingDropdownProps) {
  const [selectedOption, setSelectedOption] = useState<string>('');

  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedOption(event.target.value);
    setDisplayedTable(
      TableUtil.handleTableSorting(
        displayedTable,
        event.target.selectedIndex + 1
      )
    );
  };
  return (
    <div className='input-wrapper'>
      <select
        name=''
        value={selectedOption}
        onChange={handleOptionChange}
        className='input-lg text-center text-md border-bottom-primary margin-top-5'
      >
        {dropdownOptions.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
}
