import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ModularSearchHeader from '../../components/ModularSearchHeader/ModularSearchHeader';
import { Table } from '../../components/Table';
import {
  ApiWorkPackage,
  WorkpackageConverter,
} from '../../backend/ApiWorkPackage';
import { ApiAuth } from '../../backend/ApiAuth';

// TODO: Daten anders verwalten

export default function ArbeitspaketeUebersicht() {
  const navigate = useNavigate();
  const tableHeaders = [
    'Name',
    'Verantwortlicher',
    'Datum',
    'Status',
    'Ort',
    'Bearbeiten',
  ];
  const [displayedTable, setDisplayedTable] = useState<string[][]>([]);
  const [initializedTable, setInitializedTable] = useState(false);
  const [userDisplayNamesList, setUserDisplayNamesList] = useState<string[]>(
    []
  );
  const [userLoginNamesList, setUserLoginNamesList] = useState<string[]>([]);
  const [userIDList, setUserIDsList] = useState<string[]>([]);

  useEffect(() => {
    document.title = 'Arbeitspakete Uebersicht';
    if (!initializedTable) {
      loadData().then(() =>{
        setInitializedTable(true);
      });
    }
  });
  const loadData = async() =>{
    await ApiWorkPackage.ListAll().then((data) => {
      setDisplayedTable(WorkpackageConverter.ConvertFromApiToTableList(data));  
    })
    .catch((e)=>{
        console.log(e);
      });;
    await ApiAuth.ListAll()
      .then((data)=>{
        let users = data.filter( x=> !x.deleted && x.state.name !== 'gesperrt' && x.state.name !== 'gelöscht');
        setUserDisplayNamesList(users.map(x => x.displayName));
        setUserLoginNamesList(users.map(x => x.loginName));
        setUserIDsList(users.map(x => x.id));
      })
      .catch((e)=>{
        console.log(e);
      });
  }
  //const [displayedTable, setDisplayedTable] = useState(arbeitspaketeTest);
  const [inputs, setInputs] = useState({});
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };
  const getInitialTable = () => {
    return displayedTable;
  };

  const handleOpen = (index: number) => {
    sessionStorage.setItem("userList", JSON.stringify(userDisplayNamesList));
    sessionStorage.setItem("userLoginList", JSON.stringify(userLoginNamesList));

    navigate(`/arbeitspakete/details`, {
      state: {
        workpackage: displayedTable[index],
        userList: userDisplayNamesList,
        userLoginList: userLoginNamesList,
        userIDList: userIDList,
      },
    });
  };
  const handleDuplicate = (index: number) => {
    navigate(`/arbeitspakete/neu`);
  };
  const handleNew = () => {
    navigate(`/arbeitspakete/neu`, {
      state: {
        userList: userDisplayNamesList,
        userLoginList: userLoginNamesList,
      },
    });
  };
  
  const someFunctions = {
    func: [handleOpen],
    val: ['Öffnen'],
    defaultState: ["aktiv"],
    modal:[false]
  };

  return (
    <div className='content-main-container'>
      
      {initializedTable ? (
        <ModularSearchHeader
          title='Arbeitspakete'
          dropdownOptions={tableHeaders.slice(0, -1)}
          handleChange={handleChange}
          setDisplayedTable={setDisplayedTable}
          displayTable={displayedTable}
          getInitialTable={getInitialTable}
        />
      ) : (
        <></>
      )}
      <input
        className='btn-main margin-top-15'
        type='button'
        value='Neues Arbeitspaket'
        onClick={handleNew}
      />
      <Table
        modalIDList={[]}
        headers={tableHeaders}
        valuesList={displayedTable}
        stateList={null}
        functionsList={someFunctions}
      />
    </div>
  );
}
