import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import { ConfirmationModal } from '../../components/Modal';
import { Table } from '../../components/Table';
import ModularSearchHeader from '../../components/ModularSearchHeader/ModularSearchHeader';
import { useNavigate,useLocation } from 'react-router-dom';
import { ApiMaterial, MaterialConverter } from '../../backend/ApiMaterial';
import { __MaterialUnconverted } from '../../backend/types/__RequestStructures';
import { IBackendMaterialGet } from '../../backend/types/__ResponseStructures';
import Loading from '../../components/Ladebalken';

const GetObjectToEditObject = (getObject : IBackendMaterialGet) => {
  let dataObject: __MaterialUnconverted = {
    id: getObject.id,
    platform: getObject.platform.name,
    name: getObject.name,
    materialType: getObject.type.name,
    total: ""+getObject.total,
    available: ""+getObject.available,
    orderNumber: getObject.orderNumber,
    state: getObject.state.name,
    origin: getObject.origin.name,
    annotation: getObject.annotation,
    //deleted: TODO: getObject.deleted, führt zu Fehler und löscht Objekte immer raus
    delieverer: getObject.delieverer,
    placeOfStorage: getObject.placeOfStorage,
    producer: getObject.producer,
    orderInfo: getObject.orderInformation
  };
  return dataObject;
};

function updateButtonStates(table : string[][]) : string[][] {
  let buttonStateTable : string[][] = [];
  for(let row of table) {
    let newStates = ["aktiv", "aktiv", "aktiv", "aktiv"];
    if(row[5] == "gesperrt") {
      newStates[1] = "hidden";
      newStates[2] = "aktiv";
    }
    else {
      newStates[1] = "aktiv"
      newStates[2] = "hidden"
    }
    buttonStateTable.push(newStates);
  }
  return buttonStateTable;
}

// TODO: Daten anders verwalten
export default function Inventar() {
  const navigate = useNavigate();

  const [initializedTable, setInitializedTable] = useState(false);
  const [displayedTable, setDisplayedTable] = useState<string[][]>([]);
  const [openLoadingModal, setOpenLoadingModal] = useState(false);

  const [buttonStateTable, setButtonStateTable] = useState<string[][]|null>(null);

  const tableHeaders = [
    'Name',
    'Typ',
    'Verfügbarkeit',
    'Gekauft',
    'Status',
    'Zuletzt bearbeitet',
    'Bearbeiten',
  ];
  useEffect(() => {
    document.title = 'Inventar';
    if (!initializedTable) {
      setOpenLoadingModal(true);
      ApiMaterial.ListAll().then((data) => {
        let filteredData = data.filter(x => !x.deleted);
        setDisplayedTable(MaterialConverter.ConvertFromApiToTableList(filteredData));
        setInitializedTable(true);
        setOpenLoadingModal(false);
      });
    }
  });

  useEffect(() => {
    setButtonStateTable(updateButtonStates(displayedTable));
  }, [displayedTable]);
  useEffect(() => {
  }, [buttonStateTable]);

  const [inputs, setInputs] = useState({});

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const getInitialTable = () => {
    return displayedTable;
  };

  const handleDelete = (index: number) => {
    //delete material
    ApiMaterial.Delete({ id: displayedTable[index][0] })
    .then(() => {
      ApiMaterial.ListAll().then((data) => {
        setDisplayedTable(MaterialConverter.ConvertFromApiToTableList(data));
      });
    });
  };
  const handleEdit = (index: number) => {
    navigate('/material/details', {
      state: {
        currentMaterialIndex: displayedTable[index][0],
        title: 'Material bearbeiten'
      },
    });
    setInitializedTable(false);
  };
  const handleLock = (index: number) => {
    ApiMaterial.Lock({ id: displayedTable[index][0] })
    .then(() => {

      ApiMaterial.ListAll().then((data) => {
        setDisplayedTable(MaterialConverter.ConvertFromApiToTableList(data));
      });

    });
  };
  
  const handleUnlock = (index: number) => {
    //unlock material
    let id = displayedTable[index][0];
    ApiMaterial.Get(id)
    .then(material => {
      material.state.name = "vorhanden";
      ApiMaterial.Edit(GetObjectToEditObject(material))
      .then(data => {
        ApiMaterial.ListAll().then((data) => {
          setDisplayedTable(MaterialConverter.ConvertFromApiToTableList(data));
        });
      })
    });
  };

  const someFunctions = {
    func: [handleEdit, handleLock, handleUnlock, handleDelete],
    val: ['Bearbeiten', 'Sperren', 'Entsperren', 'Löschen'],
    defaultState: ["aktiv", "aktiv", "aktiv", "aktiv"],
    modal: [false,true,true,true]
  };
  const handleNewMaterial = () => {
    navigate('/material/neu',{
      state:{
        title: 'Material erstellen',
        currentMaterialIndex: null
      }
    });
    setInitializedTable(false);

  };
  return (
    <div className='content-main-container'>
      {openLoadingModal ? (
          <Loading/>
        ) : (
          <></>
      )}
      {initializedTable ? (
        <ModularSearchHeader
          title='Materialien'
          dropdownOptions={tableHeaders.slice(0, -1)}
          handleChange={handleChange}
          setDisplayedTable={setDisplayedTable}
          displayTable={displayedTable}
          getInitialTable={getInitialTable}
        />
      ) : (
        <></>
      )}

      <input
        type='button'
        name='newMaterial'
        id='newMaterial'
        className='btn-main margin-top-15'
        value='Neues Material'
        onClick={handleNewMaterial}
      />

      <Table
        modalIDList={{...someFunctions}.val.map(x => 'Inventar_' + x)}
        modalTextList={['','Soll der Eintrag wirklich gesperrt werden?','Soll der Eintrag wirklich entsperrt werden?','Soll der Eintrag wirklich gelöscht werden?']}
        headers={tableHeaders}
        valuesList={displayedTable}
        stateList={buttonStateTable}
        functionsList={someFunctions}
      />
    </div>
  );
}
